import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FilesizePipe implements PipeTransform {
  private readonly units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  private readonly precisionDic = {
    bytes: 0,
    KB: 0,
    MB: 1,
    GB: 1,
    TB: 2,
    PB: 2
  };

  transform(bytes: number): string {
    if (!bytes) return '';

    let unitIndex = 0;

    while (bytes >= 1024) {
      bytes /= 1024;
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    return `${bytes.toFixed(this.precisionDic[unit])} ${unit}`;
  }
}
