import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, forwardRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLuxonDateModule, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgChartsModule } from 'ng2-charts';
import { environment } from 'src/environments/environment';
import { ApiModule } from 'src/_api';
import { CampaignBookComponent } from './admin/campaign/book/campaign-book.component';
import { CampaignCalendarItemComponent } from './admin/campaign/calendar/campaign-calendar-item.component';
import { CampaignCalendarComponent } from './admin/campaign/calendar/campaign-calendar.component';
import { CampaignComponent } from './admin/campaign/campaign.component';
import { CampaignEditTimesModal } from './admin/campaign/edit/campaign-edit-times.modal';
import { CampaignEditComponent } from './admin/campaign/edit/campaign-edit.component';
import { CampaignSelectSlotsModal } from './admin/campaign/edit/campaign-select-slots.modal';
import { CampaignOverviewComponent } from './admin/campaign/overview/campaign-overview.component';
import { CharacteristicComponent } from './admin/characteristic/characteristic.component';
import { CharacteristicCreateComponent } from './admin/characteristic/create/characteristic-create.component';
import { CustomerCreateComponent } from './admin/customer/create/customer-create.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { CustomerPersonCreateModal } from './admin/customer/person-create/customer-person-create.modal';
import { LocationCreateComponent } from './admin/location/create/location-create.component';
import { LocationComponent } from './admin/location/location.component';
import { MediaCreateComponent } from './admin/media/create/media-create.component';
import { MediaCreateModal } from './admin/media/create/media-create.modal';
import { MediaSelectModal } from './admin/media/media-select.modal';
import { MediaComponent } from './admin/media/media.component';
import { PersonOfInterestCreateComponent } from './admin/person-of-interest/create/person-of-interest-create.component';
import { PersonOfInterestComponent } from './admin/person-of-interest/person-of-interest.component';
import { ScreenCreateComponent } from './admin/screen/create/screen-create.component';
import { ScreenComponent } from './admin/screen/screen.component';
import { StartComponent } from './admin/start/start.component';
import { PeopleCountTargetGroupDailyComponent } from './admin/statistics/people-count/people-count-target-group-daily/people-count-target-group-daily.component';
import { PeopleCountTargetGroupMonthlyComponent } from './admin/statistics/people-count/people-count-target-group-monthly/people-count-target-group-monthly.component';
import { PeopleCountTargetGroupWeeklyComponent } from './admin/statistics/people-count/people-count-target-group-weekly/people-count-target-group-weekly.component';
import { PeopleCountComponent } from './admin/statistics/people-count/people-count.component';
import { StatisticsFilterComponent } from './admin/statistics/statistics-filter.component';
import { ViewsMonthlyComponent } from './admin/statistics/views/monthly/views-monthly.component';
import { ViewsBarChartComponent } from './admin/statistics/views/views-bar-chart.component';
import { ViewsChartHeaderComponent } from './admin/statistics/views/views-chart-header/views-chart-header.component';
import { ViewsSummaryCardComponent } from './admin/statistics/views/views-summary-card.component';
import { ViewsComponent } from './admin/statistics/views/views.component';
import { ViewsWeeklyComponent } from './admin/statistics/views/weekly/views-weekly.component';
import { TargetGroupCreateComponent } from './admin/target-group/create/target-group-create.component';
import { TargetGroupCreateModal } from './admin/target-group/create/target-group-create.modal';
import { TargetGroupComponent } from './admin/target-group/target-group.component';
import { UserCreateComponent } from './admin/user/create/user-create.component';
import { UserComponent } from './admin/user/user.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptor } from './core/api.interceptor';
import { AuthService } from './core/auth/auth.service';
import { LoginComponent } from './core/auth/login.component';
import { LoaderComponent } from './core/loader/loader.component';
import { SidenavComponent } from './core/sidenav/sidenav.component';
import { WeekPickerComponent } from './shared/date/week-picker/week-picker.component';
import { YearAndMonthPickerHeader } from './shared/date/year-and-month-picker/year-and-month-picker-header.component';
import { YearAndMonthPickerComponent } from './shared/date/year-and-month-picker/year-and-month-picker.component';
import { FilesizePipe } from './shared/filesize-pipe/filesize.pipe';
import { FormFieldGroupComponent } from './shared/form-field-group/form-field-group.component';
import { MultiSelectAutocompleteComponent } from './shared/multi-select-autocomplete/multi-select-autocomplete.component';
import { MultiSelectChipsComponent } from './shared/multi-select-chips/multi-select-chips.component';
import { ViewMediaModal } from './shared/view-media-modal/view-media.modal';

const materialModules = [
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatDividerModule,
  MatListModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatCardModule,
  MatInputModule,
  MatFormFieldModule,
  MatToolbarModule,
  MatTableModule,
  MatSortModule,
  MatSelectModule,
  MatChipsModule,
  MatExpansionModule,
  MatCheckboxModule,
  MatDialogModule,
  MatTabsModule,
  MatTreeModule,
  MatDatepickerModule,
  MatLuxonDateModule,
  MatAutocompleteModule
];

@NgModule({
  declarations: [
    LoaderComponent,
    LoginComponent,
    SidenavComponent,
    AppComponent,
    StartComponent,
    MediaComponent,
    TargetGroupComponent,
    TargetGroupCreateModal,
    CharacteristicComponent,
    ScreenComponent,
    ViewsComponent,
    StatisticsFilterComponent,
    ViewsSummaryCardComponent,
    UserComponent,
    LocationComponent,
    UserCreateComponent,
    LocationCreateComponent,
    CharacteristicCreateComponent,
    ScreenCreateComponent,
    TargetGroupCreateComponent,
    MediaCreateComponent,
    MediaCreateModal,
    MediaSelectModal,
    CampaignComponent,
    CampaignBookComponent,
    CampaignEditComponent,
    CampaignEditTimesModal,
    CampaignSelectSlotsModal,
    CampaignOverviewComponent,
    CampaignCalendarComponent,
    CampaignCalendarItemComponent,
    MultiSelectChipsComponent,
    CustomerComponent,
    CustomerCreateComponent,
    FormFieldGroupComponent,
    PersonOfInterestComponent,
    PersonOfInterestCreateComponent,
    CustomerPersonCreateModal,
    ViewsBarChartComponent,
    ViewsMonthlyComponent,
    ViewsWeeklyComponent,
    ViewsChartHeaderComponent,
    ViewMediaModal,
    MultiSelectAutocompleteComponent,
    FilesizePipe,
    PeopleCountComponent,
    PeopleCountTargetGroupMonthlyComponent,
    PeopleCountTargetGroupWeeklyComponent,
    PeopleCountTargetGroupDailyComponent,
    YearAndMonthPickerComponent,
    YearAndMonthPickerHeader,
    WeekPickerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiModule.forRoot({ rootUrl: environment.apiBaseUrl }),
    ...materialModules,
    NgChartsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: forwardRef(() => ApiInterceptor),
      multi: true,
      deps: [AuthService, MatSnackBar]
    },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, firstDayOfWeek: 1 } },
    { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
    FilesizePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
