import { Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { CalendarBounds, CalendarItem, CalendarPeriod, CalendarWeek, CalendarWeekday, CampaignCalendarSlot, ITEM_ROW_HEIGHT } from './calendar-models';

@Component({
  selector: 'flow-campaign-calendar',
  templateUrl: 'campaign-calendar.component.html',
  styleUrls: ['./campaign-calendar.component.scss']
})

export class CampaignCalendarComponent extends unsubscribeMixin() {
  @Input() isSelectState: boolean;
  @Input()
  set campaignSlots(slots: any) {
    this._campaignSlots = slots;
    console.log(slots);
    this.initCalendar();
  }
  get campaignSlots(): CampaignCalendarSlot[] {
    return this._campaignSlots;
  }
  private _campaignSlots: CampaignCalendarSlot[];

  campaignItems: CalendarItem[];
  bounds: CalendarBounds;
  period: CalendarPeriod;
  calendarWeekdays: CalendarWeekday[];
  weeks: CalendarWeek[];

  constructor() {
    super();
  }

  rowHeight(week: CalendarWeek, offset?: number): string {
    const rowCount = week.rowCount || 1;
    return (rowCount * ITEM_ROW_HEIGHT) + offset + 'px';
  }

  private initCalendar(): void {
    this.calendarWeekdays = this.buildWeekdays();
    let start: DateTime, end: DateTime;
    if (!this.campaignSlots?.length) {
      const now = DateTime.utc();
      start = now, end = now.plus({ weeks: 4 });
    } else {
      this.campaignSlots?.forEach(slot => {
        if (!start || slot.start < start) {
          start = slot.start;
        }
        if (!end || slot.end > end) {
          end = slot.end;
        }
      });
    }

    if (!start || !end) {
      return;
    }
    this.weeks = this.buildWeeks(start.startOf('week'), end.endOf('week'));
    // this.cdr.detectChanges();
  }

  private buildWeeks(startOfFirstWeek: DateTime, startOfLastWeek: DateTime): CalendarWeek[] {
    const weeks = [];
    let startOfWeek = startOfFirstWeek;
    while (startOfWeek <= startOfLastWeek) {
      const week = CalendarWeek.buildWeek(
        startOfWeek, this.campaignSlots);
      weeks.push(week);
      startOfWeek = startOfWeek.plus({ week: 1 });
    }
    return weeks;
  }

  private buildWeekdays(): CalendarWeekday[] {
    let day = DateTime.now().startOf('week');
    const endOfWeek = day.endOf('week');
    const weekdaysShort = [];
    while (day < endOfWeek) {
      weekdaysShort.push({ name: day.weekdayLong.slice(0, 2) });
      day = day.plus({ day: 1 });
    }
    return weekdaysShort;
  }
}


