<div>
  <div class="flex justify-start items-center">
    <mat-form-field class="mt-4">
      <mat-label>Gruppera visningar på</mat-label>
      <mat-select [formControl]="groupByFC">
        <mat-option [value]="GroupByData.Total">Totalt antal</mat-option>
        <mat-option [value]="GroupByData.Screen">Skärm</mat-option>
        <mat-option [value]="GroupByData.TargetGroup">Målgrupp</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="flex justify-center items-center">
    <h2 class="chart-period m-0 text-center">
      {{label}}
    </h2>
  </div>
</div>