<div class="page-header"
     *ngIf="!isModal">
  <h1 class="page-title">Media</h1>
  <mat-divider></mat-divider>
</div>

<div class="table-container">
  <mat-accordion [displayMode]="'flat'"
                 [formGroup]="filterForm"
                 class="accordion-filter">
    <mat-expansion-panel [expanded]="panelOpenState"
                         (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
      <mat-expansion-panel-header [collapsedHeight]="'60px'">
        <mat-panel-title>
          <a *ngIf="!isModal"
             mat-flat-button
             color="accent"
             [routerLink]="['/medias/create']">
            <mat-icon class="mr-2"
                      inline="true">add</mat-icon>Skapa
          </a>
        </mat-panel-title>
        <mat-panel-description>
          <span class="mr-2">Filter</span>
          <mat-icon color="accent">filter_alt</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-form-field class="mr-3">
        <mat-label>Fritextsök</mat-label>
        <input matInput
               type="text"
               formControlName="search">
      </mat-form-field>
      <mat-form-field class="mr-3">
        <mat-label>Taggar</mat-label>
        <mat-select formControlName="tags"
                    multiple>
          <mat-option *ngFor="let tag of tags"
                      [value]="tag">
            {{tag.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Uppladdad</mat-label>
        <mat-date-range-input formGroupName="created"
                              [rangePicker]="picker">
          <input matStartDate
                 formControlName="start"
                 placeholder="Från">
          <input matEndDate
                 formControlName="end"
                 placeholder="Till">
        </mat-date-range-input>
        <!-- <mat-hint>YYYY-MM-DD – YYYY-MM-DD</mat-hint> -->
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
  <div *ngIf="!isFilterEmpty()"
       class="table-filter-summary flex justify-end items-center">
    <div class="result-container mr-3">
      Antal träffar: {{dataSource.filteredData?.length}} av {{dataSource.data?.length}}
    </div>
    <div class="btn-container">
      <button type="button"
              color="accent"
              mat-icon-button
              (click)="onClearFilterClick()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <table mat-table
         [dataSource]="dataSource"
         class="table-hover"
         matSort
         matSortActive="id"
         matSortDirection="desc"
         multiTemplateDataRows>

    <ng-container matColumnDef="id">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> ID. </th>
      <td mat-cell
          *matCellDef="let media"> {{media.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Namn </th>
      <td mat-cell
          *matCellDef="let media"> {{media.name}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Typ </th>
      <td mat-cell
          *matCellDef="let media">
        {{mediaTypeLabel(media?.type)}}
      </td>
    </ng-container>

    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Fil </th>
      <td mat-cell
          *matCellDef="let media">
        <div *ngIf="media.type != null"
             class="list-thumbnail-container"
             (click)="openViewMediaModal(media, $event)">
          <img *ngIf="media.type === MediaTypeEnum.Image && media.thumbnailUrl"
               [src]="media.thumbnailUrl"
               alt="Bild för media {{media.name}}" />
          <div class="thumbnail-placeholder"
               *ngIf="media.type === MediaTypeEnum.Video">
            <mat-icon>play_circle</mat-icon>
          </div>
          <div class="thumbnail-placeholder"
               *ngIf="media.type === MediaTypeEnum.Image && !media.thumbnailUrl">
            <mat-icon>visibility</mat-icon>
          </div>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="tags">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Taggar </th>
      <td mat-cell
          *matCellDef="let media">
        <mat-chip-list class="chip-list-small"
                       *ngIf="media?.tags?.length">
          <mat-chip *ngFor="let tag of media.tags">
            {{tag.name}}
          </mat-chip>
        </mat-chip-list>
      </td>
    </ng-container>

    <ng-container matColumnDef="filename">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Filnamn </th>
      <td mat-cell
          *matCellDef="let media">
        {{media?.filename}}
      </td>
    </ng-container>

    <ng-container matColumnDef="filesize">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Storlek </th>
      <td mat-cell
          *matCellDef="let media">
        <ul *ngIf="media.size || (media.width && media.height)"
            class="list-none">
          <li *ngIf="media.width && media.height">
            {{resolutionLabel(media)}}
          </li>
          <li *ngIf="media.size">{{media?.size | filesize}}</li>
        </ul>

      </td>
    </ng-container>

    <ng-container matColumnDef="uploaded">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Uppladdad </th>
      <td mat-cell
          *matCellDef="let media">
        {{media?.createdString}}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="actions-col"></th>
      <td mat-cell
          *matCellDef="let media"
          [style.width]="'1px'">
        <button mat-icon-button
                (click)="onDeleteClick($event, media)"
                class="delete-button"
                aria-label="delete item">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- details -->
    <ng-container matColumnDef="showMore">
      <td mat-cell
          *matCellDef="let result"
          class="col-show-more"
          colspan="10">
        Visa mer...
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <ng-container *ngIf="!isModal">
      <tr mat-row
          *matRowDef="let media; columns: displayedColumns;"
          [routerLink]="['/medias', media.id]"></tr>
    </ng-container>
    <ng-container *ngIf="isModal">
      <tr mat-row
          *matRowDef="let media; columns: displayedColumns;"
          (click)="onMediaSelected(media)"></tr>
    </ng-container>
    <tr mat-row
        *matRowDef="let row; columns: ['showMore']; when: showMoreVisible"
        class="row-show-more"
        (click)="onLoadMore()"></tr>
  </table>
</div>

<div *ngIf="isModal"
     class="flex items-center justify-end mt-8">
  <button mat-button
          type="button"
          (click)="onCancelClick()">Avbryt</button>
</div>
