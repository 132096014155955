<flow-loader></flow-loader>

<mat-drawer-container [hasBackdrop]="false"
                      class="drawer-container"
                      [ngClass]="{ 'nav-hidden': appService.navHidden }"
                      autosize>
  <mat-drawer class="drawer-side"
              color="accent"
              #navDrawer
              [mode]="appService.modeOverQuery.matches ? 'over' : 'side'"
              [opened]="!appService.navHidden && !appService.navClosed">
    <flow-sidenav (closeNav)="appService.navClosed = true"></flow-sidenav>
  </mat-drawer>

  <mat-drawer-content class="drawer-content">
    <mat-toolbar *ngIf="appService.modeOverQuery.matches"
                 class="flex justify-between">
      <button mat-icon-button
              (click)="toggleSidenav()"
              class="raised-icon-button">
        <mat-icon>menu</mat-icon>
      </button>
      <button mat-icon-button
              (click)="toggleDarkTheme()"
              class="raised-icon-button"
              aria-label="Toggle Dark Theme">
        <mat-icon>{{ appService.isDark ? "dark_mode" : "light_mode" }}</mat-icon>
      </button>
    </mat-toolbar>
    <div class="content-container"
         [ngClass]="{ 'has-toolbar-form-actions': appService.hasToolbarFormActions }"
         [ngClass]="{ 'pt-4': appService.modeOverQuery.matches}">

      <button *ngIf="!appService.modeOverQuery.matches"
              mat-icon-button
              (click)="toggleDarkTheme()"
              class="raised-icon-button fixed-theme-toggle"
              aria-label="Toggle Dark Theme">
        <mat-icon>{{ appService.isDark ? "dark_mode" : "light_mode" }}</mat-icon>
      </button>
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>

</mat-drawer-container>
