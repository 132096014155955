import { CharacteristicTypeEnum } from "src/_api";

export const CharacteristicTypeTranslations = {
  [CharacteristicTypeEnum.NoSpecified]: '',
  [CharacteristicTypeEnum.Age]: 'År',
  [CharacteristicTypeEnum.Gender]: 'Kön',
  [CharacteristicTypeEnum.AgeGroup]: 'Ålderskategori',
  [CharacteristicTypeEnum.Weather]: 'Väder',
  [CharacteristicTypeEnum.TimeOfDay]: 'Tid'
};
