<div class="chips-and-input-container">
  <mat-chip-list *ngIf="value?.length">
    <mat-chip *ngFor="let selectedOption of value"
              class="mb-3"
              [removable]="true"
              (removed)="onSelectedOptionRemoved(selectedOption)">
      {{displayFn(selectedOption)}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <input type="search"
         matInput
         [formControl]="inputControl"
         [matAutocomplete]="auto"
         (focusin)="onFocusIn($event)"
         (focusout)="onFocusOut($event)"
         (keydown.enter)="onInputEnter($event)">
  <mat-autocomplete #auto="matAutocomplete"
                    [displayWith]="displayFn"
                    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions | async"
                [value]="option"
                [disabled]="isOptionSelected(option)">
      {{displayFn(option)}}
    </mat-option>
  </mat-autocomplete>
</div>
