import { Injectable } from "@angular/core";
import { TargetGroupEnum } from "src/_api";

const { YoungWoman, OldWoman, YoungMan, OldMan } = TargetGroupEnum


@Injectable({
  providedIn: 'root'
})
export class TargetGroupHelper {
  getTargetGroupColor(group: TargetGroupEnum, opacity = 0.6) {
    switch (group) {
      case YoungMan:
        return `rgba(155, 197, 241, ${opacity})`
      case OldMan:
        return `rgba(54, 126, 66, ${opacity})`
      case YoungWoman:
        return `rgba(245, 169, 181, ${opacity})`
      case OldWoman:
        return `rgba(167, 139, 250, ${opacity})`
      default:
        return 'rgba(0, 0, 0, 0.1)'
    }
  }

  getChartColors(group: TargetGroupEnum, opacity = 0.6) {
    const color = this.getTargetGroupColor(group, opacity)

    return {
      backgroundColor: color,
      hoverBackgroundColor: color,
      borderColor: color
    }
  }
}
