import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { EnumObject, EnumService } from 'src/app/core/enum.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Characteristic, CharacteristicTypeEnum, SaveTargetGroupRequest, TargetGroup } from 'src/_api';
import { CharacteristicTypeTranslations } from '../../characteristic/characteristic-type-translations';

@Component({
  selector: 'flow-target-group-create',
  templateUrl: './target-group-create.component.html',
  styleUrls: ['./target-group-create.component.scss']
})
export class TargetGroupCreateComponent extends unsubscribeMixin() implements OnInit {

  @Input() isModal: boolean;
  @Output() targetGroupSaved = new EventEmitter<TargetGroup>();
  @Output() cancel = new EventEmitter();

  targetGroupId: number;
  targetGroup: TargetGroupCreate;
  characteristics: Characteristic[];
  characteristicGroups: CharacteristicGroup[] = [];
  form: FormGroup<TargetGroupFormGroup>;

  loading$ = {
    init: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public enumService: EnumService,
    private cdr: ChangeDetectorRef,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.targetGroupId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('targetGroupId: ', this.targetGroupId);
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.adminApi.getCharacteristics(),
      this.targetGroupId ? this.adminApi.getTargetGroupById({ targetGroupId: this.targetGroupId }) : of(null)
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([characteristics, targetGroup]) => {
        this.characteristics = characteristics;
        if (targetGroup) {
          this.targetGroup = {
            ...targetGroup,
            characteristicTypes: []
          };
          this.targetGroup.characteristicIds.forEach(cId => {
            const characteristic = this.characteristics.find(c => c.id === cId);
            if (!this.targetGroup.characteristicTypes.some(ct => ct.value === characteristic.type)) {
              const typeObject = this.enumService.characteristicTypes.find(ct => ct.value === characteristic.type);
              this.targetGroup.characteristicTypes.push(typeObject);
              this.characteristicGroups.push({
                type: characteristic.type,
                characteristics: this.characteristics.filter(c => c.type === characteristic.type),
                selectedCharacteristics: [characteristic]
              });
            } else {
              this.characteristicGroups.find(cg => cg.type === characteristic.type).selectedCharacteristics.push(characteristic);
            }
          });
        }
        this.initForm(this.targetGroup);
      });
  }

  onSaveClick(): void {
    if (!this.form.valid || !this.characteristicGroups?.length) {
      return;
    }
    const body: SaveTargetGroupRequest = {
      id: this.targetGroupId,
      name: this.form.value.name,
      characteristicIds: this.characteristicGroups.flatMap(cg => cg.selectedCharacteristics.map(c => c.id) || [])
    };
    this.adminApi.saveTargetGroup({ body })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe((targetGroup) => {
        if (!this.isModal) {
          this.systemMessageService.success(`Målgrupp ${this.form.value.name} sparad!`);
          this.router.navigate(['/target-groups']);
        } else {
          this.targetGroupSaved.emit(targetGroup);
        }
      });
  }

  onCancelClick(): void {
    this.cancel.emit();
  }

  typeLabel(type: CharacteristicTypeEnum): string {
    return CharacteristicTypeTranslations[type];
  }

  private initForm(targetGroup?: TargetGroupCreate): void {
    this.form = this.formBuilder.group({
      name: [targetGroup?.name, Validators.required],
      characteristicTypes: this.formBuilder.control(targetGroup?.characteristicTypes, { validators: Validators.required }),
    });

    if (this.targetGroupId && !targetGroup) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    this.form.get('characteristicTypes').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(types => {
        const groups: CharacteristicGroup[] = types?.map(type => ({
          type: type.value,
          characteristics: this.characteristics.filter(c => c.type === type.value),
          selectedCharacteristics: this.characteristicGroups
            ?.find(cg => cg.type === type.value)?.selectedCharacteristics || []
        }));
        this.characteristicGroups = groups;
        console.log(this.characteristicGroups);
      });
  }

}

interface TargetGroupFormGroup {
  name: FormControl<string>;
  characteristicTypes: FormControl<EnumObject<CharacteristicTypeEnum>[]>;
}

interface TargetGroupCreate extends TargetGroup {
  characteristicTypes: EnumObject<CharacteristicTypeEnum>[];
}

interface CharacteristicGroup {
  type: CharacteristicTypeEnum;
  selectedCharacteristics?: Characteristic[],
  characteristics: Characteristic[]
}
