/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PersonOfInterest } from '../models/person-of-interest';
import { SavePersonOfInterestRequest } from '../models/save-person-of-interest-request';

@Injectable({
  providedIn: 'root',
})
export class PersonOfInteresetApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPersonOfInterestsPersonOfIntereset
   */
  static readonly GetPersonOfInterestsPersonOfInteresetPath = '/person-of-intereset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonOfInterests()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterests$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PersonOfInterest>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.GetPersonOfInterestsPersonOfInteresetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonOfInterest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPersonOfInterests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterests(params?: {
  }): Observable<Array<PersonOfInterest>> {

    return this.getPersonOfInterests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonOfInterest>>) => r.body as Array<PersonOfInterest>)
    );
  }

  /**
   * Path part for operation createOrUpdatePersonOfInterestPersonOfIntereset
   */
  static readonly CreateOrUpdatePersonOfInterestPersonOfInteresetPath = '/person-of-intereset';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdatePersonOfInterest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdatePersonOfInterest$Response(params?: {
    body?: SavePersonOfInterestRequest
  }): Observable<StrictHttpResponse<PersonOfInterest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.CreateOrUpdatePersonOfInterestPersonOfInteresetPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonOfInterest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdatePersonOfInterest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdatePersonOfInterest(params?: {
    body?: SavePersonOfInterestRequest
  }): Observable<PersonOfInterest> {

    return this.createOrUpdatePersonOfInterest$Response(params).pipe(
      map((r: StrictHttpResponse<PersonOfInterest>) => r.body as PersonOfInterest)
    );
  }

  /**
   * Path part for operation getPersonOfInterestByIdPersonOfIntereset
   */
  static readonly GetPersonOfInterestByIdPersonOfInteresetPath = '/person-of-intereset/{personOfInteresetId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonOfInterestById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterestById$Response(params: {
    personOfInteresetId: number;
  }): Observable<StrictHttpResponse<PersonOfInterest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.GetPersonOfInterestByIdPersonOfInteresetPath, 'get');
    if (params) {
      rb.path('personOfInteresetId', params.personOfInteresetId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonOfInterest>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPersonOfInterestById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterestById(params: {
    personOfInteresetId: number;
  }): Observable<PersonOfInterest> {

    return this.getPersonOfInterestById$Response(params).pipe(
      map((r: StrictHttpResponse<PersonOfInterest>) => r.body as PersonOfInterest)
    );
  }

  /**
   * Path part for operation getManyPersonOfInterestPersonOfIntereset
   */
  static readonly GetManyPersonOfInterestPersonOfInteresetPath = '/person-of-intereset/getmany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManyPersonOfInterest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyPersonOfInterest$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<PersonOfInterest>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.GetManyPersonOfInterestPersonOfInteresetPath, 'get');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonOfInterest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManyPersonOfInterest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyPersonOfInterest(params?: {
    body?: Array<number>
  }): Observable<Array<PersonOfInterest>> {

    return this.getManyPersonOfInterest$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonOfInterest>>) => r.body as Array<PersonOfInterest>)
    );
  }

  /**
   * Path part for operation getPersonOfInterestByCustomerIdPersonOfIntereset
   */
  static readonly GetPersonOfInterestByCustomerIdPersonOfInteresetPath = '/person-of-intereset/by-customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonOfInterestByCustomerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterestByCustomerId$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<Array<PersonOfInterest>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.GetPersonOfInterestByCustomerIdPersonOfInteresetPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PersonOfInterest>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPersonOfInterestByCustomerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonOfInterestByCustomerId(params: {
    customerId: number;
  }): Observable<Array<PersonOfInterest>> {

    return this.getPersonOfInterestByCustomerId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PersonOfInterest>>) => r.body as Array<PersonOfInterest>)
    );
  }

  /**
   * Path part for operation deletePersonOfInterestPersonOfIntereset
   */
  static readonly DeletePersonOfInterestPersonOfInteresetPath = '/person-of-intereset/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePersonOfInterest()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePersonOfInterest$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PersonOfInteresetApi.DeletePersonOfInterestPersonOfInteresetPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deletePersonOfInterest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePersonOfInterest(params: {
    id: number;
  }): Observable<void> {

    return this.deletePersonOfInterest$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
