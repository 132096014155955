/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Advertisement } from '../models/advertisement';
import { Characteristic } from '../models/characteristic';
import { Customer } from '../models/customer';
import { CustomerPerson } from '../models/customer-person';
import { ImageToken } from '../models/image-token';
import { Location } from '../models/location';
import { MediaFile } from '../models/media-file';
import { SaveAdvertisementRequest } from '../models/save-advertisement-request';
import { SaveCharacteristicRequest } from '../models/save-characteristic-request';
import { SaveCustomerPersonRequest } from '../models/save-customer-person-request';
import { SaveCustomerRequest } from '../models/save-customer-request';
import { SaveLocationRequest } from '../models/save-location-request';
import { SaveMediaRequest } from '../models/save-media-request';
import { SaveScreenRequest } from '../models/save-screen-request';
import { SaveTargetGroupRequest } from '../models/save-target-group-request';
import { Screen } from '../models/screen';
import { Tag } from '../models/tag';
import { TargetGroup } from '../models/target-group';
import { Versions } from '../models/versions';

@Injectable({
  providedIn: 'root',
})
export class AdminApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCharacteristicsAdmin
   */
  static readonly GetCharacteristicsAdminPath = '/admin/characteristics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCharacteristics()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharacteristics$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Characteristic>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCharacteristicsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Characteristic>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCharacteristics$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharacteristics(params?: {
  }): Observable<Array<Characteristic>> {

    return this.getCharacteristics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Characteristic>>) => r.body as Array<Characteristic>)
    );
  }

  /**
   * Path part for operation createOrUpdateCharacteristicAdmin
   */
  static readonly CreateOrUpdateCharacteristicAdminPath = '/admin/characteristics';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOrUpdateCharacteristic()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateCharacteristic$Response(params?: {
    body?: SaveCharacteristicRequest
  }): Observable<StrictHttpResponse<Characteristic>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.CreateOrUpdateCharacteristicAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Characteristic>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOrUpdateCharacteristic$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createOrUpdateCharacteristic(params?: {
    body?: SaveCharacteristicRequest
  }): Observable<Characteristic> {

    return this.createOrUpdateCharacteristic$Response(params).pipe(
      map((r: StrictHttpResponse<Characteristic>) => r.body as Characteristic)
    );
  }

  /**
   * Path part for operation getManyCharacteristicsAdmin
   */
  static readonly GetManyCharacteristicsAdminPath = '/admin/characteristics/getmany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManyCharacteristics()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyCharacteristics$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<Characteristic>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetManyCharacteristicsAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Characteristic>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManyCharacteristics$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyCharacteristics(params?: {
    body?: Array<number>
  }): Observable<Array<Characteristic>> {

    return this.getManyCharacteristics$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Characteristic>>) => r.body as Array<Characteristic>)
    );
  }

  /**
   * Path part for operation getCharacteristicByIdAdmin
   */
  static readonly GetCharacteristicByIdAdminPath = '/admin/characteristics/{characteristicId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCharacteristicById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharacteristicById$Response(params: {
    characteristicId: number;
  }): Observable<StrictHttpResponse<Characteristic>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCharacteristicByIdAdminPath, 'get');
    if (params) {
      rb.path('characteristicId', params.characteristicId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Characteristic>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCharacteristicById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCharacteristicById(params: {
    characteristicId: number;
  }): Observable<Characteristic> {

    return this.getCharacteristicById$Response(params).pipe(
      map((r: StrictHttpResponse<Characteristic>) => r.body as Characteristic)
    );
  }

  /**
   * Path part for operation removeCharacteristicAdmin
   */
  static readonly RemoveCharacteristicAdminPath = '/admin/characteristic/{characteristicId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCharacteristic()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCharacteristic$Response(params: {
    characteristicId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveCharacteristicAdminPath, 'delete');
    if (params) {
      rb.path('characteristicId', params.characteristicId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCharacteristic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCharacteristic(params: {
    characteristicId: number;
  }): Observable<void> {

    return this.removeCharacteristic$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLocationsAdmin
   */
  static readonly GetLocationsAdminPath = '/admin/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocations$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Location>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetLocationsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Location>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocations(params?: {
  }): Observable<Array<Location>> {

    return this.getLocations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Location>>) => r.body as Array<Location>)
    );
  }

  /**
   * Path part for operation saveLocationAdmin
   */
  static readonly SaveLocationAdminPath = '/admin/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveLocation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveLocation$Response(params?: {
    body?: SaveLocationRequest
  }): Observable<StrictHttpResponse<Location>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveLocationAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Location>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveLocation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveLocation(params?: {
    body?: SaveLocationRequest
  }): Observable<Location> {

    return this.saveLocation$Response(params).pipe(
      map((r: StrictHttpResponse<Location>) => r.body as Location)
    );
  }

  /**
   * Path part for operation getManyLocationsAdmin
   */
  static readonly GetManyLocationsAdminPath = '/admin/locations/getmany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManyLocations()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyLocations$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<Location>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetManyLocationsAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Location>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManyLocations$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyLocations(params?: {
    body?: Array<number>
  }): Observable<Array<Location>> {

    return this.getManyLocations$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Location>>) => r.body as Array<Location>)
    );
  }

  /**
   * Path part for operation getLocationByIdAdmin
   */
  static readonly GetLocationByIdAdminPath = '/admin/locations/{locationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationById$Response(params: {
    locationId: number;
  }): Observable<StrictHttpResponse<Location>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetLocationByIdAdminPath, 'get');
    if (params) {
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Location>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationById(params: {
    locationId: number;
  }): Observable<Location> {

    return this.getLocationById$Response(params).pipe(
      map((r: StrictHttpResponse<Location>) => r.body as Location)
    );
  }

  /**
   * Path part for operation removeLocationAdmin
   */
  static readonly RemoveLocationAdminPath = '/admin/locations/{locationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLocation$Response(params: {
    locationId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveLocationAdminPath, 'delete');
    if (params) {
      rb.path('locationId', params.locationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeLocation(params: {
    locationId: number;
  }): Observable<void> {

    return this.removeLocation$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTargetGroupsAdmin
   */
  static readonly GetTargetGroupsAdminPath = '/admin/targetGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetGroups$Response(params?: {
  }): Observable<StrictHttpResponse<Array<TargetGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetTargetGroupsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TargetGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTargetGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetGroups(params?: {
  }): Observable<Array<TargetGroup>> {

    return this.getTargetGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TargetGroup>>) => r.body as Array<TargetGroup>)
    );
  }

  /**
   * Path part for operation saveTargetGroupAdmin
   */
  static readonly SaveTargetGroupAdminPath = '/admin/targetGroups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveTargetGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTargetGroup$Response(params?: {
    body?: SaveTargetGroupRequest
  }): Observable<StrictHttpResponse<TargetGroup>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveTargetGroupAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TargetGroup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveTargetGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveTargetGroup(params?: {
    body?: SaveTargetGroupRequest
  }): Observable<TargetGroup> {

    return this.saveTargetGroup$Response(params).pipe(
      map((r: StrictHttpResponse<TargetGroup>) => r.body as TargetGroup)
    );
  }

  /**
   * Path part for operation getTargetGroupByIdAdmin
   */
  static readonly GetTargetGroupByIdAdminPath = '/admin/targetGroups/{targetGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTargetGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetGroupById$Response(params: {
    targetGroupId: number;
  }): Observable<StrictHttpResponse<TargetGroup>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetTargetGroupByIdAdminPath, 'get');
    if (params) {
      rb.path('targetGroupId', params.targetGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TargetGroup>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTargetGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTargetGroupById(params: {
    targetGroupId: number;
  }): Observable<TargetGroup> {

    return this.getTargetGroupById$Response(params).pipe(
      map((r: StrictHttpResponse<TargetGroup>) => r.body as TargetGroup)
    );
  }

  /**
   * Path part for operation removeTargetGroupAdmin
   */
  static readonly RemoveTargetGroupAdminPath = '/admin/targetGroups/{targetGroupId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeTargetGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTargetGroup$Response(params: {
    targetGroupId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveTargetGroupAdminPath, 'delete');
    if (params) {
      rb.path('targetGroupId', params.targetGroupId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeTargetGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeTargetGroup(params: {
    targetGroupId: number;
  }): Observable<void> {

    return this.removeTargetGroup$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getManyTargetGroupsAdmin
   */
  static readonly GetManyTargetGroupsAdminPath = '/admin/targetGroups/getmany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManyTargetGroups()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyTargetGroups$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<TargetGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetManyTargetGroupsAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TargetGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManyTargetGroups$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyTargetGroups(params?: {
    body?: Array<number>
  }): Observable<Array<TargetGroup>> {

    return this.getManyTargetGroups$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TargetGroup>>) => r.body as Array<TargetGroup>)
    );
  }

  /**
   * Path part for operation targetGroupAddCharacteristicAdmin
   */
  static readonly TargetGroupAddCharacteristicAdminPath = '/admin/targetGroups/{targetGroupId}/characteristic/{characteristicId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `targetGroupAddCharacteristic()` instead.
   *
   * This method doesn't expect any request body.
   */
  targetGroupAddCharacteristic$Response(params: {
    targetGroupId: number;
    characteristicId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.TargetGroupAddCharacteristicAdminPath, 'post');
    if (params) {
      rb.path('targetGroupId', params.targetGroupId, {});
      rb.path('characteristicId', params.characteristicId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `targetGroupAddCharacteristic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  targetGroupAddCharacteristic(params: {
    targetGroupId: number;
    characteristicId: number;
  }): Observable<void> {

    return this.targetGroupAddCharacteristic$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation targetGroupRemoveCharacteristicAdmin
   */
  static readonly TargetGroupRemoveCharacteristicAdminPath = '/admin/targetGroups/{targetGroupId}/characteristic/{characteristicId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `targetGroupRemoveCharacteristic()` instead.
   *
   * This method doesn't expect any request body.
   */
  targetGroupRemoveCharacteristic$Response(params: {
    targetGroupId: number;
    characteristicId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.TargetGroupRemoveCharacteristicAdminPath, 'delete');
    if (params) {
      rb.path('targetGroupId', params.targetGroupId, {});
      rb.path('characteristicId', params.characteristicId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `targetGroupRemoveCharacteristic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  targetGroupRemoveCharacteristic(params: {
    targetGroupId: number;
    characteristicId: number;
  }): Observable<void> {

    return this.targetGroupRemoveCharacteristic$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getScreensAdmin
   */
  static readonly GetScreensAdminPath = '/admin/screens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScreens()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreens$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Screen>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetScreensAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Screen>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScreens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreens(params?: {
  }): Observable<Array<Screen>> {

    return this.getScreens$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Screen>>) => r.body as Array<Screen>)
    );
  }

  /**
   * Path part for operation saveScreenAdmin
   */
  static readonly SaveScreenAdminPath = '/admin/screens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveScreen()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveScreen$Response(params?: {
    body?: SaveScreenRequest
  }): Observable<StrictHttpResponse<Screen>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveScreenAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Screen>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveScreen$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveScreen(params?: {
    body?: SaveScreenRequest
  }): Observable<Screen> {

    return this.saveScreen$Response(params).pipe(
      map((r: StrictHttpResponse<Screen>) => r.body as Screen)
    );
  }

  /**
   * Path part for operation getScreenByIdAdmin
   */
  static readonly GetScreenByIdAdminPath = '/admin/screens/{screenId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getScreenById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenById$Response(params: {
    screenId: number;
  }): Observable<StrictHttpResponse<Screen>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetScreenByIdAdminPath, 'get');
    if (params) {
      rb.path('screenId', params.screenId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Screen>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getScreenById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getScreenById(params: {
    screenId: number;
  }): Observable<Screen> {

    return this.getScreenById$Response(params).pipe(
      map((r: StrictHttpResponse<Screen>) => r.body as Screen)
    );
  }

  /**
   * Path part for operation removeScreenAdmin
   */
  static readonly RemoveScreenAdminPath = '/admin/screens/{screenId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeScreen()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeScreen$Response(params: {
    screenId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveScreenAdminPath, 'delete');
    if (params) {
      rb.path('screenId', params.screenId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeScreen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeScreen(params: {
    screenId: number;
  }): Observable<void> {

    return this.removeScreen$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation versionsAdmin
   */
  static readonly VersionsAdminPath = '/admin/versions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versions()` instead.
   *
   * This method doesn't expect any request body.
   */
  versions$Response(params?: {
  }): Observable<StrictHttpResponse<Versions>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.VersionsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Versions>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  versions(params?: {
  }): Observable<Versions> {

    return this.versions$Response(params).pipe(
      map((r: StrictHttpResponse<Versions>) => r.body as Versions)
    );
  }

  /**
   * Path part for operation getMediasAdmin
   */
  static readonly GetMediasAdminPath = '/admin/medias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMedias()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedias$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MediaFile>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetMediasAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MediaFile>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMedias$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMedias(params?: {
  }): Observable<Array<MediaFile>> {

    return this.getMedias$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MediaFile>>) => r.body as Array<MediaFile>)
    );
  }

  /**
   * Path part for operation saveMediaAdmin
   */
  static readonly SaveMediaAdminPath = '/admin/medias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMedia()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMedia$Response(params?: {
    body?: SaveMediaRequest
  }): Observable<StrictHttpResponse<MediaFile>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveMediaAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaFile>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveMedia$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveMedia(params?: {
    body?: SaveMediaRequest
  }): Observable<MediaFile> {

    return this.saveMedia$Response(params).pipe(
      map((r: StrictHttpResponse<MediaFile>) => r.body as MediaFile)
    );
  }

  /**
   * Path part for operation getMediaByIdAdmin
   */
  static readonly GetMediaByIdAdminPath = '/admin/medias/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMediaById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MediaFile>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetMediaByIdAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaFile>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMediaById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMediaById(params: {
    id: number;
  }): Observable<MediaFile> {

    return this.getMediaById$Response(params).pipe(
      map((r: StrictHttpResponse<MediaFile>) => r.body as MediaFile)
    );
  }

  /**
   * Path part for operation removeMediaAdmin
   */
  static readonly RemoveMediaAdminPath = '/admin/medias/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeMedia()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMedia$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<MediaFile>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveMediaAdminPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaFile>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeMedia$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeMedia(params: {
    id: number;
  }): Observable<MediaFile> {

    return this.removeMedia$Response(params).pipe(
      map((r: StrictHttpResponse<MediaFile>) => r.body as MediaFile)
    );
  }

  /**
   * Path part for operation mediaUploadImageAdmin
   */
  static readonly MediaUploadImageAdminPath = '/admin/medias/images/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaUploadImage()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mediaUploadImage$Response(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<MediaFile>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaUploadImageAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaFile>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaUploadImage$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mediaUploadImage(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<MediaFile> {

    return this.mediaUploadImage$Response(params).pipe(
      map((r: StrictHttpResponse<MediaFile>) => r.body as MediaFile)
    );
  }

  /**
   * Path part for operation mediaUploadVideoAdmin
   */
  static readonly MediaUploadVideoAdminPath = '/admin/medias/videos/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaUploadVideo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mediaUploadVideo$Response(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<MediaFile>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaUploadVideoAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MediaFile>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaUploadVideo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  mediaUploadVideo(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<MediaFile> {

    return this.mediaUploadVideo$Response(params).pipe(
      map((r: StrictHttpResponse<MediaFile>) => r.body as MediaFile)
    );
  }

  /**
   * Path part for operation mediaGetImageAdmin
   */
  static readonly MediaGetImageAdminPath = '/admin/medias/images/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaGetImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImage$Response(params: {
    mediaId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaGetImageAdminPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaGetImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImage(params: {
    mediaId: number;
  }): Observable<void> {

    return this.mediaGetImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mediaGetVideoAdmin
   */
  static readonly MediaGetVideoAdminPath = '/admin/medias/videos/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaGetVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetVideo$Response(params: {
    mediaId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaGetVideoAdminPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaGetVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetVideo(params: {
    mediaId: number;
  }): Observable<void> {

    return this.mediaGetVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAdvertisementsAdmin
   */
  static readonly GetAdvertisementsAdminPath = '/admin/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Advertisement>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetAdvertisementsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Advertisement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements(params?: {
  }): Observable<Array<Advertisement>> {

    return this.getAdvertisements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Advertisement>>) => r.body as Array<Advertisement>)
    );
  }

  /**
   * Path part for operation saveAdvertisementAdmin
   */
  static readonly SaveAdvertisementAdminPath = '/admin/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAdvertisement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAdvertisement$Response(params?: {
    body?: SaveAdvertisementRequest
  }): Observable<StrictHttpResponse<Advertisement>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveAdvertisementAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Advertisement>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveAdvertisement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAdvertisement(params?: {
    body?: SaveAdvertisementRequest
  }): Observable<Advertisement> {

    return this.saveAdvertisement$Response(params).pipe(
      map((r: StrictHttpResponse<Advertisement>) => r.body as Advertisement)
    );
  }

  /**
   * Path part for operation getAdvertisementByIdAdmin
   */
  static readonly GetAdvertisementByIdAdminPath = '/admin/advertisements/{advertisementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisementById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisementById$Response(params: {
    advertisementId: number;
  }): Observable<StrictHttpResponse<Advertisement>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetAdvertisementByIdAdminPath, 'get');
    if (params) {
      rb.path('advertisementId', params.advertisementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Advertisement>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisementById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisementById(params: {
    advertisementId: number;
  }): Observable<Advertisement> {

    return this.getAdvertisementById$Response(params).pipe(
      map((r: StrictHttpResponse<Advertisement>) => r.body as Advertisement)
    );
  }

  /**
   * Path part for operation removeAdvertisementAdmin
   */
  static readonly RemoveAdvertisementAdminPath = '/admin/advertisements/{advertisementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeAdvertisement()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAdvertisement$Response(params: {
    advertisementId: number;
  }): Observable<StrictHttpResponse<Array<Advertisement>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveAdvertisementAdminPath, 'delete');
    if (params) {
      rb.path('advertisementId', params.advertisementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Advertisement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeAdvertisement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeAdvertisement(params: {
    advertisementId: number;
  }): Observable<Array<Advertisement>> {

    return this.removeAdvertisement$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Advertisement>>) => r.body as Array<Advertisement>)
    );
  }

  /**
   * Path part for operation getManyAdvertisementsAdmin
   */
  static readonly GetManyAdvertisementsAdminPath = '/admin/advertisements/getmany';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getManyAdvertisements()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyAdvertisements$Response(params?: {
    body?: Array<number>
  }): Observable<StrictHttpResponse<Array<Advertisement>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetManyAdvertisementsAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Advertisement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getManyAdvertisements$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getManyAdvertisements(params?: {
    body?: Array<number>
  }): Observable<Array<Advertisement>> {

    return this.getManyAdvertisements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Advertisement>>) => r.body as Array<Advertisement>)
    );
  }

  /**
   * Path part for operation getCustomersAdmin
   */
  static readonly GetCustomersAdminPath = '/admin/customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCustomersAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
  }): Observable<Array<Customer>> {

    return this.getCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation saveCustomerAdmin
   */
  static readonly SaveCustomerAdminPath = '/admin/customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCustomer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCustomer$Response(params?: {
    body?: SaveCustomerRequest
  }): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveCustomerAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCustomer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCustomer(params?: {
    body?: SaveCustomerRequest
  }): Observable<Customer> {

    return this.saveCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Path part for operation getCustomerByIdAdmin
   */
  static readonly GetCustomerByIdAdminPath = '/admin/customers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCustomerByIdAdminPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById(params: {
    id: number;
  }): Observable<Customer> {

    return this.getCustomerById$Response(params).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Path part for operation removeCustomerAdmin
   */
  static readonly RemoveCustomerAdminPath = '/admin/customers/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomer$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveCustomerAdminPath, 'delete');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomer(params: {
    customerId: number;
  }): Observable<void> {

    return this.removeCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCustomerPersonsAdmin
   */
  static readonly GetCustomerPersonsAdminPath = '/admin/customer/{customerId}/persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPersons$Response(params: {
    customerId: number;
  }): Observable<StrictHttpResponse<Array<CustomerPerson>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCustomerPersonsAdminPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerPerson>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPersons(params: {
    customerId: number;
  }): Observable<Array<CustomerPerson>> {

    return this.getCustomerPersons$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerPerson>>) => r.body as Array<CustomerPerson>)
    );
  }

  /**
   * Path part for operation getCustomerPersonsByIdAdmin
   */
  static readonly GetCustomerPersonsByIdAdminPath = '/admin/customers/{customerId}/persons/{customerPersonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPersonsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPersonsById$Response(params: {
    customerId: number;
    customerPersonId: number;
  }): Observable<StrictHttpResponse<CustomerPerson>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetCustomerPersonsByIdAdminPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('customerPersonId', params.customerPersonId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerPerson>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerPersonsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPersonsById(params: {
    customerId: number;
    customerPersonId: number;
  }): Observable<CustomerPerson> {

    return this.getCustomerPersonsById$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerPerson>) => r.body as CustomerPerson)
    );
  }

  /**
   * Path part for operation removeCustomerPersonAdmin
   */
  static readonly RemoveCustomerPersonAdminPath = '/admin/customers/{customerId}/persons/{customerPersonId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCustomerPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomerPerson$Response(params: {
    customerId: number;
    customerPersonId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.RemoveCustomerPersonAdminPath, 'delete');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.path('customerPersonId', params.customerPersonId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCustomerPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCustomerPerson(params: {
    customerId: number;
    customerPersonId: number;
  }): Observable<void> {

    return this.removeCustomerPerson$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation saveCustomerPersonAdmin
   */
  static readonly SaveCustomerPersonAdminPath = '/admin/customers/{customerId}/persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCustomerPerson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCustomerPerson$Response(params: {
    customerId: number;
    body?: SaveCustomerPersonRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.SaveCustomerPersonAdminPath, 'post');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCustomerPerson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCustomerPerson(params: {
    customerId: number;
    body?: SaveCustomerPersonRequest
  }): Observable<void> {

    return this.saveCustomerPerson$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getImageTokenAdmin
   */
  static readonly GetImageTokenAdminPath = '/admin/image-token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getImageToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageToken$Response(params?: {
  }): Observable<StrictHttpResponse<ImageToken>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetImageTokenAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ImageToken>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getImageToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getImageToken(params?: {
  }): Observable<ImageToken> {

    return this.getImageToken$Response(params).pipe(
      map((r: StrictHttpResponse<ImageToken>) => r.body as ImageToken)
    );
  }

  /**
   * Path part for operation mediaGetImageWithTokenAdmin
   */
  static readonly MediaGetImageWithTokenAdminPath = '/admin/medias/token/{token}/images/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaGetImageWithToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImageWithToken$Response(params: {
    mediaId: number;
    token: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaGetImageWithTokenAdminPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaGetImageWithToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImageWithToken(params: {
    mediaId: number;
    token: string;
  }): Observable<void> {

    return this.mediaGetImageWithToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mediaGetImageResizedWithTokenAdmin
   */
  static readonly MediaGetImageResizedWithTokenAdminPath = '/admin/medias/images/{mediaId}/width/{width}/height/{height}/token/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaGetImageResizedWithToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImageResizedWithToken$Response(params: {
    mediaId: number;
    width: number;
    height: number;
    token: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaGetImageResizedWithTokenAdminPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
      rb.path('width', params.width, {});
      rb.path('height', params.height, {});
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaGetImageResizedWithToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetImageResizedWithToken(params: {
    mediaId: number;
    width: number;
    height: number;
    token: string;
  }): Observable<void> {

    return this.mediaGetImageResizedWithToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mediaGetVideoWithTokenAdmin
   */
  static readonly MediaGetVideoWithTokenAdminPath = '/admin/medias/token/{token}/videos/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediaGetVideoWithToken()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetVideoWithToken$Response(params: {
    mediaId: number;
    token: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.MediaGetVideoWithTokenAdminPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `mediaGetVideoWithToken$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediaGetVideoWithToken(params: {
    mediaId: number;
    token: string;
  }): Observable<void> {

    return this.mediaGetVideoWithToken$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTagsAdmin
   */
  static readonly GetTagsAdminPath = '/admin/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Tag>>> {

    const rb = new RequestBuilder(this.rootUrl, AdminApi.GetTagsAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tag>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTags(params?: {
  }): Observable<Array<Tag>> {

    return this.getTags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Tag>>) => r.body as Array<Tag>)
    );
  }

}
