<div class="page-header">
  <h1 class="page-title">Målgrupper</h1>
  <mat-divider></mat-divider>
</div>

<div class="table-container">
  <mat-toolbar>
    <a mat-flat-button
       color="accent"
       [routerLink]="['/target-groups/create']">
      <mat-icon class="mr-2"
                inline="true">add</mat-icon>Skapa
    </a>
  </mat-toolbar>
  <table mat-table
         [dataSource]="dataSource"
         class="table-hover"
         matSort
         matSortActive="id"
         matSortDirection="desc">

    <ng-container matColumnDef="id">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> ID. </th>
      <td mat-cell
          *matCellDef="let targetGroup"> {{targetGroup.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Namn </th>
      <td mat-cell
          *matCellDef="let targetGroup"> {{targetGroup.name}} </td>
    </ng-container>

    <ng-container matColumnDef="characteristics">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Egenskaper </th>
      <td mat-cell
          *matCellDef="let targetGroup">
        <ng-container *ngFor="let key of objectKeys(targetGroup.characteristicsGrouped || {}); let i = index">
          <mat-divider *ngIf="i > 0"></mat-divider>
          <div class="py-3" *ngIf="targetGroup.characteristicsGrouped[key]?.length">
            <mat-label>{{typeLabel(+key)}}</mat-label>
            <mat-chip-list>
              <mat-chip *ngFor="let characteristic of targetGroup.characteristicsGrouped[key]">
                {{characteristic.name}}
              </mat-chip>
            </mat-chip-list>
          </div>

        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="actions-col"></th>
      <td mat-cell
          *matCellDef="let targetGroup"
          [style.width]="'1px'">
        <button mat-icon-button
                (click)="onDeleteClick($event, targetGroup)"
                class="delete-button"
                aria-label="delete item">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let targetGroup; columns: displayedColumns;"
        [routerLink]="['/target-groups', targetGroup.id]"></tr>
  </table>
</div>
