import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { CalendarItem } from './calendar-models';

@Component({
  selector: 'flow-campaign-calendar-item',
  templateUrl: 'campaign-calendar-item.component.html',
  styleUrls: ['./campaign-calendar-item.component.scss']
})

export class CampaignCalendarItemComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  @Input() item: CalendarItem;
  @Input() isSelectState: boolean = false;
  @ViewChild('campaignItemRef') campaignItemRef: ElementRef;

  isCrossingRight = false;
  isCrossingLeft = false;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router
  ) {
    super();
  }

  @HostBinding('class.bc-select-state') get selectStateClass() {
    return this.isSelectState;
  }
  @HostBinding('class.bc-item-selected') get itemSelectedClass() {
    return this.isSelectState && this.item.campaignSlot.selected;
  }
  @HostBinding('class.bc-item-disabled') get itemDisabledClass() {
    return this.isSelectState && this.item.campaignSlot.totalSlotsCount > 0 && this.item.campaignSlot.bookedSlotsCount === this.item.campaignSlot.totalSlotsCount;
  }

  ngOnInit(): void {
    const element = this.elementRef.nativeElement as HTMLElement;
    this.renderer.setStyle(element, 'left', this.item.leftPercentage + '%');
    this.renderer.setStyle(element, 'right', this.item.rightPercentage + '%');
    this.renderer.setStyle(element, 'top', this.item.topPx + 'px');
    this.isCrossingLeft = this.item.startDiffInMinutes < 0;
    this.isCrossingRight = this.item.endDiffInMinutes < 0;
  }

  ngAfterViewInit(): void {
    // const defaultColor = this.configurationService.config.colors.default;
    // if (!this.configurationService.config.colors.holding) {
    // 	console.warn('Color for HoldingCalendarItem is missing in config');
    // }
    // this.renderer.setStyle(this.holdingItemRef.nativeElement, 'background-color', this.configurationService.config.colors.holding || defaultColor);
  }

  toggleSlotSelected(event: MatCheckboxChange): void {
    this.item.campaignSlot.selected = event.checked;
    console.log('toggle', this.item.campaignSlot);
  }

  onItemClick(item: CalendarItem): void {
    if (!this.selectState && item.campaignSlot?.campaign?.id) {
      this.router.navigate(['/campaigns', item.campaignSlot.campaign.id]);
    }
  }
}
