<div class="page-header">
  <h1 *ngIf="!userId"
      class="page-title">Skapa användare</h1>
  <h1 *ngIf="userId"
      class="page-title">Redigera användare</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full">
      <mat-label>Namn</mat-label>
      <input matInput
             type="text"
             formControlName="name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>E-post</mat-label>
      <input matInput
             type="email"
             formControlName="email">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Lösenord</mat-label>
      <input matInput
             type="password"
             formControlName="password">
    </mat-form-field>
    <mat-form-field class="multi-select-field w-full">
      <mat-label>Roll</mat-label>
      <mat-select formControlName="roleIds"
                  multiple>
        <mat-option *ngFor="let role of enumService.userRoles"
                    [value]="role.value">
          {{role.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="multi-select-field w-full">
      <mat-label>Kunder</mat-label>
      <mat-select formControlName="customerIds"
                  multiple>
        <mat-option *ngFor="let customer of customers"
                    [value]="customer.id">
          {{customer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/users']">
        Avbryt
      </button>
      <button [disabled]="loading$.save | async"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
