<div class="login-wrapper">
  <mat-card class="login-card"
            *ngIf="!navigating">
    <div class="login-header">
      <div class="login-header-icon-container">
        <mat-icon>lock</mat-icon>
      </div>
    </div>
    <form #f="ngForm"
          (ngSubmit)="onLogin(f.value.email, f.value.password)"
          *ngIf="loadingLogin || !authService.isLoggedIn()">
      <mat-card-content>
        <mat-form-field class="w-full">
          <mat-label>E-post</mat-label>
          <input matInput
                 type="email"
                 name="email"
                 id="email"
                 (ngModel)="email">
        </mat-form-field>
        <mat-form-field class="w-full">
          <mat-label>Lösenord</mat-label>
          <input matInput
                 type="password"
                 name="password"
                 id="password"
                 (ngModel)="password">
        </mat-form-field>
      </mat-card-content>
      <button mat-raised-button
              color="primary"
              class="btn-block"
              [disabled]="loadingLogin">Logga in</button>

    </form>
    <div *ngIf="!loadingLogin && authService.isLoggedIn()">
      <p class="text-center mb-6">
        Du är inloggad som {{authService.user?.email}}.
      </p>
      <button mat-stroked-button
              color="accent"
              class="btn-block mr-2"
              type="button"
              (click)="logout()">Logga ut</button>
      <a mat-raised-button
         color="primary"
         class="btn-block"
         type="button"
         [routerLink]="['/']">
        <mat-icon class="mr-2"
                  inline="true">home</mat-icon>
        Till startsidan
      </a>
    </div>
  </mat-card>
</div>
