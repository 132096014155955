import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { AbstractViewsComponent } from '../abstract-views-component';


@Component({
  selector: 'flow-views-weekly',
  templateUrl: './views-weekly.component.html',
  styleUrls: ['./views-weekly.component.scss'],
})
export class ViewsWeeklyComponent extends AbstractViewsComponent {
  range = new FormGroup({
    start: new FormControl<DateTime | null>(DateTime.utc().startOf('week')),
    end: new FormControl<DateTime | null>(DateTime.utc().endOf('week')),
  });

  getColumnType(): 'day' {
    return 'day'
  }

  getStartAndEndTime() {
    const { start, end } = this.range.value
    const startTime = start?.startOf('week');
    const endTime = end?.endOf('week');

    return {
      startTime,
      endTime,
    }
  }

}

