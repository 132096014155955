import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { STORAGE_KEY_AUTH, STORAGE_KEY_USER } from 'src/app/constants';
import { User, UserApi, UserLoginResponse } from 'src/_api';
import { environment } from '../../../environments/environment';
import { CurrentUser } from './current-user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  @Output() userChanged = new EventEmitter<User>();

  private apiPath = environment.apiBaseUrl;
  authToken: UserLoginResponse;
  previousUrl: string;
  user: CurrentUser;

  constructor(
    // private http: HttpClient,
    private router: Router,
    // private toastr: ToastrService,
    private userApi: UserApi
  ) {
    this.checkForUser();
  }

  login(email: string, password: string): Observable<CurrentUser> {
    return this.userApi.login({ body: { email, password } }).pipe(
      switchMap((response) => {
        this.authToken = response;
        this.user = { email };
        localStorage.setItem(STORAGE_KEY_AUTH, JSON.stringify(this.authToken));
        return this.currentUser();
      }),
      map(user => {
        this.userChanged.emit(user);
        return user;
      })
    );
  }

  currentUser(): Observable<CurrentUser> {
    return this.userApi.currentUser().pipe(
      map(currentUserInfo => {
        this.user.id = currentUserInfo.id;
        this.user.currentCustomerId = currentUserInfo.currentCustomerId;
        this.user.customerIds = currentUserInfo.customerIds;
        this.user.roleIds = currentUserInfo.roleIds;
        localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(this.user));
        return this.user;
      })
    );
  }

  logout(): void {
    localStorage.removeItem(STORAGE_KEY_USER);
    localStorage.removeItem(STORAGE_KEY_AUTH);
    this.user = null;
    this.userChanged.emit();
  }

  getAndSetCustomerToken(customerId: number): Observable<CurrentUser> {
    return this.userApi.customerToken({ body: { customerId } }).pipe(
      map(response => {
        this.authToken = response;
        this.user.currentCustomerId = customerId;
        localStorage.setItem(STORAGE_KEY_AUTH, JSON.stringify(this.authToken));
        localStorage.setItem(STORAGE_KEY_USER, JSON.stringify(this.user));
        return this.user;
      })
    );
  }

  checkForUser(): Observable<User> {
    if (this.user) {
      return of(this.user);
    }
    const token = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH));
    if (!token || this.tokenExpired(token)) {
      this.logout();
      return of(null);
    } else {
      this.authToken = token;
      const user = JSON.parse(localStorage.getItem(STORAGE_KEY_USER));
      if (!user) {
        this.logout();
        return of(null);
      }
      this.user = user;
      console.log('logged in as user: ', user);
      return of(this.user);
    }
  }

  isLoggedIn(): boolean {
    return !!this.user;
  }

  tokenExpired(authToken: UserLoginResponse): boolean {
    const expirationDate = new Date(authToken.expires);
    const now = new Date();
    const hasExpired = expirationDate < now;
    return hasExpired;
  }

  handleUnauthorized(error: any) {
    this.logout();
    this.previousUrl = this.router.routerState.snapshot.url;
    this.router.navigateByUrl('/login');
    return throwError(() => new Error(error));
  }
}

export enum UserRoleEnum {
  None = 0,
  Admin = 1
}
