import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { Media, MediaService } from 'src/app/core/media.service';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Location, MediaFile, MediaTypeEnum, Screen } from 'src/_api';

@Component({
  selector: 'flow-screen-create',
  templateUrl: './screen-create.component.html',
  styleUrls: ['./screen-create.component.scss']
})
export class ScreenCreateComponent extends unsubscribeMixin() implements OnInit {

  screenId: number;
  screen: Screen;
  locations: Location[];
  aiVersions: string[];
  advertiserVersions: string[];
  medias: MediaFile[];
  form: FormGroup<ScreenFormGroup>;
  selectedMedia: Media;
  MediaTypeEnum = MediaTypeEnum;

  loading$ = {
    init: new BehaviorSubject(false),
    screen: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private mediaService: MediaService,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.screenId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('screenId: ', this.screenId);
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.adminApi.getLocations(),
      this.adminApi.getMedias(),
      this.adminApi.versions()
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([locations, medias, versions]) => {
        this.locations = locations;
        this.medias = medias.sort((a, b) => b.name.localeCompare(a.name));
        this.aiVersions = versions.aiVersions;
        this.advertiserVersions = versions.advertiserVersions;
      });

    if (this.screenId) {
      this.adminApi.getScreenById({ screenId: this.screenId })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.screen))
        .subscribe((screen) => {
          this.screen = screen;
          this.initForm(screen);
        });
    }
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    this.adminApi.saveScreen({ body: this.form.value })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Skärm ${this.form.value.humanUniqueIdentifier} sparad!`);
        this.router.navigate(['/screens']);
      });
  }

  private onDefaultMediaChange = (id: number): void => {
    const media = this.medias?.find(m => m.mediaId === id);
    this.adminApi.getImageToken().pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((imageToken) => {
        this.selectedMedia = this.mediaService.getMedia(media, imageToken);
      });
  }

  private initForm(screen?: Screen): void {
    this.form = this.formBuilder.group({
      id: this.screenId,
      aiVersion: [screen?.aiVersion],
      advertiserVersion: [screen?.advertiserVersion],
      humanUniqueIdentifier: [screen?.humanUniqueIdentifier, Validators.required],
      locationId: [screen?.locationId, Validators.required],
      locationDescription: [screen?.locationDescription, Validators.required],
      defaultMediaId: [screen?.defaultMediaId, Validators.required],
      note: [screen?.note],
      width: [screen?.width, Validators.required],
      height: [screen?.height, Validators.required]
    });
    if (this.screenId && !screen) {
      this.form.disable();
    } else {
      this.form.enable();
    }
    this.form.get('defaultMediaId').valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onDefaultMediaChange);
  }

}

interface ScreenFormGroup {
  id: FormControl<number>;
  aiVersion: FormControl<string>;
  advertiserVersion: FormControl<string>;
  humanUniqueIdentifier: FormControl<string>;
  locationId: FormControl<number>;
  locationDescription: FormControl<string>;
  defaultMediaId: FormControl<number>;
  note: FormControl<string>;
  width: FormControl<number>;
  height: FormControl<number>;
}
