import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MediaFile } from 'src/_api';


@Component({
  selector: 'flow-media-create-modal',
  templateUrl: './media-create.modal.html',
  styleUrls: ['./media-create.modal.scss']
})
export class MediaCreateModal implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MediaCreateModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData
  ) { }

  ngOnInit(): void {
    console.log(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSaved(media: MediaFile): void {
    this.dialogRef.close(media);
  }
}

interface ModalData {
  media?: MediaFile;
}
