<div class="page-header">
  <h1 class="page-title">Boka kampanj</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">

  <mat-card class="form-container mb-5">
    <mat-tab-group #tabGroup
                   mat-stretch-tabs
                   class="mb-5"
                   [selectedIndex]="selectedTabIndex"
                   (selectedIndexChange)="onSelectedTabIndexChange($event)">

      <mat-tab label="Kampanjinfo & målgrupper">
        <ng-template matTabContent>
          <!-- <div class="grid lg:grid-cols-2 gap-4"> -->
          <mat-form-field class="w-full">
            <mat-label>Kampanjnamn</mat-label>
            <input matInput
                   type="text"
                   formControlName="name">
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Kampanjtyp</mat-label>
            <mat-select formControlName="type">
              <mat-option *ngFor="let type of enumService.campaignTypes"
                          [value]="type">
                {{type.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="w-full">
            <mat-label>Kund</mat-label>
            <mat-select formControlName="customer">
              <mat-option *ngFor="let customer of customers"
                          [value]="customer">
                {{customer.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="multi-select-field w-full">
            <mat-label>Butiker</mat-label>
            <mat-select formControlName="locations"
                        multiple>
              <mat-select-trigger>
                <flow-multi-select-chips [control]="form.get('locations')"
                                         displayKey="name"></flow-multi-select-chips>
              </mat-select-trigger>
              <mat-option *ngFor="let location of locations"
                          [value]="location">
                {{location.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="multi-select-field w-full">
            <mat-label>Målgrupper</mat-label>
            <mat-select formControlName="targetGroups"
                        multiple>
              <mat-select-trigger>
                <flow-multi-select-chips [control]="form.get('targetGroups')"
                                         displayKey="name"></flow-multi-select-chips>
              </mat-select-trigger>
              <mat-option *ngFor="let targetGroup of targetGroups"
                          [value]="targetGroup">
                {{targetGroup.name}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
          <!-- </div> -->
        </ng-template>
      </mat-tab>

      <mat-tab label="Period & skärmar">
        <ng-template matTabContent>
          <mat-card class="alert alert--info mb-3"
                    *ngIf="!form.value?.type || !form.value?.locations?.length">
            Välj <strong>kampanjtyp</strong> och <strong>butiker</strong> i föregående steg för att se vilka skärmar som
            går att boka.
          </mat-card>
          <mat-form-field>
            <mat-label>Period</mat-label>
            <mat-date-range-input [min]="minDT"
                                  formGroupName="calendarSpan"
                                  [rangePicker]="picker">
              <input matStartDate
                     formControlName="start"
                     placeholder="Från">
              <input matEndDate
                     formControlName="end"
                     placeholder="Till">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix
                                   [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker [min]="minDT"
                                   #picker></mat-date-range-picker>

            <mat-error *ngIf="form.get('calendarSpan.start').hasError('matStartDateInvalid')">Ogiltigt fråndatum
            </mat-error>
            <mat-error *ngIf="form.get('calendarSpan.end').hasError('matEndDateInvalid')">Ogiltigt tilldatum</mat-error>
          </mat-form-field>
          <flow-campaign-calendar [campaignSlots]="campaignSlots"
                                  [isSelectState]="true"></flow-campaign-calendar>
        </ng-template>
      </mat-tab>

      <mat-tab label="Granska">
        <ng-template matTabContent>
          <flow-form-field-group label="Kampanjinfo & målgrupper"
                                 [bordered]="false">
            <div class="sm:flex sm:flex-col md:block">
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kampanjnamn</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="form.value.name"
                       [disabled]="true">
              </mat-form-field>
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kampanjtyp</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="form.value?.type?.name"
                       [disabled]="true">
              </mat-form-field>
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kund</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="form.value?.customer?.name"
                       [disabled]="true">
              </mat-form-field>
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Butiker</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="locationsValueString()"
                       [disabled]="true">
              </mat-form-field>
              <!-- <mat-form-field class="form-field-readonly">
                <mat-label>Målgrupper</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="targetGroupsValueString()"
                       [disabled]="true">
              </mat-form-field> -->
            </div>
          </flow-form-field-group>
          <flow-form-field-group label="Valda perioder & skärmar"
                                 [bordered]="false">
            <flow-campaign-calendar *ngIf="selectedCampaignSlots?.length"
                                    [campaignSlots]="selectedCampaignSlots"></flow-campaign-calendar>
          </flow-form-field-group>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/campaigns']">
        Avbryt
      </button>
      <button *ngIf="selectedTabIndex < 2"
              type="button"
              mat-flat-button
              class="btn-next"
              color="primary"
              (click)="tabGroup.selectedIndex = 2">
        Granska<mat-icon class="ml-2"
                  inline="true">grading</mat-icon>
      </button>
      <button *ngIf="selectedTabIndex === 2"
              [disabled]="(loading$.save | async) || !form.valid"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">send</mat-icon>Boka
      </button>
    </div>
  </mat-toolbar>
</form>
