/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CurrentUserInfoResponse } from '../models/current-user-info-response';
import { CustomerTokenRequest } from '../models/customer-token-request';
import { DummyResult } from '../models/dummy-result';
import { User } from '../models/user';
import { UserLoginRequest } from '../models/user-login-request';
import { UserLoginResponse } from '../models/user-login-response';
import { UserRegisterRequest } from '../models/user-register-request';

@Injectable({
  providedIn: 'root',
})
export class UserApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation loginUser
   */
  static readonly LoginUserPath = '/users/login';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `login()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login$Response(params?: {
    body?: UserLoginRequest
  }): Observable<StrictHttpResponse<UserLoginResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.LoginUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserLoginResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `login$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  login(params?: {
    body?: UserLoginRequest
  }): Observable<UserLoginResponse> {

    return this.login$Response(params).pipe(
      map((r: StrictHttpResponse<UserLoginResponse>) => r.body as UserLoginResponse)
    );
  }

  /**
   * Path part for operation getUsersUser
   */
  static readonly GetUsersUserPath = '/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<User>>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.GetUsersUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<User>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsers(params?: {
  }): Observable<Array<User>> {

    return this.getUsers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<User>>) => r.body as Array<User>)
    );
  }

  /**
   * Path part for operation getUserUser
   */
  static readonly GetUserUserPath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<User>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.GetUserUserPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<User>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUser(params: {
    userId: number;
  }): Observable<User> {

    return this.getUser$Response(params).pipe(
      map((r: StrictHttpResponse<User>) => r.body as User)
    );
  }

  /**
   * Path part for operation deleteUserUser
   */
  static readonly DeleteUserUserPath = '/users/{userId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: {
    userId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.DeleteUserUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: {
    userId: number;
  }): Observable<void> {

    return this.deleteUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation registerUser
   */
  static readonly RegisterUserPath = '/users/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `register()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  register$Response(params?: {
    body?: UserRegisterRequest
  }): Observable<StrictHttpResponse<DummyResult>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.RegisterUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DummyResult>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `register$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  register(params?: {
    body?: UserRegisterRequest
  }): Observable<DummyResult> {

    return this.register$Response(params).pipe(
      map((r: StrictHttpResponse<DummyResult>) => r.body as DummyResult)
    );
  }

  /**
   * Path part for operation currentUserUser
   */
  static readonly CurrentUserUserPath = '/users/current';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `currentUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  currentUser$Response(params?: {
  }): Observable<StrictHttpResponse<CurrentUserInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.CurrentUserUserPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CurrentUserInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `currentUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  currentUser(params?: {
  }): Observable<CurrentUserInfoResponse> {

    return this.currentUser$Response(params).pipe(
      map((r: StrictHttpResponse<CurrentUserInfoResponse>) => r.body as CurrentUserInfoResponse)
    );
  }

  /**
   * Path part for operation customerTokenUser
   */
  static readonly CustomerTokenUserPath = '/users/token/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customerToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerToken$Response(params?: {
    body?: CustomerTokenRequest
  }): Observable<StrictHttpResponse<UserLoginResponse>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.CustomerTokenUserPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserLoginResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `customerToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  customerToken(params?: {
    body?: CustomerTokenRequest
  }): Observable<UserLoginResponse> {

    return this.customerToken$Response(params).pipe(
      map((r: StrictHttpResponse<UserLoginResponse>) => r.body as UserLoginResponse)
    );
  }

  /**
   * Path part for operation addUserToCustomerUser
   */
  static readonly AddUserToCustomerUserPath = '/users/{userId}/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUserToCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  addUserToCustomer$Response(params: {
    userId: number;
    customerId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.AddUserToCustomerUserPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addUserToCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addUserToCustomer(params: {
    userId: number;
    customerId: number;
  }): Observable<void> {

    return this.addUserToCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeUserFromCustomerUser
   */
  static readonly RemoveUserFromCustomerUserPath = '/users/{userId}/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeUserFromCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromCustomer$Response(params: {
    userId: number;
    customerId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.RemoveUserFromCustomerUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeUserFromCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeUserFromCustomer(params: {
    userId: number;
    customerId: number;
  }): Observable<void> {

    return this.removeUserFromCustomer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation addRoleUser
   */
  static readonly AddRoleUserPath = '/users/{userId}/role/{roleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  addRole$Response(params: {
    userId: number;
    roleId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.AddRoleUserPath, 'post');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addRole(params: {
    userId: number;
    roleId: number;
  }): Observable<void> {

    return this.addRole$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation removeRoleFromUserUser
   */
  static readonly RemoveRoleFromUserUserPath = '/users/{userId}/role/{roleId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeRoleFromUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleFromUser$Response(params: {
    userId: number;
    roleId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UserApi.RemoveRoleFromUserUserPath, 'delete');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('roleId', params.roleId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeRoleFromUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeRoleFromUser(params: {
    userId: number;
    roleId: number;
  }): Observable<void> {

    return this.removeRoleFromUser$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
