<div class="page-header">
  <h1 class="page-title"
      *ngIf="!campaignId">Skapa kampanj</h1>
  <h1 class="page-title"
      *ngIf="campaignId">Redigera kampanj</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">

  <mat-card class="form-container mb-5">
    <mat-tab-group #tabGroup
                   mat-stretch-tabs
                   class="mb-5"
                   [selectedIndex]="selectedTabIndex"
                   (selectedIndexChange)="onSelectedTabIndexChange($event)">

      <mat-tab label="Kampanj">
        <ng-template matTabContent>
          <flow-form-field-group label="Kampanjinfo & målgrupper"
                                 [bordered]="false">
            <div class="sm:flex sm:flex-col md:block">
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kampanjnamn</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="campaign?.name || '-'"
                       [disabled]="true">
              </mat-form-field>
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kampanjtyp</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="campaignTypeName(campaign?.type)"
                       [disabled]="true">
              </mat-form-field>
              <mat-form-field class="form-field-readonly mr-3">
                <mat-label>Kund</mat-label>
                <input matInput
                       type="text"
                       [readonly]="true"
                       [value]="campaign?.customerName || '-'"
                       [disabled]="true">
              </mat-form-field>
            </div>
          </flow-form-field-group>
          <flow-form-field-group label="Perioder & skärmar"
                                 [bordered]="false">
            <flow-campaign-calendar *ngIf="campaign?.calendarSlots?.length"
                                    [campaignSlots]="campaign.calendarSlots"></flow-campaign-calendar>
          </flow-form-field-group>
        </ng-template>
      </mat-tab>

      <mat-tab label="Annonser">
        <ng-template matTabContent>
          <button type="button"
                  mat-raised-button
                  class="mb-3"
                  color="accent"
                  (click)="onAddAdvertisementRow()">
            <mat-icon class="mr-2"
                      inline="true">add</mat-icon>Lägg till
          </button>
          <ng-container formArrayName="advertisements"
                        *ngFor="let advGroup of advertisementsFormArray.controls; let i = index">
            <div class="grid lg:grid-cols-4 2xl:grid-cols-3 gap-4"
                 [formGroupName]="i">
              <mat-form-field>
                <mat-label>Media</mat-label>
                <mat-select formControlName="media">
                  <mat-option class="option-create"
                              [value]="null"
                              (click)="openCreateMediaModal(advGroup)">Skapa ny media</mat-option>
                  <mat-option class="option-create"
                              [value]="null"
                              (click)="openSelectMediaModal(advGroup)">Välj media från biblioteket</mat-option>
                  <mat-option *ngFor="let media of selectedMedias"
                              [value]="media">
                    {{media.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field>
                <mat-label>Målgrupper</mat-label>
                <mat-select formControlName="targetGroups"
                            multiple>
                  <mat-option *ngFor="let targetGroup of targetGroups"
                              [value]="targetGroup.value">
                    {{targetGroup.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="flex items-center col-span-2 2xl:col-span-1">
                <mat-form-field class="flex-1 mr-3">
                  <mat-label>Perioder & skärmar</mat-label>
                  <mat-select formControlName="slotSelection">
                    <mat-option [value]="SlotSelectionEnum.All">
                      Alla ({{campaign?.calendarSlots?.length}} st)
                    </mat-option>
                    <mat-option [value]="SlotSelectionEnum.Landscape"
                                [disabled]="!landscapeSlots()?.length">
                      Landskap ({{landscapeSlots().length}} st)
                    </mat-option>
                    <mat-option [value]="SlotSelectionEnum.Portrait"
                                [disabled]="!portraitSlots()?.length">
                      Porträtt ({{portraitSlots().length}} st)
                    </mat-option>
                    <mat-option [value]="SlotSelectionEnum.Custom"
                                (click)="this.openCampaignSelectSlotsModal(advGroup);">
                      Anpassa {{customSlotsCountLabel(advGroup)}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <button type="button"
                        color="accent"
                        mat-stroked-button
                        class="mb-5 py-1 mx-2"
                        (click)="openCampaignEditTimesModal(advGroup)">
                  Tider
                  <mat-icon inline="true">calendar_month</mat-icon>
                </button>
                <button *ngIf="advertisementsFormArray.length > 1"
                        type="button"
                        mat-icon-button
                        class="delete-button mb-5"
                        (click)="onRemoveAdvertisementRow(advGroup)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <mat-divider class="lg:hidden last:hidden mt-5 mb-10"></mat-divider>
          </ng-container>
        </ng-template>

      </mat-tab>

    </mat-tab-group>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/campaigns']">
        Avbryt
      </button>
      <button mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">send</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
