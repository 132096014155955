import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Media } from 'src/app/core/media.service';


@Component({
  selector: 'flow-media-select-modal',
  templateUrl: './media-select.modal.html',
  styleUrls: ['./media-select.modal.scss']
})
export class MediaSelectModal implements OnInit {

  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<MediaSelectModal>
  ) { }

  ngOnInit(): void { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSelected(media: Media): void {
    this.dialogRef.close(media);
  }
}
