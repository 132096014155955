import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer } from 'src/_api';
import { CustomerTypeTranslations } from './customer-type-translations';

@Component({
  selector: 'flow-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['id', 'name', 'organizationId', 'type', 'person', 'actions'];
  dataSource: MatTableDataSource<CustomerListItem>;
  CustomerTypeTranslations = CustomerTypeTranslations;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getCustomers();
  }

  getCustomers(): void {
    this.adminApi.getCustomers()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((customers) => {
        this.dataSource = new MatTableDataSource(customers);
        this.dataSource.sort = this.sort;
      });
  }

  onDeleteClick(event: Event, customer: CustomerListItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeCustomer({ customerId: customer.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getCustomers();
      });
  }

  contactPersonLabel(customer: Customer): string {
    return customer.persons?.map(c => c.name)?.join(', ');
  }
}

interface CustomerListItem extends Customer {

}
