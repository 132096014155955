<h1 mat-dialog-title>Skapa kontaktperson</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="w-full">
      <mat-label>Namn</mat-label>
      <input matInput
             type="text"
             formControlName="name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>E-postadress</mat-label>
      <input matInput
             type="mail"
             formControlName="email">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Telefon</mat-label>
      <input matInput
             type="phone"
             formControlName="phone">
    </mat-form-field>
    <div class="mb-3">
      <mat-checkbox formControlName="personOfInterestNotification">Notifiera vid "Person of Interest"</mat-checkbox>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button
          type="button"
          (click)="onCancel()"
          [disabled]="loading$.save | async">Avbryt</button>
  <button mat-raised-button
          type="button"
          (click)="onSave()"
          color="primary"
          cdkFocusInitial
          [disabled]="loading$.save | async">Spara</button>
</div>
