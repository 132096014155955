import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { isEqual } from 'lodash-es';
import { DateTime } from "luxon";
import { BehaviorSubject, forkJoin, map, Observable, switchMap, takeUntil } from "rxjs";
import { AppService } from "src/app/app.service";
import { EnumObject, EnumService } from "src/app/core/enum.service";
import { indicateLoading } from "src/app/core/indicate-loading";
import { Media } from "src/app/core/media.service";
import { SystemMessageService } from "src/app/core/system-message.service";
import { unsubscribeMixin } from "src/app/core/unsubscribe";
import { AdvertisementDetail, AdvertisementDetailMedia, CampaignApi, CampaignTypeEnum, Location, MediaFile, SaveAdvertisementDetailRequest, Screen, TargetGroupEnum } from "src/_api";
import { MediaCreateModal } from "../../media/create/media-create.modal";
import { MediaSelectModal } from "../../media/media-select.modal";
import { CampaignCalendarSlot } from "../calendar/calendar-models";
import { CampaignEdit, CampaignEditAdvertisement, CampaignEditAdvertisementFormGroup, CampaignEditFormGroup, CampaignEditTimesModalResult, SlotSelectionEnum } from "./campaign-edit-models";
import { CampaignEditTimesModal } from "./campaign-edit-times.modal";
import { CampaignSelectSlotsModal } from "./campaign-select-slots.modal";

@Component({
  selector: 'flow-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss']
})
export class CampaignEditComponent extends unsubscribeMixin() implements OnInit {

  campaignId: number;
  campaign: CampaignEdit;
  form: FormGroup<CampaignEditFormGroup>;
  advertisementsFormArray: FormArray<FormGroup<CampaignEditAdvertisementFormGroup>>;
  selectedTabIndex: number;
  selectedMedias: AdvertisementDetailMedia[] = [];
  targetGroups: EnumObject<TargetGroupEnum>[];
  SlotSelectionEnum = SlotSelectionEnum;

  loading$ = {
    init: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private enumService: EnumService,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private activatedRoute: ActivatedRoute,
    private campaignApi: CampaignApi,
    public dialog: MatDialog,
    private systemMessageService: SystemMessageService,
    private router: Router
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.campaignId = +this.activatedRoute.snapshot.params['id'] || null;
    this.selectedTabIndex = 0;
    console.log('campaignId: ', this.campaignId);
    this.initForm();
  }

  ngOnInit(): void {
    this.campaignApi.getCampaign({ campaignId: this.campaignId }).pipe(
      switchMap(campaign => forkJoin([
        this.campaignApi.getAdvertisementsByCampaign({ campaignId: campaign.id }),
        this.campaignApi.getCampaignScreens({ campaignId: campaign.id })
      ]).pipe(
        map(([campaignAdvertisements, screens]) => {
          this.screens = screens;
          const campaignEdit: CampaignEdit = {
            name: campaign.name,
            type: campaign.type,
            customerName: campaign.customer?.name,
            advertisements: campaignAdvertisements?.map(({ id, media, targetGroups, weekDays, slots }) => {
              const advertisement: CampaignEditAdvertisement = {
                id, media, targetGroups, weekDays,
                slotIds: slots?.map(slot => slot.campaignSlotId)
              };
              return advertisement;
            }),
            calendarSlots: campaign.slots.map(slot => {
              const identifier = slot.screenId + '_' + slot.startDate;
              const start = DateTime.fromISO(slot.startDate).toUTC();
              const end = start.endOf('week');
              const screen: Screen = screens?.find(screen => screen.id === slot.screenId);
              const location: Location = { id: screen.locationId, name: screen.locationName };
              return { identifier, start, end, screen, location, campaign: null, campaignSlotId: slot.campaignSlotId };
            })
          };
          return campaignEdit
        }))),
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init)
    ).subscribe(campaignEdit => {
      this.campaign = campaignEdit;
      this.targetGroups = this.enumService.targetGroups;
      this.selectedMedias = campaignEdit.advertisements?.map(adv => adv.media);
      this.initForm(this.campaign);
    });
  }

  onSelectedTabIndexChange = (index: number): void => {
    this.selectedTabIndex = index;
  }

  campaignTypeName = (type: CampaignTypeEnum): string => {
    return this.enumService.campaignTypes.find(ct => ct.value === type)?.name || '-';
  }

  campaignLocationNames = (locations: Location[]): string => {
    return locations?.map(loc => loc.name).join(', ') || '-';
  }

  campaignTargetGroupNames = (targetGroups: TargetGroupEnum[]): string => {
    return targetGroups?.map(tg => this.enumService.targetGroups.find(tgObj => tgObj.value === tg)?.name).join(', ') || '-';
  }

  landscapeSlots(): CampaignCalendarSlot[] {
    return this.campaign.calendarSlots?.filter(slot => slot.screen.width > slot.screen.height) || [];
  }

  portraitSlots(): CampaignCalendarSlot[] {
    return this.campaign.calendarSlots?.filter(slot => slot.screen.width < slot.screen.height) || [];
  }

  customSlotsCountLabel(advGroup: FormGroup<CampaignEditAdvertisementFormGroup>): string {
    const slotsCount = advGroup.value?.slotIds?.length;
    return slotsCount ? `(${slotsCount} st)` : '';
  }

  onAddAdvertisementRow(): void {
    this.advertisementsFormArray.push(this.toAdvertisementFormGroup(null));
  }

  onRemoveAdvertisementRow(fg: FormGroup<CampaignEditAdvertisementFormGroup>): void {
    this.advertisementsFormArray.removeAt(this.advertisementsFormArray.controls.indexOf(fg));
  }

  // isTargetGroupSelected(tg: TargetGroupEnum, advGroup: AbstractControl): boolean {
  //   return this.advertisementsFormArray.controls.filter(fg => fg !== advGroup)
  //     ?.some(aFg => aFg.value?.targetGroups?.some(selectedTg => selectedTg === tg));
  // }

  openCreateMediaModal(advGroup: FormGroup): void {
    const dialogRef = this.dialog.open(MediaCreateModal, {
      width: '800px'
    });

    dialogRef.afterClosed().subscribe((media: MediaFile) => {
      if (media) {
        const advMedia = { mediaId: media.mediaId, name: media.name };
        this.selectedMedias.splice(0, 0, advMedia);
        advGroup.get('media').setValue(advMedia);
      }
    });
  }

  openSelectMediaModal(advGroup: FormGroup): void {
    const dialogRef = this.dialog.open(MediaSelectModal, {
      width: '1200px'
    });

    dialogRef.afterClosed().subscribe((media: Media) => {
      if (media) {
        const advMedia = { mediaId: media.id, name: media.name };
        if (!this.selectedMedias.some(sm => sm.mediaId === media.id)) {
          this.selectedMedias.splice(0, 0, advMedia);
        }
        advGroup.get('media').setValue(advMedia);
      }
    });
  }

  openCampaignSelectSlotsModal(advGroup: FormGroup): void {
    const dialogRef = this.dialog.open(CampaignSelectSlotsModal, {
      width: '800px',
      data: {
        advGroup,
        calendarSlots: this.campaign.calendarSlots,
        screens: this.screens
      }
    });

    dialogRef.afterClosed().subscribe((selectedSlots: CampaignCalendarSlot[]) => {
      if (selectedSlots) {
        console.log(selectedSlots);
        const slotsControl = advGroup.get('slotIds');
        slotsControl.setValue(selectedSlots.map(s => s.campaignSlotId));
        const slotSelectionControl = advGroup.get('slotSelection');
        slotSelectionControl.updateValueAndValidity();
      }
    });
  }

  openCampaignEditTimesModal(advGroup: FormGroup): void {
    const dialogRef = this.dialog.open(CampaignEditTimesModal, {
      width: '550px',
      data: {
        advGroup
      }
    });

    dialogRef.afterClosed().subscribe((values: CampaignEditTimesModalResult) => {
      if (values) {
        console.log(values);
        advGroup.get('weekDays').setValue(values.weekDays);
        advGroup.get('startTime').setValue(values.startTime);
        advGroup.get('endTime').setValue(values.endTime);
      }
    });
  }

  onSaveClick(): void {
    console.log(this.form);
    if (!this.form.valid) {
      return;
    }
    const advertisements: CampaignEditAdvertisement[] = this.form.value?.advertisements;
    const requests: Observable<AdvertisementDetail>[] = [];
    advertisements?.forEach(adv => {
      const body: SaveAdvertisementDetailRequest = {
        id: adv.id,
        mediaId: adv.media?.mediaId,
        targetGroups: adv.targetGroups,
        weekDays: adv.weekDays,
        campaignSlotIds: this.getSelectedSlotIds(adv)
      };
      (body as any).startTime = adv.startTime;
      (body as any).endTime = adv.endTime;
      requests.push(this.campaignApi.saveAdvertisement({ body }));
    });
    forkJoin(requests).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.init))
      .subscribe(savedAdvertisements => {
        console.log(savedAdvertisements);
        this.systemMessageService.success(`Kampanj ${this.campaign.name} har sparats`);
        this.router.navigate(['/campaigns']);
      });
  }

  private initForm(campaign?: CampaignEdit): void {
    this.advertisementsFormArray = campaign?.advertisements?.length ?
      this.formBuilder.array(campaign.advertisements.map(a => this.toAdvertisementFormGroup(a))) :
      this.formBuilder.array([this.toAdvertisementFormGroup(null)]);
    this.form = this.formBuilder.group({
      id: this.campaignId,
      advertisements: this.advertisementsFormArray
    });
    if (this.campaignId && !campaign) {
      this.form.disable();
    } else if (this.campaignId) {
      setTimeout(() => {
        this.form.enable();
      });
    }
  }

  private toAdvertisementFormGroup(advertisement: CampaignEditAdvertisement): FormGroup<CampaignEditAdvertisementFormGroup> {
    return this.formBuilder.group({
      id: advertisement?.id,
      media: [advertisement?.media, Validators.required],
      targetGroups: this.formBuilder.control(advertisement?.targetGroups, Validators.required),
      slotSelection: new FormControl(this.getSlotSelection(advertisement), this.slotsRequiredValidator),
      slotIds: this.formBuilder.control(advertisement?.slotIds),
      weekDays: this.formBuilder.control(advertisement?.weekDays),
      startTime: this.formBuilder.control(advertisement?.startTime),
      endTime: this.formBuilder.control(advertisement?.endTime)
    });
  }

  private getSlotSelection(advertisement: CampaignEditAdvertisement): SlotSelectionEnum {
    if (!advertisement?.slotIds?.length) {
      return SlotSelectionEnum.All;
    }
    const advSlotIds = advertisement.slotIds.sort((a, b) => a - b);
    const landscapeSlotIds = this.landscapeSlots().map(cs => cs.campaignSlotId).sort((a, b) => a - b);
    if (isEqual(landscapeSlotIds, advSlotIds)) {
      return SlotSelectionEnum.Landscape;
    }
    const portraitSlotIds = this.portraitSlots().map(cs => cs.campaignSlotId).sort((a, b) => a - b);
    if (isEqual(portraitSlotIds, advSlotIds)) {
      return SlotSelectionEnum.Portrait;
    }
    return SlotSelectionEnum.Custom;
  }

  private getSelectedSlotIds(advertisement: CampaignEditAdvertisement): number[] {
    if (advertisement.slotSelection === SlotSelectionEnum.All) {
      return this.campaign.calendarSlots.map(cs => cs.campaignSlotId);
    } else if (advertisement.slotSelection === SlotSelectionEnum.Landscape) {
      return this.landscapeSlots().map(cs => cs.campaignSlotId);
    } else if (advertisement.slotSelection === SlotSelectionEnum.Portrait) {
      return this.portraitSlots().map(cs => cs.campaignSlotId);
    } else {
      return advertisement?.slotIds;
    }
  }

  private slotsRequiredValidator = (control: AbstractControl<any>): ValidationErrors => {
    if (!control.parent) {
      return null;
    }
    const slotSelection: SlotSelectionEnum = control.value;
    if (!slotSelection) {
      return { required: true };
    }
    if (slotSelection === SlotSelectionEnum.Custom) {
      const slotIds: number[] = control.parent.get('slotIds')?.value;
      if (!slotIds?.length) {
        return { slotIdsRequired: true }
      }
    }
    return null;
  };
}
