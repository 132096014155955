<h1 mat-dialog-title>Ange tider</h1>
<div mat-dialog-content
     class="py-2">
  <form [formGroup]="form">
    <flow-form-field-group formGroupName="weekDays"
                           label="Dagar"
                           [bordered]="true">
      <div class="grid sm:grid-cols-7 grid-cols-4 gap-2">
        <mat-checkbox *ngFor="let weekDay of enumService.weekDays"
                      [formControlName]="weekDay.value">
          {{weekDay.name}}
        </mat-checkbox>
      </div>
    </flow-form-field-group>

    <flow-form-field-group class="mt-5"
                           label="Tid"
                           [bordered]="true">
      <div class="grid grid-cols-2 gap-2">
        <mat-form-field>
          <mat-label>Start</mat-label>
          <input matInput
                 type="time"
                 formControlName="startTime">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Slut</mat-label>
          <input matInput
                 type="time"
                 formControlName="endTime">
        </mat-form-field>
      </div>
    </flow-form-field-group>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button
          type="button"
          (click)="onCancel()">Avbryt</button>
  <button mat-raised-button
          type="button"
          (click)="onDone()"
          color="primary"
          cdkFocusInitial>
    Klar
  </button>
</div>
