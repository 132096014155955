<div class="flex flex-col">
  <year-and-month-picker [formValue]="date"
                         (monthChanged)="onMonthChange($event)"
                         class="mt-4"></year-and-month-picker>
</div>
<canvas baseChart
        [data]="chartData"
        [type]="'pie'"
        [options]="chartOptions"
        [plugins]="chartPlugins">
</canvas>
