import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { AdvertisementDetailMedia, CampaignTypeEnum, TargetGroupEnum, WeekDayEnum } from "src/_api";
import { CampaignCalendarSlot } from "../calendar/calendar-models";

export interface CampaignEditFormGroup {
  id: FormControl<number>;
  advertisements: FormArray<FormGroup<CampaignEditAdvertisementFormGroup>>;
}

export interface CampaignEditAdvertisementFormGroup {
  id: FormControl<number>;
  media: FormControl<AdvertisementDetailMedia>;
  targetGroups: FormControl<TargetGroupEnum[]>;
  slotSelection: FormControl<SlotSelectionEnum>;
  slotIds: FormControl<number[]>;
  weekDays: FormControl<WeekDayEnum[]>;
  startTime: FormControl<CampaignEditTime>;
  endTime: FormControl<CampaignEditTime>;
}

export interface CampaignEdit {
  name: string;
  type: CampaignTypeEnum;
  customerName: string;
  // locations: Location[];
  // targetGroups: TargetGroupEnum[];
  calendarSlots: CampaignCalendarSlot[];
  advertisements: CampaignEditAdvertisement[];
}

export interface CampaignEditAdvertisement {
  id?: number;
  media?: AdvertisementDetailMedia;
  targetGroups?: TargetGroupEnum[];
  slotSelection?: SlotSelectionEnum;
  slotIds?: number[];
  weekDays?: WeekDayEnum[];
  startTime?: CampaignEditTime;
  endTime?: CampaignEditTime;
}

export interface SlotSelection {
  value: SlotSelectionEnum;
  name: string;
  count: number;
}

export enum SlotSelectionEnum {
  None,
  All,
  Landscape,
  Portrait,
  Custom
}

export const SlotSelectionTranslations = {
  [SlotSelectionEnum.None]: '',
  [SlotSelectionEnum.All]: 'Alla',
  [SlotSelectionEnum.Landscape]: 'Landskap',
  [SlotSelectionEnum.Portrait]: 'Porträtt',
  [SlotSelectionEnum.Custom]: 'Anpassa'
};

export const WeekdayTranslations = {
  [WeekDayEnum.Monday]: 'Mån',
  [WeekDayEnum.Tuesday]: 'Tis',
  [WeekDayEnum.Wednesday]: 'Ons',
  [WeekDayEnum.Thursday]: 'Tor',
  [WeekDayEnum.Friday]: 'Fre',
  [WeekDayEnum.Saturday]: 'Lör',
  [WeekDayEnum.Sunday]: 'Sön',
};

export interface CampaignEditTimesModalResult {
  weekDays: WeekDayEnum[];
  startTime: CampaignEditTime;
  endTime: CampaignEditTime;
}

export interface CampaignEditTime {
  hours: number;
  minutes: number;
}
