import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { EnumService } from 'src/app/core/enum.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { Screen } from 'src/_api';
import { ScreenOrientationEnum } from '../../screen/screen-orientation';
import { CampaignCalendarSlot } from '../calendar/calendar-models';
import { CampaignEditAdvertisementFormGroup } from './campaign-edit-models';


@Component({
  selector: 'flow-campaign-select-slots-modal',
  templateUrl: './campaign-select-slots.modal.html',
  styleUrls: ['./campaign-select-slots.modal.scss']
})
export class CampaignSelectSlotsModal extends unsubscribeMixin() implements OnInit {

  filterForm: FormGroup<FilterSlotsFormGroup>;
  filteredScreens: Screen[];
  visibleSlots: CampaignCalendarSlot[];

  constructor(
    public dialogRef: MatDialogRef<CampaignSelectSlotsModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public enumService: EnumService,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  get selectedSlotsCount() {
    return this.visibleSlots?.filter(slot => slot.selected).length;
  }

  ngOnInit(): void {
    this.initForm();
    this.filteredScreens = this.data.screens.slice(0);
    this.visibleSlots = this.data.calendarSlots.slice(0);
    const slotIds = this.data.advGroup.value?.slotIds;
    if (slotIds?.length) {
      this.visibleSlots.forEach(slot => {
        slot.selected = slotIds.some(sId => sId === slot.campaignSlotId);
      });
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDone(): void {
    const selectedSlots = this.visibleSlots?.filter(slot => slot.selected) || [];
    this.dialogRef.close(selectedSlots);
  }

  screenLabel(screen: Screen): string {
    const layout = screen.width && screen.height ? `${screen.width} x ${screen.height}` : 'ej specificerad';
    return `${screen.humanUniqueIdentifier} - Layout: ${layout}`;
  }

  toggleSlots(): void {
    const allSelected = this.visibleSlots.every(slot => slot.selected);
    this.visibleSlots?.forEach(slot => {
      slot.selected = allSelected ? false : true;
    });
  }

  onOrientationChange = (orientation: ScreenOrientationEnum): void => {
    this.filteredScreens = this.data.screens.filter(screen => this.filterScreenByOrientation(orientation, screen));
    this.filterVisibleSlots(orientation, this.filterForm.value?.screenIds);
  };

  onSelectedScreensChange = (screenIds: number[]): void => {
    if (!screenIds?.length) {
      this.visibleSlots = this.data.calendarSlots?.slice(0) || [];
      return;
    }
    this.filterVisibleSlots(this.filterForm.value?.screenOrientation, screenIds);
  };

  filterVisibleSlots = (orientation: ScreenOrientationEnum, screenIds: number[]): void => {
    this.visibleSlots = this.data.calendarSlots?.filter(slot => {
      if (screenIds?.length) {
        return screenIds.some(sId => slot.screen?.id === sId);
      } else {
        return this.filterScreenByOrientation(orientation, slot.screen)
      }
    });
  };

  private filterScreenByOrientation = (orientation: ScreenOrientationEnum, screen: Screen): boolean => {
    if (orientation === ScreenOrientationEnum.Landscape) {
      return screen.width > screen.height;
    } else if (orientation === ScreenOrientationEnum.Portrait) {
      return screen.width < screen.height;
    } else {
      return true;
    }
  }

  private initForm(): void {
    this.filterForm = this.formBuilder.group({
      screenOrientation: ScreenOrientationEnum.None as ScreenOrientationEnum,
      screenIds: this.formBuilder.control([])
    });

    this.filterForm.get('screenOrientation').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onOrientationChange);

    this.filterForm.get('screenIds').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(this.onSelectedScreensChange);
  }
}

interface ModalData {
  advGroup?: FormGroup<CampaignEditAdvertisementFormGroup>;
  screens: Screen[];
  calendarSlots: CampaignCalendarSlot[];
}

interface FilterSlotsFormGroup {
  screenOrientation: FormControl<ScreenOrientationEnum>;
  screenIds: FormControl<number[]>;
}
