import { Component } from '@angular/core';
import { ChartConfiguration, ChartData } from 'chart.js';
import { takeUntil } from 'rxjs';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { AbstractPeopleCountTargetGroup } from '../abstract-people-count-target-group';
import { FormControl } from '@angular/forms';
import { DateTime } from 'luxon';
import { TargetGroupEnum } from 'src/_api/models/target-group-enum';
import { PeopleCounterDailyRequest, PeopleCounterRequest } from 'src/_api';
import { STATISTICS_START_DATE } from 'src/app/constants';

@Component({
  selector: 'flow-people-count-target-group-daily',
  templateUrl: './people-count-target-group-daily.component.html',
  styleUrls: ['./people-count-target-group-daily.component.scss']
})
export class PeopleCountTargetGroupDailyComponent extends AbstractPeopleCountTargetGroup {
  date = new FormControl(DateTime.utc());
  maxDate = DateTime.utc()
  minDate = STATISTICS_START_DATE

  override ngOnInit(): void {
    this.date.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onDateChange);
    this.updateData()
  }

  private onDateChange = (): void => {
    this.updateData();
  };


  public chartOptions: ChartConfiguration['options'] = {
    elements: {
      line: { tension: 0.5 }
    },
    plugins: {
      legend: { position: 'bottom' },
      datalabels: { display: false }
    }
  };
  public chartData: ChartData<'line', number[], string | string[]> = null;

  override doApiRequest() {
    return this.statisticsApi
      .peoplePerHour2({
        body: this.getRequestBody()
      })
  }

  getRequestBody(): PeopleCounterDailyRequest {
    const day = this.date.value
    const date = day.startOf('day');

    return {
      date: date.toISODate(),
      screensIds: this.filter?.screens?.map(s => s.id),
    }
  }

  setChartData(statistics?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return

    const hoursWithData = statistics.screenHours.map((stat) => Number(DateTime.fromISO(stat.time).hour))
    const startHour = Math.min(...hoursWithData)
    const endHour = Math.max(...hoursWithData)

    const statHours = []
    for (let i = startHour; i <= endHour; i++) statHours.push(i)

    const genderGrouping = [
      { label: 'Kvinna', targetGroups: [TargetGroupEnum.OldWoman, TargetGroupEnum.YoungWoman] },
      { label: 'Man', targetGroups: [TargetGroupEnum.OldMan, TargetGroupEnum.YoungMan] },
    ]

    this.chartData = {
      datasets: genderGrouping.map((group) => ({
        fill: 'origin',

        data: statHours.reduce((acc, hour) => {
          const statForHour = statistics.screenHours.find((sd) => DateTime.fromISO(sd.time).hour === hour)
          if (!statForHour) return [...acc, 0]

          const sumForGroup = statForHour.peoples
            .filter(p => group.targetGroups.includes(p.targetGroup))
            .reduce((sum, stat) => sum + stat.count, 0)

          return [...acc, sumForGroup]
        }, []),
        label: group.label
      })),
      labels: statHours.map(h => `${h}-${h + 1}`)
    };
    this.chart.update();
  }

}

