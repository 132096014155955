import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'flow-form-field-group',
  templateUrl: './form-field-group.component.html',
  styleUrls: ['./form-field-group.component.scss']
})
export class FormFieldGroupComponent implements OnInit {

  @Input() label: string;
  @Input() bordered = false;

  constructor() { }

  ngOnInit(): void {
  }

}
