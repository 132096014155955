/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PeopleCounterDailyRequest } from '../models/people-counter-daily-request';
import { PeopleCounterRequest } from '../models/people-counter-request';
import { PeopleCounterResponse } from '../models/people-counter-response';
import { StatisticsAdvertisement } from '../models/statistics-advertisement';
import { StatisticsAdvertisementRequest } from '../models/statistics-advertisement-request';
import { StatisticsAdvertisementResponse } from '../models/statistics-advertisement-response';
import { StatisticsFilteredRequest } from '../models/statistics-filtered-request';
import { StatisticsResponse } from '../models/statistics-response';
import { StatisticsResponse2 } from '../models/statistics-response-2';
import { StatisticsSummaryRequest } from '../models/statistics-summary-request';
import { StatisticsSummaryResponse } from '../models/statistics-summary-response';

@Injectable({
  providedIn: 'root',
})
export class StatisticsApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation filteredStatistics
   */
  static readonly FilteredStatisticsPath = '/statistics/filtered';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filtered()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered$Response(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StrictHttpResponse<StatisticsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.FilteredStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filtered$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StatisticsResponse> {

    return this.filtered$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsResponse>) => r.body as StatisticsResponse)
    );
  }

  /**
   * Path part for operation filtered2TotalStatistics
   */
  static readonly Filtered2TotalStatisticsPath = '/statistics/filtered/totalcount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filtered2Total()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Total$Response(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StrictHttpResponse<StatisticsResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.Filtered2TotalStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsResponse2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filtered2Total$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Total(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StatisticsResponse2> {

    return this.filtered2Total$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsResponse2>) => r.body as StatisticsResponse2)
    );
  }

  /**
   * Path part for operation filtered2CampaignStatistics
   */
  static readonly Filtered2CampaignStatisticsPath = '/statistics/filtered/campaign';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filtered2Campaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Campaign$Response(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StrictHttpResponse<StatisticsResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.Filtered2CampaignStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsResponse2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filtered2Campaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Campaign(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StatisticsResponse2> {

    return this.filtered2Campaign$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsResponse2>) => r.body as StatisticsResponse2)
    );
  }

  /**
   * Path part for operation filtered2ScreenStatistics
   */
  static readonly Filtered2ScreenStatisticsPath = '/statistics/filtered/screen';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filtered2Screen()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Screen$Response(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StrictHttpResponse<StatisticsResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.Filtered2ScreenStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsResponse2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filtered2Screen$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2Screen(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StatisticsResponse2> {

    return this.filtered2Screen$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsResponse2>) => r.body as StatisticsResponse2)
    );
  }

  /**
   * Path part for operation filtered2TargetGroupStatistics
   */
  static readonly Filtered2TargetGroupStatisticsPath = '/statistics/filtered/targetgroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filtered2TargetGroup()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2TargetGroup$Response(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StrictHttpResponse<StatisticsResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.Filtered2TargetGroupStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsResponse2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `filtered2TargetGroup$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  filtered2TargetGroup(params?: {
    body?: StatisticsFilteredRequest
  }): Observable<StatisticsResponse2> {

    return this.filtered2TargetGroup$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsResponse2>) => r.body as StatisticsResponse2)
    );
  }

  /**
   * Path part for operation getAdvertisementStatisticsStatistics
   */
  static readonly GetAdvertisementStatisticsStatisticsPath = '/statistics/advertisements/{advertisementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisementStatistics()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAdvertisementStatistics$Response(params: {
    advertisementId: number;
    body?: StatisticsAdvertisementRequest
  }): Observable<StrictHttpResponse<StatisticsAdvertisementResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.GetAdvertisementStatisticsStatisticsPath, 'post');
    if (params) {
      rb.path('advertisementId', params.advertisementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsAdvertisementResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisementStatistics$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getAdvertisementStatistics(params: {
    advertisementId: number;
    body?: StatisticsAdvertisementRequest
  }): Observable<StatisticsAdvertisementResponse> {

    return this.getAdvertisementStatistics$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsAdvertisementResponse>) => r.body as StatisticsAdvertisementResponse)
    );
  }

  /**
   * Path part for operation getAdvertisementsStatistics
   */
  static readonly GetAdvertisementsStatisticsPath = '/statistics/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements$Response(params?: {
  }): Observable<StrictHttpResponse<Array<StatisticsAdvertisement>>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.GetAdvertisementsStatisticsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticsAdvertisement>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements(params?: {
  }): Observable<Array<StatisticsAdvertisement>> {

    return this.getAdvertisements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StatisticsAdvertisement>>) => r.body as Array<StatisticsAdvertisement>)
    );
  }

  /**
   * Path part for operation summaryStatistics
   */
  static readonly SummaryStatisticsPath = '/statistics/summary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `summary()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  summary$Response(params?: {
    body?: StatisticsSummaryRequest
  }): Observable<StrictHttpResponse<StatisticsSummaryResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.SummaryStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatisticsSummaryResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `summary$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  summary(params?: {
    body?: StatisticsSummaryRequest
  }): Observable<StatisticsSummaryResponse> {

    return this.summary$Response(params).pipe(
      map((r: StrictHttpResponse<StatisticsSummaryResponse>) => r.body as StatisticsSummaryResponse)
    );
  }

  /**
   * Path part for operation peoplePerDayStatistics
   */
  static readonly PeoplePerDayStatisticsPath = '/statistics/peoplecounter';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `peoplePerDay()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerDay$Response(params?: {
    body?: PeopleCounterRequest
  }): Observable<StrictHttpResponse<PeopleCounterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeoplePerDayStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PeopleCounterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `peoplePerDay$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerDay(params?: {
    body?: PeopleCounterRequest
  }): Observable<PeopleCounterResponse> {

    return this.peoplePerDay$Response(params).pipe(
      map((r: StrictHttpResponse<PeopleCounterResponse>) => r.body as PeopleCounterResponse)
    );
  }

  /**
   * Path part for operation peoplePerDay2Statistics
   */
  static readonly PeoplePerDay2StatisticsPath = '/statistics/peoplecounter2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `peoplePerDay2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerDay2$Response(params?: {
    body?: PeopleCounterRequest
  }): Observable<StrictHttpResponse<PeopleCounterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeoplePerDay2StatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PeopleCounterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `peoplePerDay2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerDay2(params?: {
    body?: PeopleCounterRequest
  }): Observable<PeopleCounterResponse> {

    return this.peoplePerDay2$Response(params).pipe(
      map((r: StrictHttpResponse<PeopleCounterResponse>) => r.body as PeopleCounterResponse)
    );
  }

  /**
   * Path part for operation peoplePerHourStatistics
   */
  static readonly PeoplePerHourStatisticsPath = '/statistics/peoplecounter/daily';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `peoplePerHour()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerHour$Response(params?: {
    body?: PeopleCounterDailyRequest
  }): Observable<StrictHttpResponse<PeopleCounterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeoplePerHourStatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PeopleCounterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `peoplePerHour$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerHour(params?: {
    body?: PeopleCounterDailyRequest
  }): Observable<PeopleCounterResponse> {

    return this.peoplePerHour$Response(params).pipe(
      map((r: StrictHttpResponse<PeopleCounterResponse>) => r.body as PeopleCounterResponse)
    );
  }

  /**
   * Path part for operation peoplePerHour2Statistics
   */
  static readonly PeoplePerHour2StatisticsPath = '/statistics/peoplecounter/daily2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `peoplePerHour2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerHour2$Response(params?: {
    body?: PeopleCounterDailyRequest
  }): Observable<StrictHttpResponse<PeopleCounterResponse>> {

    const rb = new RequestBuilder(this.rootUrl, StatisticsApi.PeoplePerHour2StatisticsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PeopleCounterResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `peoplePerHour2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  peoplePerHour2(params?: {
    body?: PeopleCounterDailyRequest
  }): Observable<PeopleCounterResponse> {

    return this.peoplePerHour2$Response(params).pipe(
      map((r: StrictHttpResponse<PeopleCounterResponse>) => r.body as PeopleCounterResponse)
    );
  }

}
