import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { Media, MediaService } from 'src/app/core/media.service';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, PersonOfInteresetApi, PersonOfInterest } from 'src/_api';

@Component({
  selector: 'flow-person-of-interest-create',
  templateUrl: './person-of-interest-create.component.html',
  styleUrls: ['./person-of-interest-create.component.scss']
})
export class PersonOfInterestCreateComponent extends unsubscribeMixin() implements OnInit {

  @ViewChild('fileInput') fileInput: ElementRef;

  personOfInterestId: number;
  personOfInterest: PersonOfInterestCreate;
  customers: Customer[];
  form: FormGroup<PersonOfInterestFormGroup>;
  selectedFile: any;
  filePreview: any;

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public dialog: MatDialog,
    private personOfInterestApi: PersonOfInteresetApi,
    private mediaService: MediaService,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.personOfInterestId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('personOfInterestId: ', this.personOfInterestId);
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.personOfInterestId ? this.personOfInterestApi.getPersonOfInterestById({ personOfInteresetId: this.personOfInterestId }) : of(null),
      this.personOfInterestId ? this.adminApi.getImageToken() : of(null),
      this.adminApi.getCustomers(),
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([personOfInterest, imageToken, customers]) => {
        this.customers = customers;
        this.personOfInterest = {
          ...personOfInterest,
          media: this.mediaService.getMedia(personOfInterest?.mediaFile, imageToken)
        };
        this.initForm(this.personOfInterest);
      });
  }

  selectFile(event: any): void {
    this.selectedFile = event.target.files && event.target.files[0];
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.filePreview = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }

  clearFile(): void {
    this.selectedFile = null;
    this.filePreview = null;
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.selectedFile) {
      this.adminApi.mediaUploadImage({ body: { file: this.selectedFile } })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.upload))
        .subscribe((media) => {
          this.form.get('mediaId').setValue(media.mediaId);
          this.savePersonOfInterest();
        });
    } else if (this.personOfInterest?.mediaFile?.mediaId) {
      this.savePersonOfInterest();
    } else {
      this.systemMessageService.error('Vänligen välj en bild till personen.');
    }
  }

  private savePersonOfInterest(): void {
    this.personOfInterestApi.createOrUpdatePersonOfInterest({ body: this.form.value })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`"Person of Interest" ${this.form.value.name} sparad!`);
        this.router.navigate(['/person-of-interest']);
      });
  }

  private initForm(personOfInterest?: PersonOfInterestCreate): void {
    this.form = this.formBuilder.group({
      id: this.personOfInterestId,
      name: [personOfInterest?.name, Validators.required],
      customerId: personOfInterest?.customerId,
      mediaId: personOfInterest?.mediaFile.mediaId
    });
    if (this.personOfInterestId && !personOfInterest) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

}

interface PersonOfInterestFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  customerId: FormControl<number>;
  mediaId: FormControl<number>;
}

interface PersonOfInterestCreate extends PersonOfInterest {
  media: Media;
}

