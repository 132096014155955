import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, Location } from 'src/_api';

@Component({
  selector: 'flow-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'customer', 'actions'];
  dataSource: MatTableDataSource<Location>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getLocations();
  }

  getLocations(): void {
    this.adminApi.getLocations()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((locationsResponse) => {
        this.adminApi.getCustomers()
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((customers) => {
            const locations = locationsResponse?.map(loc => {
              const listItem = loc as LocationListItem;
              listItem.customer = customers?.find(c => c.id === loc.customerId);
              return listItem;
            })
            this.dataSource = new MatTableDataSource(locations);
            this.dataSource.sort = this.sort;
          });

      });
  }

  onDeleteClick(event: Event, location: Location): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeLocation({ locationId: location.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getLocations();
      });
  }

}

interface LocationListItem extends Location {
  customer?: Customer;
}
