import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DateTime } from 'luxon';
import { debounceTime, distinctUntilChanged, forkJoin, Subscription, takeUntil } from 'rxjs';
import { Media, MediaService } from 'src/app/core/media.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { FilesizePipe } from 'src/app/shared/filesize-pipe/filesize.pipe';
import { ViewMediaModal } from 'src/app/shared/view-media-modal/view-media.modal';
import { AdminApi, MediaTypeEnum, Tag } from 'src/_api';

@Component({
  selector: 'flow-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent extends unsubscribeMixin() implements OnInit, AfterViewInit, OnDestroy {

  @Input() isModal: boolean;
  @Output() mediaSelected = new EventEmitter<Media>();
  @Output() cancel = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['id', 'thumbnail', 'name', 'type', 'tags', 'filename', 'filesize', 'uploaded'];
  dataSource: MatTableDataSource<Media>;
  paginator: MatPaginator = new MatPaginator(new MatPaginatorIntl(), this.cdr);
  readonly pageSize = 50;

  MediaTypeEnum = MediaTypeEnum;
  panelOpenState = true;
  filterForm: FormGroup<MediaFilterFormGroup>;
  filterValues: MediaFilterValues;
  tags: Tag[];
  filterRequest: Subscription;

  constructor(
    private adminApi: AdminApi,
    private mediaService: MediaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private filesizePipe: FilesizePipe,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.initFilter();
  }

  ngOnInit(): void {
    if (!this.isModal) {
      this.displayedColumns.push('actions');
    }
  }

  ngAfterViewInit(): void {
    this.adminApi.getTags()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((tags) => {
        this.tags = tags;
      });
    this.getMedias();
  }

  override ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.filterRequest?.unsubscribe();
  }

  openViewMediaModal(media: Media, $event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.dialog.open(ViewMediaModal, {
      data: {
        title: media.name + ' - ' + media.filename,
        media: media
      }
    });
  }

  onDeleteClick(event: Event, media: Media): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeMedia({ id: media.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getMedias();
      });
  }

  onMediaSelected(media: Media): void {
    this.mediaSelected.emit(media);
  }

  onCancelClick(): void {
    this.cancel.emit();
  }

  onClearFilterClick(): void {
    this.filterForm.reset({
      search: null,
      tags: null,
      created: { start: null, end: null }
    });
  }

  onLoadMore(): void {
    const newSize = this.paginator.pageSize + this.pageSize;
    this.paginator._changePageSize(newSize);
  }

  showMoreVisible = (index: number): boolean => {
    return this.paginator.hasNextPage() && index === this.paginator.pageSize - 1;
  }

  mediaTypeLabel = (type: MediaTypeEnum): string => {
    return type === MediaTypeEnum.Image ? 'Bild' :
      type === MediaTypeEnum.Video ? 'Video' : '';
  }

  resolutionLabel = (media: Media): string => {
    return media && media.width + ' x ' + media.height;
  }

  isFilterEmpty = (): boolean => {
    return !this.filterValues?.search && !this.filterValues?.tags?.length &&
      !this.filterValues?.created?.start && !this.filterValues?.created?.end;
  }

  private getMedias(): void {
    this.filterRequest?.unsubscribe();
    this.filterRequest = forkJoin([
      this.adminApi.getMedias(),
      this.adminApi.getImageToken()
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([mediasResponse, imageToken]) => {
        const medias = mediasResponse.map(media => {
          const listItem = this.mediaService.getMedia(media, imageToken);
          return listItem;
        }) as Media[];

        this.paginator.pageSize = this.pageSize;
        if (!this.dataSource) {
          this.paginator.ngOnInit();
        }
        this.dataSource = new MatTableDataSource(medias);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filterPredicate = (item: Media) => {
          return this.filterSearchInput(item) && this.filterTags(item) && this.filterDate(item);
        };
      });
  }

  private onFilterChanged = (values: MediaFilterValues): void => {
    this.filterValues = values;
    this.dataSource.filter = <any>values;
  }

  private initFilter(): void {
    this.filterForm = this.formBuilder.group({
      search: this.formBuilder.control(null),
      tags: this.formBuilder.control(null),
      created: this.formBuilder.group({
        start: this.formBuilder.control(null),
        end: this.formBuilder.control(null)
      })
    });
    this.filterForm.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(50),
      distinctUntilChanged(),
    ).subscribe(this.onFilterChanged);
  }

  private filterSearchInput(item: Media): boolean {
    if (!this.filterValues?.search) {
      return true;
    }
    const itemString = [
      item.id, item.name, this.mediaTypeLabel(item.type), item.filename,
      this.resolutionLabel(item), this.filesizePipe.transform(item.size), item.createdString
    ].concat(item.tags?.map(t => t.name)).join(' ');
    return itemString.toLowerCase().indexOf(this.filterValues.search.toLowerCase()) >= 0;
  }

  private filterTags(item: Media): boolean {
    if (!this.filterValues?.tags?.length) {
      return true;
    }
    return item.tags?.some(itemTag => this.filterValues.tags.some(tag => tag.id === itemTag.id));
  }

  private filterDate(item: Media): boolean {
    if (!this.filterValues?.created?.start && !this.filterValues?.created?.end) {
      return true;
    }
    if (!item.createdDT) {
      return false;
    }
    const dt = item.createdDT;
    const start = this.filterValues.created.start || DateTime.fromObject({ year: 0, month: 1, day: 1 });
    const end = this.filterValues.created.end || DateTime.fromObject({ year: 9999, month: 12, day: 31 });
    return dt >= start && dt <= end;
  }
}

interface MediaFilterValues {
  search?: string;
  tags?: Tag[];
  created?: {
    start?: DateTime;
    end?: DateTime;
  }
}

interface MediaFilterFormGroup {
  search: FormControl<string>;
  tags: FormControl<Tag[]>;
  created: FormGroup<{
    start: FormControl<DateTime>,
    end: FormControl<DateTime>
  }>;
}
