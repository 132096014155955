import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { EnumService } from 'src/app/core/enum.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, CustomerPerson, CustomerTypeEnum, InvoiceDeliveryTypeEnum } from 'src/_api';
import { CustomerPersonCreateModal } from '../person-create/customer-person-create.modal';
import { COUNTRIES } from './country-codes';

@Component({
  selector: 'flow-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent extends unsubscribeMixin() implements OnInit {

  customerId: number;
  customer: Customer;
  customerPersons: CustomerPerson[] = [];
  form: FormGroup<CustomerFormGroup>;
  personsControl: FormControl<CustomerPerson[]>;
  countries = COUNTRIES;

  InvoiceDeliveryTypeEnum = InvoiceDeliveryTypeEnum;

  loading$ = {
    customer: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public enumService: EnumService,
    public dialog: MatDialog,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.customerId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('customerId: ', this.customerId);
    this.initForm();
  }

  ngOnInit(): void {
    if (this.customerId) {
      this.adminApi.getCustomerById({ id: this.customerId })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.customer))
        .subscribe((customer) => {
          this.customer = customer as Customer;
          this.customerPersons = customer.persons || [];
          this.initForm(this.customer);
        });
    }
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    const body = this.form.value;
    this.adminApi.saveCustomer({ body })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe((customer) => {
        this.systemMessageService.success(`Kund ${this.form.value.name} sparad!`);
        this.router.navigate(['/customers']);
      });
  }

  customerPersonLabel = (person: CustomerPerson): string => person?.name + ' - ' + person?.phone;

  onCreateCustomerPerson(customerPerson?: CustomerPerson, index?: number, event?: any): void {
    event?.preventDefault();
    event?.stopPropagation();
    const dialogRef = this.dialog.open(CustomerPersonCreateModal, {
      data: { customerPerson },
      width: '500px'
    });

    dialogRef.afterClosed().subscribe((person: CustomerPerson) => {
      if (!person) {
        return;
      }
      if (customerPerson) {
        customerPerson.id = person.id;
        customerPerson.name = person.name;
        customerPerson.email = person.email;
        customerPerson.phone = person.phone;
        customerPerson.type = person.type;
        customerPerson.personOfInterestNotification = person.personOfInterestNotification;
        console.log(this.personsControl.value);
      } else {
        this.customerPersons.push(person);
        const selectedPersons = this.personsControl.value;
        selectedPersons.push(person);
        this.personsControl.setValue(selectedPersons);
      }
    });
  }

  private initForm(customer?: Customer): void {
    this.personsControl = this.formBuilder.control(customer?.persons || []);
    this.form = this.formBuilder.group({
      id: this.customerId,
      name: [customer?.name, Validators.required],
      organizationId: customer?.organizationId,
      type: [customer?.type, Validators.required],
      persons: this.personsControl,
      address: customer?.address,
      postalCode: customer?.postalCode,
      postalCity: customer?.postalCity,
      countryExternalId: customer?.countryExternalId || COUNTRIES.find(c => c.code === 'SE').code, // Sverige förvalt
      invoiceDeliveryType: customer?.invoiceDeliveryType || InvoiceDeliveryTypeEnum.Email as InvoiceDeliveryTypeEnum,
      invoiceDeliveryEmail: customer?.invoiceDeliveryEmail,
      referenceName: customer?.referenceName
    });
    if (this.customerId && !customer) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}

interface CustomerFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  organizationId: FormControl<string>;
  type: FormControl<CustomerTypeEnum>;
  persons: FormControl<CustomerPerson[]>;
  address: FormControl<string>;
  postalCode: FormControl<string>;
  postalCity: FormControl<string>;
  countryExternalId: FormControl<string>;
  invoiceDeliveryType: FormControl<InvoiceDeliveryTypeEnum>;
  invoiceDeliveryEmail: FormControl<string>;
  referenceName: FormControl<string>;
}
