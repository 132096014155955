import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { AppService } from './app.service';
import { StyleManager } from './core/style-manager.service';
import { SystemMessageService } from './core/system-message.service';

@Component({
  selector: 'flow-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  @ViewChild('navDrawer', { static: true }) navDrawer: MatDrawer;

  routesWithoutDashboard = ['/login', /* '/resetPassword', '/signup' */];

  private _modeOverQueryListener: (event) => void;

  constructor(
    private styleManager: StyleManager,
    private router: Router,
    private systemMessageService: SystemMessageService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public appService: AppService
  ) {
    // intercept routing
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    // setup media queries
    this.appService.modeOverQuery = media.matchMedia('(max-width: 1200px)');
    this._modeOverQueryListener = (event: MediaQueryListEvent) => {
      this.appService.navClosed = event.matches;
      changeDetectorRef.detectChanges();
    };
    this.appService.modeOverQuery.addEventListener('change', this._modeOverQueryListener);
    this.appService.isDark = this.styleManager.isDark;
  }

  ngOnInit(): void {
    this.appService.navClosed = this.appService.modeOverQuery.matches;
  }

  ngOnDestroy(): void {
    this.appService.modeOverQuery.removeEventListener('change', this._modeOverQueryListener);
  }

  toggleSidenav() {
    this.appService.navClosed = !this.appService.navClosed;
  }

  toggleDarkTheme() {
    this.styleManager.toggleDarkTheme();
    this.appService.isDark = !this.appService.isDark;
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    const hasToolbarFormActions = this.appService.hasToolbarFormActions;
    if (event instanceof NavigationStart) {
      this.appService.hasToolbarFormActions = false;
      this.appService.navHidden = this.routesWithoutDashboard.includes(event.url);
    }
    if (event instanceof NavigationEnd) {
      // on navigation end
    }
    if (event instanceof NavigationCancel) {
      // on navigation cancel
      this.appService.hasToolbarFormActions = hasToolbarFormActions;
    }
    if (event instanceof NavigationError) {
      // on navigation error
      this.appService.hasToolbarFormActions = hasToolbarFormActions;
    }
  }
}
