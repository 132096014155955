import { Injectable } from "@angular/core";
import { Campaign, Screen, StatisticsPerDay, TargetGroup } from "src/_api";
import { DateTime } from 'luxon';
import { StatisticsAdvertisement } from "src/_api/models/statistics-advertisement";

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {

  constructor() { }

  groupDataByCampaign(perDay: StatisticsPerDay[]): {
    campaignId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('campaignId', perDay);
  }

  groupDataByScreen(perDay: StatisticsPerDay[]): {
    screenId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('screenId', perDay);
  }

  groupDataByTargetGroup(perDay: StatisticsPerDay[]): {
    targetGroupId: number;
    perDay: {
      date: string,
      count: number
    }[];
  }[] {
    return this.groupDataBy('targetGroupId', perDay);
  }

  groupDataBy(property: string, perDay: StatisticsPerDay[]) {
    const groupedByProperty: any[] = [];
    perDay.forEach(pd => {
      pd.adSlots.forEach(slot => {
        const group = groupedByProperty.find(g => g[property] === slot[property]);
        if (!group) {
          groupedByProperty.push({
            [property]: slot[property],
            perDay: [{
              date: pd.date,
              count: 1
            }]
          });
        } else {
          const perDay = group.perDay.find(day => day.date === pd.date);
          if (!perDay) {
            group.perDay.push({
              date: pd.date,
              count: 1
            });
          } else {
            perDay.count++;
          }
        }
      });
    });
    return groupedByProperty;
  }

  buildPeriod(start: DateTime, end: DateTime, columnType: 'day' | 'date' = 'day'): ChartWeekday[] {
    let current = start;
    const days: ChartWeekday[] = [];
    while (current < end) {
      days.push({
        dateTime: current,
        utcString: current.toISO(),
        label: columnType === 'day' ? current.weekdayShort : current.day.toString()
      });
      current = current.plus({ day: 1 });
    }
    return days;
  }
}

export interface StatisticsInitData {
  campaigns?: Campaign[];
  screens: Screen[];
  targetGroups?: TargetGroup[];
  advertisements?: StatisticsAdvertisement[]
}

export interface ChartWeekday {
  dateTime: DateTime;
  utcString: string;
  label: string;
}
