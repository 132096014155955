import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, CustomerPerson, CustomerPersonTypeEnum } from 'src/_api';


@Component({
  selector: 'flow-customer-person-create',
  templateUrl: './customer-person-create.modal.html',
  styleUrls: ['./customer-person-create.modal.scss']
})
export class CustomerPersonCreateModal extends unsubscribeMixin() implements OnInit {

  form: FormGroup<CustomerPersonFormGroup>;
  loading$ = {
    save: new BehaviorSubject(false)
  }

  constructor(
    public dialogRef: MatDialogRef<CustomerPersonCreateModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private formBuilder: FormBuilder,
    private adminApi: AdminApi,
    private systemMessageService: SystemMessageService
  ) {
    super();
  }

  ngOnInit(): void {
    console.log(this.data);
    this.initForm(this.data?.customerPerson);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value);
  }

  private initForm(person?: CustomerPerson): void {
    this.form = this.formBuilder.group({
      id: person?.id,
      name: person?.name,
      email: person?.email,
      phone: person?.phone,
      personOfInterestNotification: person?.personOfInterestNotification,
      type: CustomerPersonTypeEnum.ContactPerson as CustomerPersonTypeEnum
    });
  }
}

interface CustomerPersonFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  email: FormControl<string>;
  phone: FormControl<string>;
  personOfInterestNotification: FormControl<boolean>;
  type: FormControl<CustomerPersonTypeEnum>;
}

interface ModalData {
  customerPerson?: CustomerPerson;
}
