import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { forkJoin, takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, CampaignApi } from 'src/_api';
import { CampaignCalendarSlot } from '../calendar/calendar-models';

@Component({
  selector: 'flow-campaign-overview',
  templateUrl: './campaign-overview.component.html',
  styleUrls: ['./campaign-overview.component.scss']
})
export class CampaignOverviewComponent extends unsubscribeMixin() implements OnInit {

  campaignSlots: CampaignCalendarSlot[];

  constructor(
    private campaignApi: CampaignApi,
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void {
    this.getCampaigns();
  }

  getCampaigns(): void {
    forkJoin([
      this.campaignApi.getCampaigns(),
      this.adminApi.getScreens(),
      this.adminApi.getLocations()
    ]).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([campaigns, screens, locations]) => {
        this.campaignSlots = campaigns.flatMap(campaign => campaign.slots?.map(slot => {
          const screen = screens?.find(screen => screen.id === slot.screenId);
          const location = locations?.find(location => location.id === screen?.locationId);
          return {
            start: slot.startDate && slot.startDate !== '0001-01-01T00:00:00.000Z' ?
              DateTime.fromISO(slot.startDate).toUTC() : null,
            end: slot.endDate && slot.endDate !== '0001-01-01T00:00:00.000Z' ?
              DateTime.fromISO(slot.endDate).toUTC().minus({ millisecond: 1 }) : null,
            screen,
            location,
            campaign
          };
        }));
      });
  }
}
