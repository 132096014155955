import { Injectable } from "@angular/core";
import { CampaignTypeEnum, CharacteristicTypeEnum, TargetGroupEnum, WeekDayEnum } from "src/_api";
import { CustomerTypeEnum } from "src/_api/models/customer-type-enum";
import { InvoiceDeliveryTypeEnum } from "src/_api/models/invoice-delivery-type-enum";
import { CampaignTypeTranslations } from "../admin/campaign/campaign-type-translations";
import { WeekdayTranslations } from "../admin/campaign/edit/campaign-edit-models";
import { CharacteristicTypeTranslations } from "../admin/characteristic/characteristic-type-translations";
import { CustomerTypeTranslations } from "../admin/customer/customer-type-translations";
import { InvoiceDeliveryTypeTranslations } from "../admin/customer/invoice-delivery-type-translations";
import { ScreenOrientationEnum, ScreenOrientationTranslations } from "../admin/screen/screen-orientation";
import { TargetGroupEnumTranslations } from "../admin/target-group/target-group-enum-translations";
import { UserRoleEnum } from "./auth/auth.service";
import { UserRoleTranslations } from "./auth/user-role-translations";

@Injectable({
  providedIn: 'root'
})
export class EnumService {

  userRoles = this.createEnumObjects(UserRoleTranslations) as EnumObject<UserRoleEnum>[];
  characteristicTypes = this.createEnumObjects(CharacteristicTypeTranslations) as EnumObject<CharacteristicTypeEnum>[];
  campaignTypes = this.createEnumObjects(CampaignTypeTranslations) as EnumObject<CampaignTypeEnum>[];
  invoiceDeliveryTypes = this.createEnumObjects(InvoiceDeliveryTypeTranslations) as EnumObject<InvoiceDeliveryTypeEnum>[];
  customerTypes = this.createEnumObjects(CustomerTypeTranslations) as EnumObject<CustomerTypeEnum>[];
  targetGroups = this.createEnumObjects(TargetGroupEnumTranslations) as EnumObject<TargetGroupEnum>[];
  screenOrientations = this.createEnumObjects(ScreenOrientationTranslations) as EnumObject<ScreenOrientationEnum>[];
  weekDays = this.createEnumObjects(WeekdayTranslations) as EnumObject<WeekDayEnum>[];

  constructor() { }

  private createEnumObjects(enumTranslations: { [key: number]: string }): EnumObject<number>[] {
    const types = Object.keys(enumTranslations).filter(key => +key > 0)
      .map(key => {
        const value = +key;
        return new EnumObject(value, enumTranslations[value]);
      });
    return types;
  }
}

export class EnumObject<T> {
  constructor(readonly value: T, readonly name: string) { }
}
