<div class="flex flex-col sm:flex-row mt-4">
  <week-picker class="flex-1 sm:mr-1 "
               [range]="range"
               (weekSelected)="onWeekSelected()"></week-picker>
  <week-picker class="flex-1 sm:ml-1"
               [range]="rangeCompare"
               [disabled]="!range.value.start"
               label="Vecka att jämföra"
               (weekSelected)="onWeekCompareSelected()"></week-picker>
</div>
<canvas baseChart
        [data]="chartData"
        [type]="'bar'"
        [options]="chartOptions"
        [plugins]="chartPlugins">
</canvas>