import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loaderState: Observable<any>;
  loadTasksCount = 0;
  private loaderSubject = new Subject();
  requestDelay = 0;

  constructor() {
    this.loaderState = this.loaderSubject.asObservable();
  }

  show() {
    this.loadTasksCount++;
    this.loaderSubject.next({ show: true });
  }

  hide() {
    this.loadTasksCount--;
    if (this.loadTasksCount <= 0) {
      this.loadTasksCount = 0;
      this.loaderSubject.next({ show: false });
    }
  }
}
