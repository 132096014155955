import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EnumService } from 'src/app/core/enum.service';
import { WeekDayEnum } from 'src/_api';
import { CampaignEditAdvertisement, CampaignEditAdvertisementFormGroup, CampaignEditTime, CampaignEditTimesModalResult } from './campaign-edit-models';


@Component({
  selector: 'flow-campaign-edit-times-modal',
  templateUrl: './campaign-edit-times.modal.html',
  styleUrls: ['./campaign-edit-times.modal.scss']
})
export class CampaignEditTimesModal implements OnInit {

  form: FormGroup<CampaignEditTimesFormGroup>;

  constructor(
    public dialogRef: MatDialogRef<CampaignEditTimesModal>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    public enumService: EnumService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm(this.data.advGroup.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDone(): void {
    const values = this.form.value;
    const result: CampaignEditTimesModalResult = {
      weekDays: this.mapToWeekDaysList(values.weekDays),
      startTime: this.mapToTimeObject(values.startTime),
      endTime: this.mapToTimeObject(values.endTime)
    };
    this.dialogRef.close(result);
  }

  private initForm(advertisement: CampaignEditAdvertisement) {
    this.form = this.formBuilder.group({
      startTime: this.mapToTimeString(advertisement.startTime),
      endTime: this.mapToTimeString(advertisement.endTime),
      weekDays: this.formBuilder.group({
        [WeekDayEnum.Monday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Monday),
        [WeekDayEnum.Tuesday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Tuesday),
        [WeekDayEnum.Wednesday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Wednesday),
        [WeekDayEnum.Thursday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Thursday),
        [WeekDayEnum.Friday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Friday),
        [WeekDayEnum.Saturday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Saturday),
        [WeekDayEnum.Sunday]: !advertisement.weekDays?.length || advertisement.weekDays.some(wd => wd === WeekDayEnum.Sunday),
      })
    });
  }

  private mapToTimeString(timeObj: CampaignEditTime): string {
    if (timeObj?.hours == null || timeObj.minutes == null) {
      return '';
    }
    return `${this.zeroPad(timeObj.hours)}:${this.zeroPad(timeObj.minutes)}`;
  }

  private mapToWeekDaysList(weekDaysObj: { [key: number]: boolean }): WeekDayEnum[] {
    const weekDaysList = Object.keys(weekDaysObj).filter(weekDay => !!weekDaysObj[weekDay]).map(weekDay => +weekDay);
    if (weekDaysList.length === 7) { //alla dagar valda, default till null
      return null;
    }
    return weekDaysList;
  }

  private mapToTimeObject(timeString: string): CampaignEditTime {
    if (!timeString) {
      return null;
    }
    const [hours, minutes] = timeString.split(':');
    if (!hours || !minutes) {
      return null;
    }
    return { hours: +hours, minutes: +minutes };
  }

  private zeroPad(num: number): string {
    return num.toString().padStart(2, '0');
  }
}

interface ModalData {
  advGroup?: FormGroup<CampaignEditAdvertisementFormGroup>;
}

interface CampaignEditTimesFormGroup {
  startTime: FormControl<string>;
  endTime: FormControl<string>;
  weekDays: FormGroup<WeekDaysFormGroup>;
}

interface WeekDaysFormGroup {
  [WeekDayEnum.Monday]: FormControl<boolean>;
  [WeekDayEnum.Tuesday]: FormControl<boolean>;
  [WeekDayEnum.Wednesday]: FormControl<boolean>;
  [WeekDayEnum.Thursday]: FormControl<boolean>;
  [WeekDayEnum.Friday]: FormControl<boolean>;
  [WeekDayEnum.Saturday]: FormControl<boolean>;
  [WeekDayEnum.Sunday]: FormControl<boolean>;
}
