import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { BehaviorSubject, forkJoin, interval, startWith, takeUntil } from 'rxjs';
import { STATISTICS_EXPOSURES_POLLING_INTERVAL } from 'src/app/constants';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Advertisement, StatisticsAdvertisementResponse, StatisticsApi } from 'src/_api';
import { StatisticsFilterValues } from '../statistics-filter.component';
import { StatisticsInitData } from '../statistics.service';

@Component({
  selector: 'flow-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss']
})
export class ViewsComponent extends unsubscribeMixin() implements OnInit {

  summaryPeriods: { startDT: DateTime, endDT: DateTime, title: string }[] = [];
  chartPeriods: { startDT: DateTime, endDT: DateTime }[] = [];
  filter: StatisticsFilterValues;
  initData: StatisticsInitData;
  selectedAdvertisement?: Advertisement;
  loading$ = new BehaviorSubject(true);
  currentMonthStatistics: { statistics: StatisticsAdvertisementResponse, advertisementId: number }

  SummaryCards = SummaryCards;

  constructor(
    private adminApi: AdminApi,
    private statisticsApi: StatisticsApi
  ) {
    super();
  }

  ngOnInit(): void {
    const now = DateTime.utc().startOf('day');
    this.setSummaryPeriods(now);
    forkJoin([
      this.adminApi.getScreens(),
      this.statisticsApi.getAdvertisements()
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([screens, advertisements]) => {
        this.initData = { screens, advertisements };
      });
    interval(STATISTICS_EXPOSURES_POLLING_INTERVAL)
      .pipe(startWith(0), takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getCurrentMonthData();
      });
  }

  onAdvertisementChange(newSelection: Advertisement) {
    this.getCurrentMonthData();
  }

  onFilterChange(filter: StatisticsFilterValues): void {
    this.filter = filter;
  }

  private getCurrentMonthData(): void {
    if (!this.selectedAdvertisement?.id) {
      return
    }
    const now = DateTime.utc().startOf('day');
    this.statisticsApi
      .getAdvertisementStatistics({
        advertisementId: this.selectedAdvertisement.id,
        body: {
          startTime: now.startOf('month').toISO(),
          endTime: now.endOf('month').toISO(),
        }
      })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$))
      .subscribe((response) => {
        this.currentMonthStatistics = {
          statistics: response,
          advertisementId: this.selectedAdvertisement.id
        };
      });
  }

  private setSummaryPeriods(now: DateTime): void {
    this.summaryPeriods[SummaryCards.Today] = {
      startDT: now.startOf('day'),
      endDT: now.endOf('day'),
      title: 'Idag'
    };
    this.summaryPeriods[SummaryCards.Yesterday] = {
      startDT: now.minus({ day: 1 }).startOf('day'),
      endDT: now.minus({ day: 1 }).endOf('day'),
      title: 'Igår'
    };
    this.summaryPeriods[SummaryCards.ThisWeek] = {
      startDT: now.startOf('week'),
      endDT: now.endOf('week'),
      title: 'Nuvarande vecka'
    };
    this.summaryPeriods[SummaryCards.ThisMonth] = {
      startDT: now.startOf('month'),
      endDT: now.endOf('month'),
      title: 'Nuvarande månad'
    };
    console.log(this.summaryPeriods);
  }

}

enum SummaryCards {
  Today,
  Yesterday,
  ThisWeek,
  ThisMonth
}
