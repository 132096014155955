<mat-form-field class="w-full"
                (click)="!disabled && picker.open()">
  <mat-label>{{label}}</mat-label>
  <mat-date-range-input [rangePicker]="picker"
                        [formGroup]="range"
                        [max]="maxDate"
                        [disabled]="disabled"
                        [min]="minDate">
    <input matStartDate
           #dateRangeStart
           formControlName="start"
           readonly
           placeholder="Start date">
    <input matEndDate
           #dateRangeEnd
           formControlName="end"
           readonly
           placeholder="End date"
           (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix
                         [disabled]="disabled"
                         [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
  <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Ogiltigt startdatum</mat-error>
  <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Ogiltigt slutdatum</mat-error>
</mat-form-field>