import { TargetGroupEnum } from 'src/_api/models/target-group-enum';
const { YoungMan, YoungWoman, OldMan, OldWoman } = TargetGroupEnum

export const peopleTargetGroups = [
  // { label: 'Oidentifierad', value: NotSet },
  { label: 'Unga män', value: YoungMan },
  { label: 'Äldre män', value: OldMan },
  { label: 'Unga kvinnor', value: YoungWoman },
  { label: 'Äldre kvinnor', value: OldWoman }
]
