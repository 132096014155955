<div class="page-header">
  <h1 class="page-title"
      *ngIf="!personOfInterestId">Skapa Person of interest</h1>
  <h1 class="page-title"
      *ngIf="personOfInterestId">Redigera Person of interest</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <div class="grid lg:grid-cols-2 gap-4">
      <mat-form-field class="w-full">
        <mat-label>Namn / beskrivning</mat-label>
        <input matInput
               type="text"
               formControlName="name">
      </mat-form-field>
      <mat-form-field class="w-full">
        <mat-label>Kund</mat-label>
        <mat-select formControlName="customerId">
          <mat-option *ngFor="let customer of customers"
                      [value]="customer.id">
            {{customer.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-form-field class="upload-field">
      <div>
        <mat-toolbar>
          <input matInput
                 [value]="selectedFile ? selectedFile.name : personOfInterest?.media?.url ? 'Ändra bild' : 'Välj bild'" />
          <button *ngIf="selectedFile"
                  mat-icon-button
                  color="warn"
                  (click)="clearFile()">
            <mat-icon>clear</mat-icon>
          </button>

          <input type="file"
                 id="fileInput"
                 name="fileInput"
                 accept="image/*"
                 [ngClass]="{ 'clear-visible' : !!selectedFile }"
                 (change)="selectFile($event)" />
        </mat-toolbar>
      </div>
    </mat-form-field>
    <div class="preview">
      <img *ngIf="filePreview"
           [src]="filePreview">
      <img *ngIf="!filePreview && personOfInterest?.media?.url"
           [src]="personOfInterest.media.url">
    </div>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/personOfInterests']">
        Avbryt
      </button>
      <button [disabled]="(loading$.save | async) || (loading$.upload | async)"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
