import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CampaignBookComponent } from './admin/campaign/book/campaign-book.component';
import { CampaignComponent } from './admin/campaign/campaign.component';
import { CampaignEditComponent } from './admin/campaign/edit/campaign-edit.component';
import { CampaignOverviewComponent } from './admin/campaign/overview/campaign-overview.component';
import { CharacteristicComponent } from './admin/characteristic/characteristic.component';
import { CharacteristicCreateComponent } from './admin/characteristic/create/characteristic-create.component';
import { CustomerCreateComponent } from './admin/customer/create/customer-create.component';
import { CustomerComponent } from './admin/customer/customer.component';
import { LocationCreateComponent } from './admin/location/create/location-create.component';
import { LocationComponent } from './admin/location/location.component';
import { MediaCreateComponent } from './admin/media/create/media-create.component';
import { MediaComponent } from './admin/media/media.component';
import { PersonOfInterestCreateComponent } from './admin/person-of-interest/create/person-of-interest-create.component';
import { PersonOfInterestComponent } from './admin/person-of-interest/person-of-interest.component';
import { ScreenCreateComponent } from './admin/screen/create/screen-create.component';
import { ScreenComponent } from './admin/screen/screen.component';
import { StartComponent } from './admin/start/start.component';
import { PeopleCountComponent } from './admin/statistics/people-count/people-count.component';
import { ViewsComponent } from './admin/statistics/views/views.component';
import { TargetGroupCreateComponent } from './admin/target-group/create/target-group-create.component';
import { TargetGroupComponent } from './admin/target-group/target-group.component';
import { UserCreateComponent } from './admin/user/create/user-create.component';
import { UserComponent } from './admin/user/user.component';
import { AuthGuard } from './core/auth/auth-guard.service';
import { LoginComponent } from './core/auth/login.component';

const routes: Routes = [
  {
    path: '', component: StartComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'medias', component: MediaComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'medias/create', component: MediaCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'medias/:id', component: MediaCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'campaigns', component: CampaignComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'campaigns/book', component: CampaignBookComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'campaigns/overview', component: CampaignOverviewComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'campaigns/:id', component: CampaignEditComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'characteristics', component: CharacteristicComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'characteristics/create', component: CharacteristicCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'characteristics/:id', component: CharacteristicCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'customers', component: CustomerComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'customers/create', component: CustomerCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'customers/:id', component: CustomerCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'locations', component: LocationComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'locations/create', component: LocationCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'locations/:id', component: LocationCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'login', component: LoginComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'person-of-interest', component: PersonOfInterestComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'person-of-interest/create', component: PersonOfInterestCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'person-of-interest/:id', component: PersonOfInterestCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'screens', component: ScreenComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'screens/create', component: ScreenCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'screens/:id', component: ScreenCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'statistics/people-count', component: PeopleCountComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'statistics/views', component: ViewsComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'target-groups', component: TargetGroupComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'target-groups/create', component: TargetGroupCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'target-groups/:id', component: TargetGroupCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'users', component: UserComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'users/create', component: UserCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  {
    path: 'users/:id', component: UserCreateComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: { authorize: true }
  },
  { path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
