import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { Media, MediaService } from 'src/app/core/media.service';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, MediaFile, MediaTypeEnum, Tag } from 'src/_api';

@Component({
  selector: 'flow-media-create',
  templateUrl: './media-create.component.html',
  styleUrls: ['./media-create.component.scss']
})
export class MediaCreateComponent extends unsubscribeMixin() implements OnInit {

  @Input() isModal: boolean;
  @Output() mediaSaved = new EventEmitter<MediaFile>();
  @Output() cancel = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;

  mediaId: number;
  media: Media;
  tags: Tag[];
  form: FormGroup<MediaFormGroup>;
  selectedFile: any;
  filePreview: any;
  format: 'video' | 'image';
  MediaTypeEnum = MediaTypeEnum;

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false),
    upload: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private mediaService: MediaService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.mediaId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('mediaId: ', this.mediaId);
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.adminApi.getTags(),
      this.mediaId ? this.adminApi.getMediaById({ id: this.mediaId }) : of(null),
      this.mediaId ? this.adminApi.getImageToken() : of(null)
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([tags, media, imageToken]) => {
        this.tags = tags || [];
        this.media = this.mediaService.getMedia(media, imageToken);
        this.initForm(this.media);
      });
  }

  selectFile(event: any): void {
    this.selectedFile = event.target.files && event.target.files[0];
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.filePreview = e.target.result;
      };
      reader.readAsDataURL(this.selectedFile);
      if (this.selectedFile.type.indexOf('image') > -1) {
        this.format = 'image';
      } else if (this.selectedFile.type.indexOf('video') > -1) {
        this.format = 'video';
      }
    }
  }

  clearFile(): void {
    this.selectedFile = null;
    this.filePreview = null;
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    if (this.selectedFile) {
      const uploadRequest = this.format === 'image' ? this.adminApi.mediaUploadImage({ body: { file: this.selectedFile } }) :
        this.adminApi.mediaUploadVideo({ body: { file: this.selectedFile } });
      uploadRequest.pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.upload)
      ).subscribe((media) => {
        this.form.get('id').setValue(media.mediaId);
        this.saveMedia();
      });
    } else if (this.media?.filename) {
      this.saveMedia();
    } else {
      this.systemMessageService.error('Vänligen välj en fil.');
    }
  }

  onCancelClick(): void {
    this.cancel.emit();
  }

  displayTag(tag: Tag): string {
    return tag?.name ?? '';
  }

  newTag(name: string): Tag {
    return { id: null, name };
  }

  private saveMedia(): void {
    const value = this.form.value;
    this.adminApi.saveMedia({
      body: {
        id: value.id,
        name: value.name,
        tags: value.tags?.map(t => ({ tagId: t.id, name: t.name }))
      }
    })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe((mediaFile) => {
        if (!this.isModal) {
          this.systemMessageService.success(`Media ${this.form.value.name} sparad!`);
          this.router.navigate(['/medias']);
        } else {
          this.mediaSaved.emit(mediaFile);
        }
      });
  }

  private initForm(media?: Media): void {
    this.form = this.formBuilder.group({
      id: this.mediaId,
      name: [media?.name, Validators.required],
      tags: this.formBuilder.control(media?.tags)
    });
    if (this.mediaId && !media) {
      this.form.disable();
    } else {
      setTimeout(() => this.form.enable());
    }
  }

}

interface MediaFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  tags: FormControl<Tag[]>;
}
