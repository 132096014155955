import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Characteristic, CharacteristicTypeEnum } from 'src/_api';
import { CharacteristicTypeTranslations } from './characteristic-type-translations';

@Component({
  selector: 'flow-characteristic',
  templateUrl: './characteristic.component.html',
  styleUrls: ['./characteristic.component.scss']
})
export class CharacteristicComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'type', 'actions'];
  dataSource: MatTableDataSource<Characteristic>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getCharacteristics();
  }

  getCharacteristics(): void {
    this.adminApi.getCharacteristics()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((characteristics) => {
        this.dataSource = new MatTableDataSource(characteristics);
        this.dataSource.sort = this.sort;
      });
  }

  onDeleteClick(event: Event, characteristic: Characteristic): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeCharacteristic({ characteristicId: characteristic.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getCharacteristics();
      });
  }

  typeLabel(type: CharacteristicTypeEnum): string {
    return CharacteristicTypeTranslations[type];
  }
}
