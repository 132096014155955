import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionary } from 'lodash';
import { groupBy, uniq } from 'lodash-es';
import { takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Characteristic, CharacteristicTypeEnum, TargetGroup } from 'src/_api';
import { CharacteristicTypeTranslations } from '../characteristic/characteristic-type-translations';

@Component({
  selector: 'flow-target-group',
  templateUrl: './target-group.component.html',
  styleUrls: ['./target-group.component.scss']
})
export class TargetGroupComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['id', 'name', 'characteristics', 'actions'];
  dataSource: MatTableDataSource<TargetGroupListItem>;
  objectKeys = Object.keys;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getTargetGroups();
  }

  getTargetGroups(): void {
    this.adminApi.getTargetGroups()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((targetGroupsResponse) => {
        const allCharacteristicIds = uniq(targetGroupsResponse.flatMap(tg => tg.characteristicIds));
        this.adminApi.getManyCharacteristics({ body: allCharacteristicIds }).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((characteristics) => {
            const targetGroups = targetGroupsResponse.map(tg => {
              const listItem = tg as TargetGroupListItem;
              const tgCharacteristics = tg.characteristicIds.map(cId => characteristics.find(c => c.id === cId));
              listItem.characteristicsGrouped = groupBy(tgCharacteristics, 'type');
              return listItem;
            }) as TargetGroupListItem[];
            this.dataSource = new MatTableDataSource(targetGroups);
            this.dataSource.sort = this.sort;
          })
      });
  }

  onDeleteClick(event: Event, targetGroup: TargetGroupListItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeTargetGroup({ targetGroupId: targetGroup.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getTargetGroups();
      });
  }

  typeLabel(type: CharacteristicTypeEnum): string {
    return CharacteristicTypeTranslations[type];
  }

}

interface TargetGroupListItem extends TargetGroup {
  characteristicsGrouped: Dictionary<Characteristic[]>;
}
