import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, Location } from 'src/_api';

@Component({
  selector: 'flow-location-create',
  templateUrl: './location-create.component.html',
  styleUrls: ['./location-create.component.scss']
})
export class LocationCreateComponent extends unsubscribeMixin() implements OnInit {

  locationId: number;
  location: Location;
  customers: Customer[];
  form: FormGroup<LocationFormGroup>;

  loading$ = {
    location: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  }

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.locationId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('locationId: ', this.locationId);
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.locationId ? this.adminApi.getLocationById({ locationId: this.locationId }) : of(null),
      this.adminApi.getCustomers(),
    ]).pipe(
      takeUntil(this.ngUnsubscribe),
      indicateLoading(this.loading$.location))
      .subscribe(([location, customers]) => {
        this.location = location;
        this.customers = customers;
        this.initForm(location);
      });

  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    this.adminApi.saveLocation({ body: this.form.value })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Butik ${this.form.value.name} sparad!`);
        this.router.navigate(['/locations']);
      });
  }

  private initForm(location?: Location): void {
    this.form = this.formBuilder.group({
      id: this.locationId,
      name: [location?.name, Validators.required],
      customerId: [location?.customerId, Validators.required]
    });
    if (this.locationId && !location) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }
}

interface LocationFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  customerId: FormControl<number>;
}
