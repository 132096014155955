import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { isEqual } from 'lodash-es';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject, interval, Observable, Subscription, takeUntil } from 'rxjs';
import { STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL } from 'src/app/constants';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { PeopleCounterRequest, StatisticsApi } from 'src/_api';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { TargetGroupHelper } from '../../target-group/target-group.helper';
import { StatisticsFilterValues } from '../statistics-filter.component';
import { StatisticsService } from '../statistics.service';
import { peopleTargetGroups } from './people-count-models';

@Component({
  template: ''
})
export abstract class AbstractPeopleCountTargetGroup extends unsubscribeMixin() implements OnInit {
  @Input() filter: StatisticsFilterValues;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  peopleTargetGroups = peopleTargetGroups

  loading$ = new BehaviorSubject(true);
  statistics: PeopleCounterResponse;
  statisticsComparable: PeopleCounterResponse;
  statsRequest: Subscription;
  statsRequestCompare: Subscription;
  public chartPlugins = [DataLabelsPlugin];


  constructor(
    protected statisticsApi: StatisticsApi,
    protected statisticsService: StatisticsService,
    protected targetGroupHelper: TargetGroupHelper
  ) {
    super();
    interval(STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateData();
      });
  }

  ngOnInit(): void {
    this.updateData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filter?.currentValue) {
      this.updateData();
    }
  }
  override ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.statsRequest?.unsubscribe();
  }

  protected updateData(): void {
    this.statsRequest?.unsubscribe();
    this.statsRequest = this.doApiRequest()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$))
      .subscribe((response) => {
        if (isEqual(response, this.statistics)) {
          console.log('equal data');
          return;
        }
        this.statistics = response;
        console.log('charts up to date', response);
        this.setChartData(response, this.statisticsComparable);
      });
  }
  protected updateComparableData(): void {
    this.statsRequestCompare?.unsubscribe();
    this.statsRequestCompare = this.doApiRequestCompare()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$))
      .subscribe((response) => {
        this.statisticsComparable = response;
        this.setChartData(this.statistics, response)
      });
  }

  protected doApiRequest(): Observable<PeopleCounterResponse> {
    return this.statisticsApi
      .peoplePerDay2({
        body: this.getRequestBody()
      })
  }
  protected doApiRequestCompare(): Observable<PeopleCounterResponse> {
    return this.statisticsApi
      .peoplePerDay2({
        body: this.getRequestBodyCompare()
      })
  }
  protected getRequestBodyCompare?(): PeopleCounterRequest {
    return this.getRequestBody()
  }
  abstract getRequestBody(): PeopleCounterRequest
  abstract setChartData(statistics?: PeopleCounterResponse, statisticsToCompare?: PeopleCounterResponse): void

}

