<div class="page-header"
     *ngIf="!isModal">
  <h1 class="page-title"
      *ngIf="!mediaId">Skapa media</h1>
  <h1 class="page-title"
      *ngIf="mediaId">Redigera media</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full">
      <mat-label>Namn</mat-label>
      <input matInput
             type="text"
             formControlName="name">
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Taggar</mat-label>
      <flow-multi-select-autocomplete formControlName="tags"
                                      [options]="tags"
                                      [displayFn]="displayTag"
                                      [newOptionFn]="newTag"></flow-multi-select-autocomplete>
    </mat-form-field>

    <mat-form-field class="upload-field"
                    *ngIf="!media?.url">
      <div>
        <mat-toolbar>
          <input matInput
                 [value]="selectedFile ? selectedFile.name : 'Välj fil'" />
          <!-- <mat-divider [vertical]="true"></mat-divider> -->
          <button *ngIf="selectedFile"
                  mat-icon-button
                  color="warn"
                  (click)="clearFile()">
            <mat-icon>clear</mat-icon>
          </button>

          <input type="file"
                 id="fileInput"
                 name="fileInput"
                 accept="image/*, video/*"
                 [ngClass]="{ 'clear-visible' : !!selectedFile }"
                 (change)="selectFile($event)" />
        </mat-toolbar>


      </div>
    </mat-form-field>
    <div class="preview">
      <ng-container *ngIf="filePreview">
        <img *ngIf="format === 'image'"
             [src]="filePreview">
        <!-- <video *ngIf="format ==='video'"
               [src]="filePreview"
               controls></video> -->
      </ng-container>
      <img *ngIf="!filePreview && media?.type === MediaTypeEnum.Image && media?.url"
           [src]="media.url">
      <video *ngIf="!filePreview && media?.type === MediaTypeEnum.Video && media?.url"
             [src]="media.url"
             controls></video>
    </div>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions"
               *ngIf="!isModal">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/medias']">
        Avbryt
      </button>
      <button [disabled]="(loading$.save | async) || (loading$.upload | async)"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>

  <div *ngIf="isModal"
       class="flex items-center justify-end mt-8">
    <button mat-button
            type="button"
            (click)="onCancelClick()">Avbryt</button>
    <button mat-raised-button
            type="button"
            (click)="onSaveClick()"
            color="primary"
            cdkFocusInitial>Spara</button>
  </div>
</form>
