import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { Router } from "@angular/router";
import { groupBy } from 'lodash-es';
import { DateTime } from "luxon";
import { BehaviorSubject, forkJoin, takeUntil } from "rxjs";
import { AppService } from "src/app/app.service";
import { EnumService } from "src/app/core/enum.service";
import { indicateLoading } from "src/app/core/indicate-loading";
import { SystemMessageService } from "src/app/core/system-message.service";
import { unsubscribeMixin } from "src/app/core/unsubscribe";
import { AdminApi, BookCampaignRequest, BookingSummary, BookSlot, CampaignAdminApi, CampaignTypeEnum, Customer, Location } from "src/_api";
import { CampaignCalendarSlot } from "../calendar/calendar-models";
import { CampaignBookCalendarSpan, CampaignBookCalendarSpanFormGroup, CampaignBookFormGroup, CampaignBookScreen } from "./campaign-book-models";

@Component({
  selector: 'flow-campaign-book',
  templateUrl: './campaign-book.component.html',
  styleUrls: ['./campaign-book.component.scss']
})
export class CampaignBookComponent extends unsubscribeMixin() implements OnInit {

  form: FormGroup<CampaignBookFormGroup>;

  // targetGroups: EnumObject<TargetGroupEnum>[];
  screens: CampaignBookScreen[];
  bookings: BookingSummary[];
  locations: Location[];
  customers: Customer[];
  selectedTabIndex: number;
  //för kalender
  campaignSlots: CampaignCalendarSlot[] = [];
  selectedCampaignSlots: CampaignCalendarSlot[] = [];

  loading$ = {
    init: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  };

  @ViewChild("tabGroup", { static: false }) tabGroup: MatTabGroup;

  constructor(
    private adminApi: AdminApi,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public enumService: EnumService,
    public dialog: MatDialog,
    private appService: AppService,
    private campaignAdminApi: CampaignAdminApi
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.selectedTabIndex = 0;
    this.initForm();
  }

  ngOnInit(): void {
    // this.targetGroups = this.enumService.targetGroups;
    forkJoin([
      this.adminApi.getScreens(),
      this.adminApi.getLocations(),
      this.adminApi.getCustomers()
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([screens, locations, customers]) => {
        this.screens = screens?.map(screen => {
          const location = locations?.find(loc => loc.id === screen.locationId);
          const cs = screen as CampaignBookScreen;
          cs.location = location;
          return cs;
        }) || [];
        this.customers = customers;
        this.locations = locations;
      });
  }

  onSaveClick(): void {
    console.log(this.form);
    if (!this.form.valid || !this.selectedCampaignSlots?.length) {
      return;
    }
    const formValues = this.form.value;
    const bookSlots: BookSlot[] = [];
    // formValues.targetGroups.forEach(tg => {
    this.selectedCampaignSlots.forEach(slot => {
      bookSlots.push({
        startDate: slot.start.toISO(),
        endDate: slot.end.plus({ millisecond: 1 }).toISO(), //end är söndag kl 23:59:59:999, lägger på 1 millisek för att få 00:00:00:000
        screenId: slot.screen.id,
        // targetGroup: tg.value
      });
    });
    // });
    const body: BookCampaignRequest = {
      name: formValues.name,
      type: formValues.type.value,
      customerId: formValues.customer.id,
      slots: bookSlots
    };
    console.log(body);
    this.campaignAdminApi.bookCampaign({ body })
      .pipe(takeUntil(this.ngUnsubscribe), indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Kampanj ${formValues.name} har publicerats!`);
        this.router.navigate(['/campaigns']);
      });
  }

  onSelectedTabIndexChange = (index: number): void => {
    this.selectedTabIndex = index;
    if (this.selectedTabIndex === 2) {
      this.selectedCampaignSlots = this.campaignSlots.filter(cs => cs.selected);
    }
  }

  locationsValueString = (): string => {
    return this.form.value.locations?.map(loc => loc.name).join(', ') || '-';
  }

  // targetGroupsValueString = (): string => {
  //   return this.form.value.targetGroups?.map(tg => tg.name).join(', ') || '-';
  // }

  private getBookings = (locations: Location[], calendarSpan: CampaignBookCalendarSpan): void => {
    if (!locations?.length || !calendarSpan?.start || !calendarSpan?.end) {
      return;
    }
    this.campaignAdminApi.getBookings({
      body: {
        locationIds: locations.map(loc => loc.id),
        periodStart: calendarSpan.start.toISO(),
        periodEnd: calendarSpan.end.toISO()
      }
    })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(response => {
        this.bookings = response?.bookings || [];
        console.log(this.bookings);
        this.mapCampaignSlots(this.form.value?.type?.value);
      });
  };

  private mapCampaignSlots = (campaignType: CampaignTypeEnum): void => {
    if (!campaignType || !this.bookings?.length || !this.screens?.length || !this.locations?.length) {
      return;
    }
    const sameTypeBookings = this.bookings.filter(b => b.campaignType === campaignType);
    const groupedByWeek = Object.values(groupBy(sameTypeBookings, 'startDate')) || [];
    const groupedByWeekAndScreen = groupedByWeek.map(weekSlots => Object.values(groupBy(weekSlots, 'screenId'))).flat();
    this.campaignSlots = groupedByWeekAndScreen.map(weekSlotsByScreen => {
      const screenId = weekSlotsByScreen[0].screenId;
      const startDate = weekSlotsByScreen[0].startDate;
      const identifier = screenId + '_' + startDate;
      const start = DateTime.fromISO(startDate).toUTC();
      const end = start.endOf('week');
      const screen = this.screens.find(s => s.id === screenId);
      return {
        identifier,
        start,
        end,
        screen,
        location: screen.location,
        campaign: null,
        bookedSlotsCount: weekSlotsByScreen.filter(slot => slot.campaignId > 0)?.length,
        totalSlotsCount: weekSlotsByScreen.length
      };
    }).sort((a, b) => a.screen.humanUniqueIdentifier?.localeCompare(b.screen.humanUniqueIdentifier));
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      customer: this.formBuilder.control(null, Validators.required),
      type: this.formBuilder.control(null, Validators.required),
      locations: this.formBuilder.control(null, Validators.required),
      calendarSpan: this.toCalendarSpanFormGroup()
    });
    // this.form.get('customer').valueChanges
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe(customer => {
    //     this.filteredLocations = this.allLocations?.filter(loc => loc.customerId === customer.id);
    //     const locationControl = this.form.get('locations');
    //     locationControl.reset();
    //     locationControl.enable();
    //     this.campaignSlots = [];
    //     this.selectedCampaignSlots = [];
    //   });
    this.form.get('locations').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(locations => this.getBookings(locations, this.form.value.calendarSpan));
    this.form.get('calendarSpan').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(calendarSpan => this.getBookings(this.form.value.locations, calendarSpan));
    this.form.get('type').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(type => this.mapCampaignSlots(type?.value));
  }

  private toCalendarSpanFormGroup(): FormGroup<CampaignBookCalendarSpanFormGroup> {
    const now = DateTime.now().toUTC().startOf('week');
    let start = now.startOf('week'), end = start.plus({ weeks: 6 }).endOf('week');
    return this.formBuilder.group({
      start: [start, Validators.required],
      end: [end, Validators.required]
    });
  }
}
