import { Component, OnInit } from '@angular/core';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { forkJoin, interval, takeUntil } from 'rxjs';
import { STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL } from 'src/app/constants';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Screen, StatisticsApi } from 'src/_api';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { StatisticsFilterValues } from '../statistics-filter.component';
import { peopleTargetGroups } from './people-count-models';

@Component({
  selector: 'flow-people-count',
  templateUrl: './people-count.component.html',
  styleUrls: ['./people-count.component.scss']
})
export class PeopleCountComponent extends unsubscribeMixin() implements OnInit {
  filter: StatisticsFilterValues;
  initData: { screens: Screen[], statistics: PeopleCounterResponse } = { screens: [], statistics: {} };
  statistics: PeopleCounterResponse;
  peopleTargetGroupsStatistics: { label: string, currentWeek: number }[] = [];

  constructor(
    private adminApi: AdminApi,
    private statisticsApi: StatisticsApi,
  ) {
    super();
    interval(STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateData();
      });
  }

  ngOnInit(): void {
    forkJoin([
      this.adminApi.getScreens(),
      this.getStatistics()
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([screens, statistics]) => {
        this.initData = { screens, statistics };
        this.statistics = statistics;
        this.peopleTargetGroupsStatistics = this.calculateTargetGroupStatistic(statistics);
      });
  }

  getStatistics() {
    const week = DateTime.utc().minus({ week: 1 })
    const day = week.startOf('day');
    const startDT = day.startOf('week');
    return this.statisticsApi
      .peoplePerDay2({
        body: {
          startTime: startDT.toISO(),
          endTime: DateTime.utc().toISO(),
          screensIds: this.filter?.screens?.map(s => s.id),
        }
      });
  }

  updateData() {
    this.getStatistics().pipe(takeUntil(this.ngUnsubscribe)).subscribe(statistics => {
      if (isEqual(statistics, this.statistics)) {
        console.log('equal data');
        return;
      }
      this.statistics = statistics;
      console.log('total stats up to date', statistics);
      this.peopleTargetGroupsStatistics = this.calculateTargetGroupStatistic(statistics);
    });
  }

  onFilterChange(filter: StatisticsFilterValues): void {
    this.filter = filter;
    this.updateData();
  }

  private calculateTargetGroupStatistic(statistics: PeopleCounterResponse) {
    const currentWeek = DateTime.utc()
    const currentWeekStart = currentWeek.startOf('day').startOf('week');
    const currentWeekEnd = currentWeek.startOf('day').endOf('week').endOf('day');


    const currentWeekStats = statistics.screenHours.filter(s => {
      const dt = DateTime.fromISO(s.time)
      return dt >= currentWeekStart && dt <= currentWeekEnd
    })


    const targetGroupsStats = peopleTargetGroups.map((group) => ({
      currentWeek: currentWeekStats.reduce((sum, curr) => sum + curr.peoples
        .filter(p => p.targetGroup === group.value)
        .reduce((sum, stat) => sum + stat.count, 0), 0),
      label: group.label,
    }))

    return [{ label: 'Totalt', currentWeek: targetGroupsStats.reduce((sum, curr) => sum += curr.currentWeek, 0) }, ...targetGroupsStats]
  }

}

