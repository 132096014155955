import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { EnumService } from 'src/app/core/enum.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Characteristic, CharacteristicTypeEnum } from 'src/_api';

@Component({
  selector: 'flow-characteristic-create',
  templateUrl: './characteristic-create.component.html',
  styleUrls: ['./characteristic-create.component.scss']
})
export class CharacteristicCreateComponent extends unsubscribeMixin() implements OnInit {

  characteristicId: number;
  characteristic: Characteristic;
  form: FormGroup<CharacteristicFormGroup>;

  loading$ = {
    characteristic: new BehaviorSubject(false),
    save: new BehaviorSubject(false)
  };

  constructor(
    private adminApi: AdminApi,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    public enumService: EnumService,
    private appService: AppService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.characteristicId = +this.activatedRoute.snapshot.params['id'] || null;
    console.log('characteristicId: ', this.characteristicId);
    this.initForm();
  }

  ngOnInit(): void {
    if (this.characteristicId) {
      this.adminApi.getCharacteristicById({ characteristicId: this.characteristicId })
        .pipe(
          takeUntil(this.ngUnsubscribe),
          indicateLoading(this.loading$.characteristic))
        .subscribe((characteristic) => {
          this.characteristic = characteristic;
          this.initForm(characteristic);
        });
    }
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    this.adminApi.createOrUpdateCharacteristic({ body: this.form.value })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Egenskap ${this.form.value.name} sparad!`);
        this.router.navigate(['/characteristics']);
      });
  }

  private initForm(characteristic?: Characteristic): void {
    this.form = this.formBuilder.group({
      id: this.characteristicId,
      name: [characteristic?.name, Validators.required],
      type: [characteristic?.type, Validators.required]
    });
    if (this.characteristicId && !characteristic) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

}

interface CharacteristicFormGroup {
  id: FormControl<number>;
  name: FormControl<string>;
  type: FormControl<CharacteristicTypeEnum>;
}
