import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, takeUntil } from 'rxjs';
import { UserRoleEnum } from 'src/app/core/auth/auth.service';
import { EnumObject, EnumService } from 'src/app/core/enum.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer } from 'src/_api';
import { User } from './../../../_api/models/user';
import { UserApi } from './../../../_api/services/user-api';

@Component({
  selector: 'flow-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'roles', 'email', 'customers', 'actions'];
  dataSource: MatTableDataSource<UserListItem>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userApi: UserApi,
    private adminApi: AdminApi,
    private enumService: EnumService
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    forkJoin([
      this.userApi.getUsers(),
      this.adminApi.getCustomers()
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([usersResponse, customers]) => {
        const roles = this.enumService.userRoles;
        const users = usersResponse?.map(u => {
          const user = u as UserListItem;
          user.roles = user.roleIds?.map(rId => roles.find(role => role.value === rId));
          user.customers = user.customerIds?.map(cId => customers.find(customer => customer.id === cId));
          return user;
        });
        this.dataSource = new MatTableDataSource(users);
        this.dataSource.sort = this.sort;
      });
  }

  onDeleteClick(user: User): void {
    this.userApi.deleteUser({ userId: user.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getUsers();
      });
  }

}

interface UserListItem extends User {
  roles: EnumObject<UserRoleEnum>[];
  customers: Customer[];
}

