<div class="flex flex-col">
  <year-and-month-picker [formValue]="date"
                         (monthChanged)="onMonthChange($event)"></year-and-month-picker>
</div>
<canvas baseChart
        [data]="chartData"
        [type]="'bar'"
        [options]="chartOptions"
        [plugins]="chartPlugins">
</canvas>
