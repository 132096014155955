import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, takeUntil } from 'rxjs';
import { Media, MediaService } from 'src/app/core/media.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, PersonOfInteresetApi, PersonOfInterest } from 'src/_api';

@Component({
  selector: 'flow-person-of-interest',
  templateUrl: './person-of-interest.component.html',
  styleUrls: ['./person-of-interest.component.scss']
})
export class PersonOfInterestComponent extends unsubscribeMixin() implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'customer', 'media', 'actions'];
  dataSource: MatTableDataSource<PersonOfInterestListItem>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi,
    private personOfInterestApi: PersonOfInteresetApi,
    private mediaService: MediaService
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getPersonOfInterests();
  }

  getPersonOfInterests(): void {
    this.personOfInterestApi.getPersonOfInterests()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((personOfInterestsResponse) => {
        forkJoin([
          this.adminApi.getCustomers(),
          this.adminApi.getImageToken()
        ]).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(([customers, imageToken]) => {
            const personOfInterests = personOfInterestsResponse.map(poi => {
              const listItem = poi as PersonOfInterestListItem;
              listItem.customer = customers?.find(c => c.id === poi.customerId);
              listItem.media = this.mediaService.getMedia(poi.mediaFile, imageToken);
              return listItem;
            }) as PersonOfInterestListItem[];
            this.dataSource = new MatTableDataSource(personOfInterests);
            this.dataSource.sort = this.sort;
          })
      });
  }

  onDeleteClick(event: Event, personOfInterest: PersonOfInterestListItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.personOfInterestApi.deletePersonOfInterest({ id: personOfInterest.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getPersonOfInterests();
      });
  }
}

interface PersonOfInterestListItem extends PersonOfInterest {
  media?: Media;
  customer?: Customer;
}
