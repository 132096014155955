import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { BehaviorSubject, forkJoin, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { AdminApi, Customer } from 'src/_api';
import { AuthService, UserRoleEnum } from '../auth/auth.service';
import { indicateLoading } from '../indicate-loading';
import { unsubscribeMixin } from '../unsubscribe';

@Component({
  selector: 'flow-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent extends unsubscribeMixin() implements OnInit {

  @Output() closeNav = new EventEmitter<void>();

  @ViewChild(MatExpansionPanel) userPanel: MatExpansionPanel;

  _navMini = false;
  customers: Customer[];
  selectedCustomer: Customer;
  activeNavGroup: string;
  isDev = environment.env === 'dev';
  isAdmin: boolean;

  loading$ = {
    customers: new BehaviorSubject(false)
  };

  constructor(
    private router: Router,
    private authService: AuthService,
    private adminApi: AdminApi,
    public appService: AppService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  get navMini(): boolean {
    return this._navMini && !this.appService.modeOverQuery.matches;
  }

  set navMini(value: boolean) {
    this._navMini = value;
  }

  toggleNav(): void {
    if (this.appService.modeOverQuery.matches) {
      this.closeNav.emit();
    } else {
      this.navMini = !this.navMini;
    }
  }

  ngOnInit(): void {
    this.authService.userChanged.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user) => {
        if (user) {
          this.getUserCustomers();
        } else {
          this.selectedCustomer = null;
        }
      });
    if (this.authService.isLoggedIn()) {
      this.getUserCustomers();
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigateByUrl('/login');
  }

  getUserCustomers(): void {
    forkJoin([
      this.authService.currentUser(),
      this.adminApi.getCustomers()
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.customers, this.cdr))
      .subscribe(([currentUser, customers]) => {
        this.isAdmin = currentUser?.roleIds?.some(rId => rId === UserRoleEnum.Admin);
        this.customers = this.isAdmin ? customers : customers.filter(c => currentUser.customerIds?.some(id => id === c.id));
        this.selectedCustomer = currentUser.currentCustomerId ? this.customers?.find(c => c.id === currentUser.currentCustomerId) : null;
        if (this.isAdmin) {
          const all = { id: -1, name: 'Admin' };
          this.customers.splice(0, 0, all);
          if (!this.selectedCustomer) {
            this.selectedCustomer = all;
          }
        }
      });
  }

  onUserPanelOpened() {
    this.getUserCustomers();
  }

  onCustomerChange(customer: Customer) {
    const customerId = customer.id === -1 ? null : customer.id;
    if (customerId === this.authService.user.currentCustomerId) {
      return;
    }
    this.authService.getAndSetCustomerToken(customerId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.customers, this.cdr))
      .subscribe(currentUser => {
        console.log(currentUser);
        this.userPanel.close();
        this.router.navigate(['/']);
      });
  }

  compareCustomers(c1: Customer, c2: Customer) {
    return c1?.id == c2?.id;
  }
}
