import { DateTime } from "luxon";
import { Campaign, Location, Screen } from "src/_api";

export const ITEM_ROW_HEIGHT = 35;

export interface CalendarBounds {
  from: DateTime;
  to: DateTime;
}

export interface CalendarPeriod {
  startmonth: DateTime;
  endmonth: DateTime;
  months?: DateTime[];
}

export interface CalendarWeekday {
  name: string;
}

export class CalendarWeek {
  number: number;
  monthLabel: string;
  days: CalendarDay[];
  campaignItems?: CalendarItem[];
  rowCount?: number;

  constructor(number: number, monthLabel: string, days: CalendarDay[], slots: CampaignCalendarSlot[]) {
    this.number = number;
    this.monthLabel = monthLabel;
    this.days = days;
    if (slots?.length) {
      this.campaignItems = slots?.map(slot => {
        return new CalendarItem(slot.start, slot.end, this, slot);
      }).sort((a, b) => a.leftPercentage - b.leftPercentage || a.startUnix - b.startUnix);
      this.calculateItemRows(this.campaignItems);
      // this.campaigns = campaigns.map(o => new CalendarItem(o, this))
      // 	.sort((a, b) => a.leftPercentage - b.leftPercentage || a.startUnix - b.startUnix);
      // this.calculateItemRows(this.occurrences);
    }
  }

  static buildWeek(startOfWeek: DateTime, slots: CampaignCalendarSlot[]): CalendarWeek {
    const days: CalendarDay[] = [];
    let dt = startOfWeek;
    for (let i = 0; i < 7; i++) {
      const isoString = dt.set({ millisecond: 0 }).toISO({ suppressMilliseconds: true });
      const day = new CalendarDay(
        dt.toISODate(),
        dt.day,
        dt,
        isoString,
        dt.month % 2 > 0
      );
      days.push(day);
      dt = dt.plus({ day: 1 });
    }
    const endOfWeek = startOfWeek.endOf('week');
    const startOfWeekMonth = this.capitalize(startOfWeek.toFormat('MMMM yyyy'));
    const endOfWeekMonth = this.capitalize(endOfWeek.toFormat('MMMM yyyy'));
    const monthLabel = startOfWeekMonth === endOfWeekMonth ? startOfWeekMonth : startOfWeekMonth + ' - ' + endOfWeekMonth;
    const weekSlots: CampaignCalendarSlot[] = slots?.filter(slot =>
      this.isTimePeriodMatching(slot.start, slot.end, startOfWeek, endOfWeek));
    return new CalendarWeek(startOfWeek.weekNumber, monthLabel, days, weekSlots);
  }

  private static isTimePeriodMatching(slotStart: DateTime, slotEnd: DateTime, start: DateTime, end: DateTime): boolean {
    return (slotStart >= start && slotStart <= end) ||
      (slotEnd >= start && slotEnd <= end) ||
      (start >= slotStart && start <= slotEnd) ||
      (end >= slotStart && end <= slotEnd);
  }

  private static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  calculateItemRows(items?: CalendarItem[]): void {
    const rows: CalendarItem[][] = [];
    items.forEach(o => {
      this.insertInRow(o, 0, rows);
    });
    rows.forEach((row, index) => {
      row.forEach(res => {
        res.topPx = index * ITEM_ROW_HEIGHT;
      });
    });
    this.rowCount = rows.length;
  }

  private insertInRow(item: CalendarItem, rowIndex: number, rows: CalendarItem[][]): void {
    if (!rows[rowIndex]) {
      rows.push([item]);
    } else if (this.isColliding(item, rows[rowIndex])) {
      rowIndex++;
      this.insertInRow(item, rowIndex, rows);
    } else {
      rows[rowIndex].push(item);
    }
  }

  private isColliding(item: CalendarItem, rowItems: CalendarItem[]): boolean {
    const minRight = Math.min(...rowItems.map(r => r.rightPercentage));
    return item.leftPercentage < (100 - minRight); // right är procent från höger, 100 - right för att få procent från left
  }
}

export class CalendarDay {

  constructor(
    readonly id: string,
    readonly number: number,
    readonly dateTime: DateTime,
    readonly utcString: string,
    readonly isOddMonth: boolean
  ) { }

}

export class CalendarItem {
  startUnix: number;
  startDiffInMinutes: number;
  endDiffInMinutes: number;
  topPx: number;
  leftPercentage: number;
  rightPercentage: number;
  campaignSlot: CampaignCalendarSlot;

  constructor(start: DateTime, end: DateTime, week: CalendarWeek, slot: CampaignCalendarSlot) {
    this.campaignSlot = slot;
    this.topPx = 0;
    this.startUnix = start.toSeconds();
    let points = 7 * 24 * 60;
    //lägg till eller ta bort 1 timme (60 min) på veckan pga sommar/vintertid
    const startTimezoneOffset = week.days[0].dateTime.offset;
    const endTimezoneOffset = week.days[week.days.length - 1].dateTime.endOf('day').offset;
    if (startTimezoneOffset > endTimezoneOffset) {
      points += 60;
    } else if (startTimezoneOffset < endTimezoneOffset) {
      points -= 60;
    }
    this.startDiffInMinutes = Math.round(week.days[0].dateTime.diff(start, 'minutes').minutes) * -1;
    const startDiffInPercentage = this.startDiffInMinutes / points * 100;
    this.leftPercentage = startDiffInPercentage > 0 ? startDiffInPercentage : 0;
    this.endDiffInMinutes = Math.round(week.days[week.days.length - 1].dateTime.endOf('day').diff(end, 'minutes').minutes);
    const endDiffInPercentage = this.endDiffInMinutes / points * 100;
    this.rightPercentage = endDiffInPercentage > 0 ? endDiffInPercentage : 0;
  }

  toString(): string {
    if (this.campaignSlot.bookedSlotsCount != null) {
      return `(${this.campaignSlot.bookedSlotsCount}/${this.campaignSlot.totalSlotsCount}) - Skärm: ${this.campaignSlot.screen.humanUniqueIdentifier}, ${this.campaignSlot.location.name}`;
    } else if (this.campaignSlot.campaign) {
      return `${this.campaignSlot.campaign.name} - (Skärm: ${this.campaignSlot.screen.humanUniqueIdentifier}, ${this.campaignSlot.location.name})`;
    } else if (this.campaignSlot.screen) {
      return `Skärm: ${this.campaignSlot.screen.humanUniqueIdentifier}, ${this.campaignSlot.location.name}`;
    } else {
      return '';
    }
  }
}

export interface CampaignCalendarSlot {
  start: DateTime;
  end: DateTime;
  screen: Screen;
  location: Location;
  campaign: Campaign;
  // campaign create
  identifier?: string;
  selected?: boolean;
  bookedSlotsCount?: number;
  totalSlotsCount?: number;
  campaignSlotId?: number;
}
