import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { delay, finalize, tap } from 'rxjs/operators';
import { UserLoginResponse } from 'src/_api';
import { STORAGE_KEY_AUTH } from '../constants';
import { AuthService } from './auth/auth.service';
import { LoaderService } from './loader/loader.service';
import { SystemMessageService } from './system-message.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {

  genericErrorMessage = 'Tekniskt fel';
  currentSnackbar: MatSnackBarRef<SimpleSnackBar>;
  currentErrorEndpoint: string;

  constructor(
    private authService: AuthService,
    private systemMessageService: SystemMessageService,
    private loaderService: LoaderService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth: UserLoginResponse = JSON.parse(localStorage.getItem(STORAGE_KEY_AUTH));

    if (auth?.token && !this.authService.tokenExpired(auth)) {
      req = req.clone({
        setHeaders: {
          'Authorization': 'Bearer ' + auth.token
        }
      });
    }
    this.loaderService.show();
    return next.handle(req).pipe(
      delay(this.loaderService.requestDelay),
      finalize(() => this.loaderService.hide()),
      tap({
        next: x => x,
        error: (err: HttpErrorResponse) => {
          console.error(err);
          if (!err) {
            return throwError(() => new Error(this.genericErrorMessage));
          }
          if (err.status === 401) {
            this.systemMessageService.error('Obehörigt anrop. Vänligen logga in igen för att använda systemet.');
            return this.authService.handleUnauthorized(err);
          }
          const backendResponse = err.error;
          if (backendResponse?.errors && typeof backendResponse.errors === 'object') {
            let errors: string[] = [];
            const keys = Object.keys(backendResponse.errors);
            keys.forEach(key => {
              const error = backendResponse.errors[key];
              if (typeof error === 'string') {
                errors.push(error);
              } else if (error?.length) {
                errors = errors.concat(error);
              }
            });
            const msg = errors.join(' ');
            this.systemMessageService.error(msg);
          } else if (typeof backendResponse === 'string') {
            this.systemMessageService.error(backendResponse.slice(0, 500));
          } else if (backendResponse?.length) {
            let errors: string[] = [];
            backendResponse.forEach((error: any) => {
              if (typeof error === 'string') {
                errors.push(error);
              } else if (error?.length) {
                errors = errors.concat(error);
              } else if (error?.description) {
                errors.push(error.description);
              }
            });
            const msg = errors.join(' ');
            this.systemMessageService.error(msg);
          } else {
            this.systemMessageService.error(this.genericErrorMessage);
            return throwError(() => new Error(this.genericErrorMessage));
          }
          return throwError(() => new Error(backendResponse));
        }
      })
    );
  }
}
