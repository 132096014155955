import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration } from 'chart.js';
import { DateTime } from 'luxon';
import { AbstractViewsComponent } from '../abstract-views-component';


@Component({
  selector: 'flow-views-monthly',
  templateUrl: './views-monthly.component.html',
  styleUrls: ['./views-monthly.component.scss'],
})
export class ViewsMonthlyComponent extends AbstractViewsComponent {
  override chartOptions: ChartConfiguration['options'] = {
    ...this.chartOptions,
    plugins: {
      ...this.chartOptions.plugins,
      datalabels: {
        ...this.chartOptions.plugins.datalabels,
        rotation: -67
      }
    }
  };
  date = new FormControl(DateTime.utc());

  onMonthChange(value: DateTime) {
    this.date.setValue(value);
    this.onPeriodChange()
  }

  getColumnType(): 'date' {
    return 'date'
  }

  getStartAndEndTime() {
    const month = this.date.value
    const day = month.startOf('day');
    const startTime = day.startOf('month');
    const endTime = day.endOf('month');

    return {
      startTime,
      endTime,
    }
  }
}
