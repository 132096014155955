import { environment } from "src/environments/environment";

export const STORAGE_KEY_USER = environment.env + '_polar.user';
export const STORAGE_KEY_AUTH = environment.env + '_polar.auth';
export const STORAGE_KEY_LOCATION = environment.env + '_polar.location';

export const STATISTICS_START_DATE = '2022-10-01';
export const STATISTICS_PEOPLE_COUNT_POLLING_INTERVAL = 15000;
export const STATISTICS_EXPOSURES_POLLING_INTERVAL = 60000;

