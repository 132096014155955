<div class="page-header">
  <h1 class="page-title"
      *ngIf="!screenId">Skapa skärm</h1>
  <h1 class="page-title"
      *ngIf="screenId">Redigera skärm</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full">
      <mat-label>Skärmnamn</mat-label>
      <input matInput
             type="text"
             formControlName="humanUniqueIdentifier">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Butik</mat-label>
      <mat-select formControlName="locationId">
        <mat-option *ngFor="let location of locations"
                    [value]="location.id">
          {{location.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Placering</mat-label>
      <input matInput
             type="text"
             formControlName="locationDescription">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Information</mat-label>
      <input matInput
             type="text"
             formControlName="note">
    </mat-form-field>
    <mat-form-field floatLabel="always">
      <mat-label>Skärmlayout (px)</mat-label>
      <div class="screen-layout-input-container">
        <input matInput
               type="number"
               formControlName="width"
               placeholder="Bredd"
               class="mr-2">
        x
        <input matInput
               type="number"
               formControlName="height"
               placeholder="Höjd"
               class="ml-2">
      </div>
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Standardmedia</mat-label>
      <mat-select formControlName="defaultMediaId">
        <mat-option *ngFor="let media of medias"
                    [value]="media.mediaId">
          {{media.name || '#' + media.mediaId}}
        </mat-option>
      </mat-select>
      <img class="mt-3"
           *ngIf="selectedMedia?.type === MediaTypeEnum.Image"
           [src]="selectedMedia.url">
    </mat-form-field>

    <mat-form-field class="w-full form-field-readonly"
                    *ngIf="screen?.uniqueIdentifier">
      <mat-label>Extern skärmidentitet</mat-label>
      <input matInput
             type="text"
             [readonly]="true"
             [value]="screen.uniqueIdentifier"
             [disabled]="true">
    </mat-form-field>

    <mat-form-field class="w-full form-field-readonly"
                    *ngIf="screen?.uniqueIdentifier">
      <mat-label>Ai Version</mat-label>
      <mat-select formControlName="aiVersion">
        <mat-option *ngFor="let version of aiVersions"
                    [value]="version">
          {{version}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full form-field-readonly"
                    *ngIf="screen?.uniqueIdentifier">
      <mat-label>Extern skärmidentitet</mat-label>
      <mat-select formControlName="advertiserVersion">
        <mat-option *ngFor="let version of advertiserVersions"
                    [value]="version">
          {{version}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/screens']">
        Avbryt
      </button>
      <button [disabled]="loading$.save | async"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
