<div class="bc-campaign-time"
     #campaignItemRef
     [ngClass]="{
      'bc-crossing-left': isCrossingLeft,
      'bc-crossing-right': isCrossingRight
    }"
     (click)="onItemClick(item)">
  <mat-icon *ngIf="isCrossingLeft"
            class="bc-start-arrow">arrow_right_alt</mat-icon>
  <span class="bc-campaign-time-label"
        *ngIf="!isCrossingLeft">
    <mat-checkbox class="checkbox-w-full"
                  *ngIf="isSelectState"
                  [disabled]="item.campaignSlot.totalSlotsCount > 0 && item.campaignSlot.bookedSlotsCount === item.campaignSlot.totalSlotsCount"
                  [checked]="item.campaignSlot.selected"
                  (change)="toggleSlotSelected($event)">
      {{item}}
    </mat-checkbox>
    <span *ngIf="!isSelectState">
      {{item}}
    </span>
  </span>
  <mat-icon *ngIf="isCrossingRight"
            class="bc-end-arrow">arrow_right_alt</mat-icon>
</div>
