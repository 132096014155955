import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { PeopleCounterRequest, TargetGroupEnum } from 'src/_api';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { ChartWeekday } from '../../statistics.service';
import { AbstractPeopleCountTargetGroup } from '../abstract-people-count-target-group';

@Component({
  selector: 'flow-people-count-target-group-weekly',
  templateUrl: './people-count-target-group-weekly.component.html',
  styleUrls: ['./people-count-target-group-weekly.component.scss'],

})
export class PeopleCountTargetGroupWeeklyComponent extends AbstractPeopleCountTargetGroup {
  public chartOptions: ChartConfiguration['options'] = {
    scales: {
      y: { grace: '5%' }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public chartData: ChartData<'bar', number[], string | string[]> = null;
  range = new FormGroup({
    start: new FormControl<DateTime | null>(DateTime.utc().startOf('week')),
    end: new FormControl<DateTime | null>(DateTime.utc().endOf('week')),
  });
  rangeCompare = new FormGroup({
    start: new FormControl<DateTime | null>(null),
    end: new FormControl<DateTime | null>(null),
  });


  private getStartAndEndDT(start: DateTime, end: DateTime) {
    const startDT = start?.startOf('week');
    const endDT = end?.endOf('week');

    return [startDT, endDT]
  }

  onWeekSelected() {
    this.updateData()
  }
  onWeekCompareSelected() {
    this.updateComparableData()
  }

  getRequestBody() {
    const { start, end } = this.range.value
    const [startDT, endDT] = this.getStartAndEndDT(start, end)
    return {
      startTime: startDT?.toISO(),
      endTime: endDT?.toISO(),
      screensIds: this.filter?.screens?.map(s => s.id),
    }
  }
  override getRequestBodyCompare(): PeopleCounterRequest {
    const { start, end } = this.rangeCompare.value
    const [startDT, endDT] = this.getStartAndEndDT(start, end)
    return {
      startTime: startDT?.toISO(),
      endTime: endDT?.toISO(),
      screensIds: this.filter?.screens?.map(s => s.id),
    }
  }

  private getChartData(weekdays: ChartWeekday[], stats: PeopleCounterResponse, group: TargetGroupEnum) {
    return weekdays.reduce((acc, day) => {
      const sum = stats.screenHours.reduce((acc, stat) => {
        const statDay = DateTime.fromISO(stat.time).startOf('day').weekday
        const weekDay = DateTime.fromISO(day.utcString).startOf('day').weekday

        if (statDay !== weekDay) return acc

        const sumPerHour = stat.peoples
          .filter(p => p.targetGroup === group)
          .reduce((sum, stat) => sum + stat.count, 0)
        return acc + sumPerHour
      }, 0)

      acc.push(sum || null)
      return acc
    }, [])
  }


  setChartData(statistics?: PeopleCounterResponse, statisticsCompare?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return
    const { start, end } = this.range.value
    const [startDT, endDT] = this.getStartAndEndDT(start, end)
    const weekdays = this.statisticsService.buildPeriod(startDT, endDT);

    this.chartData = {
      labels: weekdays.map(day => day.label),
      datasets: this.peopleTargetGroups.reduce((acc, group) => {
        const data = {
          label: group.label,
          data: this.getChartData(weekdays, statistics, group.value),
          ...this.targetGroupHelper.getChartColors(group.value)
        }
        acc.push(data)

        if (statisticsCompare && this.rangeCompare.value.start) {
          const { weekNumber, year } = this.rangeCompare.value.start
          const comparedData = {
            label: `${group.label} v.${weekNumber} ${year}`,
            data: this.getChartData(weekdays, statisticsCompare, group.value),
            ...this.targetGroupHelper.getChartColors(group.value, 0.3)
          }
          acc.push(comparedData)
        }

        return acc
      }, [])
    }
    this.chart.update();
  }

}

