import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs';
import { User } from 'src/_api';
import { SystemMessageService } from '../system-message.service';
import { unsubscribeMixin } from '../unsubscribe';
import { AuthService } from './auth.service';

@Component({
  selector: 'flow-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends unsubscribeMixin() implements OnInit {
  loadingLogin = false;
  navigating = false;
  email: string;
  password: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private systemMessageService: SystemMessageService
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.authService.isLoggedIn());
  }

  onLogin(email: string, password: string) {
    this.loadingLogin = true;
    this.authService.login(email, password).pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe({
      next: (user: User) => {
        this.loadingLogin = false;
        this.navigating = true;
        this.systemMessageService.success('Välkommen ' + user.email);
        if (this.authService.previousUrl) {
          this.router.navigate([this.authService.previousUrl]);
        } else {
          this.router.navigate(['']);
        }
      },
      error: (error) => {
        this.loadingLogin = false;
        this.systemMessageService.error('Inloggning misslyckades');
      }
    });
  }

  logout() {
    this.authService.logout();
  }

}
