import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { environment } from "src/environments/environment";
import { ImageToken, MediaFile, MediaTypeEnum, Tag } from "src/_api";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor() { }

  getMedia(mediaFile: MediaFile, token: ImageToken): Media {
    if (!mediaFile || !token) {
      return null;
    }
    const mediaBaseUrl = `${environment.apiBaseUrl}/admin/medias`;
    const tbSize: ThumbnailSize = this.getThumbnailSize(mediaFile, 75);
    let mediaUrl: string, thumbnailUrl: string;
    if (mediaFile.type === MediaTypeEnum.Image) {
      mediaUrl = `${mediaBaseUrl}/token/${token.token}/images/${mediaFile.mediaId}`;
      thumbnailUrl = tbSize ? `${mediaBaseUrl}/images/${mediaFile.mediaId}/width/${tbSize.width}/height/${tbSize.height}/token/${token.token}` : null;
    } else if (mediaFile.type === MediaTypeEnum.Video) {
      mediaUrl = `${mediaBaseUrl}/token/${token.token}/videos/${mediaFile.mediaId}`;
    }
    const createdDT = DateTime.fromISO(mediaFile.created);
    return {
      name: mediaFile.name,
      tags: mediaFile.tags,
      filename: mediaFile.filename,
      createdString: createdDT.toFormat('yyyy-MM-dd HH:mm'),
      createdDT: createdDT,
      id: mediaFile.mediaId,
      type: mediaFile.type,
      size: mediaFile.size,
      width: mediaFile.width,
      height: mediaFile.height,
      url: mediaUrl,
      thumbnailUrl
    }
  }

  getThumbnailSize(mediaFile: MediaFile, desiredWidth: number): ThumbnailSize {
    const fileAspectRatio = mediaFile.height / mediaFile.width;
    if (Number.isNaN(fileAspectRatio)) {
      return null;
    }
    return {
      width: desiredWidth,
      height: Math.round(fileAspectRatio * desiredWidth)
    };
  }
}

export interface Media {
  name: string;
  tags: Tag[];
  filename: string;
  createdString: string;
  createdDT: DateTime;
  type: MediaTypeEnum;
  id: number;
  url: string;
  thumbnailUrl: string;
  size: number;
  width: number;
  height: number;
}

interface ThumbnailSize {
  width: number;
  height: number;
}
