import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { uniq } from 'lodash-es';
import { forkJoin, takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Location, MediaFile, Screen } from 'src/_api';

@Component({
  selector: 'flow-screen',
  templateUrl: './screen.component.html',
  styleUrls: ['./screen.component.scss']
})
export class ScreenComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'humanUniqueIdentifier', 'location', 'locationDescription', 'layout', 'defaultMedia', 'aiVersion', 'advertiserVersion', 'actions'];
  dataSource: MatTableDataSource<ScreenListItem>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getScreens();
  }

  getScreens(): void {
    this.adminApi.getScreens()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((screensResponse) => {
        const allLocationIds = uniq(screensResponse.map(s => s.locationId));
        forkJoin([
          this.adminApi.getManyLocations({ body: allLocationIds }),
          this.adminApi.getMedias()
        ]).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(([locations, medias]) => {
            const screens = screensResponse.map(scr => {
              const screen = scr as ScreenListItem;
              screen.location = locations.find(loc => loc.id === screen.locationId);
              screen.defaultMedia = medias.find(m => m.mediaId === screen.defaultMediaId);
              return screen;
            }) as ScreenListItem[];
            this.dataSource = new MatTableDataSource(screens);
            this.dataSource.sort = this.sort;
          })
      });
  }

  onDeleteClick(event: Event, screen: ScreenListItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.adminApi.removeScreen({ screenId: screen.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getScreens();
      });
  }

}

interface ScreenListItem extends Screen {
  location?: Location;
  defaultMedia?: MediaFile;
}
