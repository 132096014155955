/* tslint:disable */
/* eslint-disable */
export enum CharacteristicTypeEnum {
  NoSpecified = 0,
  Age = 1,
  Gender = 2,
  AgeGroup = 3,
  TimeOfDay = 4,
  Weather = 5
}
