export enum ScreenOrientationEnum {
  None,
  Landscape,
  Portrait
}

export const ScreenOrientationTranslations = {
  [ScreenOrientationEnum.None]: 'Okänd',
  [ScreenOrientationEnum.Landscape]: 'Landskap',
  [ScreenOrientationEnum.Portrait]: 'Porträtt'
};
