<div class="flex flex-col">
  <mat-form-field appearance="fill"
                  class="mt-4">
    <mat-label>Datum</mat-label>
    <input matInput
           [max]="maxDate"
           [min]="minDate"
           [matDatepicker]="picker"
           [formControl]="date">
    <mat-datepicker-toggle matSuffix
                           [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</div>

<canvas baseChart
        [data]="chartData"
        [type]="'line'"
        [options]="chartOptions"
        [plugins]="chartPlugins">
</canvas>
