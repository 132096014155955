import {
  Component, EventEmitter, Injectable, Input, Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';

import { DateRange, MatDateRangeSelectionStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { DateTime } from 'luxon';
import { STATISTICS_START_DATE } from 'src/app/constants';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
@Injectable()
export class WeekRangeSelectionStrategy<D> implements MatDateRangeSelectionStrategy<D> {
  constructor(private _dateAdapter: DateAdapter<D>) { }

  selectionFinished(date: D | null): DateRange<D> {
    return this._createWeekRange(date);
  }

  createPreview(activeDate: D | null): DateRange<D> {
    return this._createWeekRange(activeDate);
  }

  private _createWeekRange(date: D | null): DateRange<D> {
    // @ts-ignore weekday does exist, but how to let typescript know?
    if (!date || date?.weekday !== 1) {
      return new DateRange<D>(null, null);
    }
    const end = this._dateAdapter.addCalendarDays(date, 6);
    return new DateRange<D>(date, end);
  }
}

@Component({
  selector: 'week-picker',
  templateUrl: './week-picker.component.html',
  styleUrls: ['./week-picker.component.scss'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: WeekRangeSelectionStrategy,
    },
  ],
})
export class WeekPickerComponent extends unsubscribeMixin() {
  @Input() range: FormGroup
  @Input() label = 'Vecka'
  @Input() disabled = false

  @Output() weekSelected = new EventEmitter();

  maxDate = DateTime.utc().endOf('week')
  minDate = STATISTICS_START_DATE
  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    if (dateRangeStart && dateRangeEnd) {
      this.weekSelected.emit()
    }
  }
}
