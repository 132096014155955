import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  navHidden = false;
  navClosed = false;
  isDark = false;
  hasToolbarFormActions = false;

  modeOverQuery: MediaQueryList;
  _modeOverQueryListener: (event) => void;

  constructor() { }
}
