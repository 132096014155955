<div class="year-and-month-picker">
  <button mat-icon-button
          (click)="previousClicked('year')">
    <mat-icon>chevron_left</mat-icon>
  </button>
  <span class="year-and-month-picker-label">{{periodLabel}}</span>
  <button mat-icon-button
          (click)="nextClicked('year')">
    <mat-icon>chevron_right</mat-icon>
  </button>
</div>
