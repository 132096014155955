import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export const unsubscribeMixin = <T extends Constructor>(base: T = class { } as T) => class extends base implements OnDestroy {
  ngUnsubscribe = new Subject<void>();
  ngOnDestroy(): void {
    /**
     * ngUnsubscribe.complete() behövs inte
     * https://stackoverflow.com/questions/44289859/do-i-need-to-complete-a-subject-for-it-to-be-garbage-collected
     */
    this.ngUnsubscribe.next();
  }
};

type Constructor<T = Record<string, unknown>> = new (...args: any[]) => T;
