export const COUNTRIES = [
  { code: 'SE', swe: 'Sverige', eng: 'Sweden' },
  { code: 'NO', swe: 'Norge', eng: 'Norway' },
  { code: 'DK', swe: 'Danmark', eng: 'Denmark' },
  { code: 'FI', swe: 'Finland', eng: 'Finland' },
  { code: 'EE', swe: 'Estland', eng: 'Estonia' },
  { code: 'DE', swe: 'Tyskland', eng: 'Germany' },
  { code: 'ES', swe: 'Spanien', eng: 'Spain' },
  { code: 'NL', swe: 'Nederländerna', eng: 'Netherlands' },
  { code: 'BE', swe: 'Belgien', eng: 'Belgium' },
  { code: 'BW', swe: 'Botswana', eng: 'Botswana' },
  { code: 'IL', swe: 'Israel', eng: 'Israel' },
  { code: 'IE', swe: 'Irland', eng: 'Ireland' },
  { code: 'ID', swe: 'Indonesien', eng: 'Indonesia' },
  { code: 'HK', swe: 'Hongkong', eng: 'Hong Kong' },
  { code: 'FR', swe: 'Frankrike', eng: 'France' },
  { code: 'GB', swe: 'Storbritannien', eng: 'United Kingdom' },
  { code: 'CZ', swe: 'Tjeckien', eng: 'Czechia' },
  { code: 'US', swe: 'USA', eng: 'United States of America' },
  { code: 'MT', swe: 'Malta', eng: 'Malta' },
  { code: 'AT', swe: 'Österrike', eng: 'Austria' },
  { code: 'SG', swe: 'Singapore', eng: 'Singapore' },
  { code: 'CH', swe: 'Schweiz', eng: 'Switzerland' },
  { code: 'PT', swe: 'Portugal', eng: 'Portugal' },
  { code: 'PL', swe: 'Polen', eng: 'Poland' },
  { code: 'AU', swe: 'Australien', eng: 'Australia' },
  { code: 'AE', swe: 'Förenade arabemiraten', eng: 'United Arab Emirates' },
  { code: 'IT', swe: 'Italien', eng: 'Italy' },
  { code: 'CA', swe: 'Kanada', eng: 'Canada' },
  { code: 'CV', swe: 'Kap Verde', eng: 'Cabo Verde' },
  { code: 'CN', swe: 'Kina', eng: 'China' },
  { code: 'CY', swe: 'Cypern', eng: 'Cyprus' },
  { code: 'LU', swe: 'Luxemburg', eng: 'Luxembourg' },
  { code: 'ME', swe: 'Montenegro', eng: 'Montenegro' },
  { code: 'NZ', swe: 'Nya Zeeland', eng: 'New Zealand' },
  { code: 'SI', swe: 'Slovenien', eng: 'Slovenia' },
  { code: 'TH', swe: 'Thailand', eng: 'Thailand' },
  { code: 'AX', swe: 'Åland', eng: 'Åland Islands' },
  { code: 'RU', swe: 'Ryssland', eng: 'Russian Federation' },
  { code: 'IS', swe: 'Island', eng: 'Iceland' },
  { code: 'TW', swe: 'Taiwan', eng: 'Taiwan(Province of China)' },
  { code: 'MY', swe: 'Malaysia', eng: 'Malaysia' }
];
