import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DateTime } from 'luxon';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { StatisticsAdvertisementResponse } from 'src/_api';
import { StatisticsFilterValues } from '../statistics-filter.component';

@Component({
  selector: 'flow-views-summary-card',
  templateUrl: './views-summary-card.component.html',
  styleUrls: ['./views-summary-card.component.scss']
})
export class ViewsSummaryCardComponent extends unsubscribeMixin() implements OnChanges {

  @Input() startDT: DateTime;
  @Input() endDT: DateTime;
  @Input() cardTitle: string;
  @Input() filter: StatisticsFilterValues;
  @Input() currentMonthStatistics?: { statistics: StatisticsAdvertisementResponse, advertisementId: number };

  count: number;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentMonthStatistics?.currentValue) {
      this.getCounts();
    }
  }

  private getCounts(): void {
    const newCount = this.currentMonthStatistics.statistics.perScreenDay.filter((i) => {
      const statisticsDate = DateTime.fromISO(i.date).toUTC().startOf('day')
      return statisticsDate >= this.startDT.startOf('day') && statisticsDate <= this.endDT.startOf('day')
    }).reduce((sum, curr) => {
      const sumForDay = curr.counts.reduce((sum, curr) => sum + curr.count, 0)
      return sum + sumForDay
    }, 0)

    this.count = newCount

  }
}
