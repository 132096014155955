<div class="page-header">
  <h1 class="page-title"
      *ngIf="!characteristicId">Skapa egenskap</h1>
  <h1 class="page-title"
      *ngIf="characteristicId">Redigera egenskap</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <mat-form-field class="w-full">
      <mat-label>Namn</mat-label>
      <input matInput
             type="text"
             formControlName="name">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Typ</mat-label>
      <mat-select formControlName="type">
        <mat-option *ngFor="let type of enumService.characteristicTypes"
                    [value]="type.value">
          {{type.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/characteristics']">
        Avbryt
      </button>
      <button [disabled]="loading$.save | async"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
