<div class="page-header">
  <h1 class="page-title">Exponeringar</h1>
  <mat-divider></mat-divider>
</div>

<!-- <flow-statistics-filter [initData]="initData"
                        (filterChange)="onFilterChange($event)"></flow-statistics-filter> -->

<mat-form-field class="mr-3"
                *ngIf="initData?.advertisements">
  <mat-label>Välj annons</mat-label>
  <mat-select [(ngModel)]="selectedAdvertisement"
              (ngModelChange)="onAdvertisementChange($event)">
    <mat-option *ngFor="let ad of initData?.advertisements"
                [value]="ad">
      {{ad.name}}
    </mat-option>
  </mat-select>
</mat-form-field>
<div class="grid grid-cols-4 gap-10 mb-10"
     *ngIf="selectedAdvertisement">
  <flow-views-summary-card *ngFor="let summaryPeriod of summaryPeriods"
                           [startDT]="summaryPeriod.startDT"
                           [endDT]="summaryPeriod.endDT"
                           [cardTitle]="summaryPeriod.title"
                           [filter]="filter"
                           [currentMonthStatistics]="currentMonthStatistics">

  </flow-views-summary-card>
</div>

<div *ngIf="initData && selectedAdvertisement"
     class="grid md:grid-cols-2 2xl:grid-cols-4 4xl:grid-cols-5 gap-10 mb-10">
  <mat-card class="md:col-span-2 2xl:col-span-2 4xl:col-span-3">
    <mat-card-header>
      <mat-card-title>Exponeringar per månad</mat-card-title>
    </mat-card-header>
    <flow-views-monthly [filter]="filter"
                        [screens]="initData.screens"
                        [currentMonthStatistics]="currentMonthStatistics"
                        [selectedAdvertisement]="selectedAdvertisement"></flow-views-monthly>
  </mat-card>
  <mat-card class="md:col-span-2 2xl:col-span-2 4xl:col-span-2">
    <mat-card-header>
      <mat-card-title>Exponeringar per vecka</mat-card-title>
    </mat-card-header>
    <flow-views-weekly [filter]="filter"
                       [screens]="initData.screens"
                       [currentMonthStatistics]="currentMonthStatistics"
                       [selectedAdvertisement]="selectedAdvertisement">
    </flow-views-weekly>
  </mat-card>
</div>
