<div class="page-header"
     *ngIf="!isModal">
  <h1 class="page-title"
      *ngIf="!targetGroupId">Skapa målgrupp</h1>
  <h1 class="page-title"
      *ngIf="targetGroupId">Redigera målgrupp</h1>
  <mat-divider></mat-divider>
</div>

<form>
  <mat-card class="form-container">
    <ng-container [formGroup]="form">
      <mat-form-field class="w-full">
        <mat-label>Namn</mat-label>
        <input matInput
               type="text"
               formControlName="name">
      </mat-form-field>
      <mat-form-field class="multi-select-field w-full">
        <mat-label>Egenskapstyp</mat-label>
        <mat-select formControlName="characteristicTypes"
                    multiple>
          <mat-select-trigger>
            <flow-multi-select-chips [control]="form.get('characteristicTypes')"
                                     displayKey="name"></flow-multi-select-chips>
          </mat-select-trigger>
          <mat-option *ngFor="let type of enumService.characteristicTypes"
                      [value]="type">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <mat-form-field class="multi-select-field w-full"
                    *ngFor="let group of characteristicGroups">
      <mat-label>{{typeLabel(group.type)}}</mat-label>
      <mat-select #select="matSelect"
                  [(ngModel)]="group.selectedCharacteristics"
                  [ngModelOptions]="{ standalone: true }"
                  required
                  multiple>
        <mat-select-trigger>
          <flow-multi-select-chips [matSelect]="select"
                                   displayKey="name"></flow-multi-select-chips>
        </mat-select-trigger>
        <mat-option *ngFor="let characteristic of group.characteristics"
                    [value]="characteristic">
          {{characteristic.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card>

  <mat-toolbar *ngIf="!isModal"
               class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/screens']">
        Avbryt
      </button>
      <button [disabled]="loading$.save | async"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>

  <div *ngIf="isModal"
       class="flex items-center justify-end mt-8">
    <button mat-button
            type="button"
            (click)="onCancelClick()">Avbryt</button>
    <button mat-raised-button
            type="button"
            (click)="onSaveClick()"
            color="primary"
            cdkFocusInitial>Spara</button>
  </div>
</form>
