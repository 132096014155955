/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdToDisplayRequest } from '../models/ad-to-display-request';
import { AdToDisplayRequest2 } from '../models/ad-to-display-request-2';
import { AddScreenActivityRequest } from '../models/add-screen-activity-request';
import { AddScreenActivityRequest2 } from '../models/add-screen-activity-request-2';
import { AdvertisementResponse } from '../models/advertisement-response';
import { AdvertisementResponse2 } from '../models/advertisement-response-2';
import { AlertRequest } from '../models/alert-request';
import { DownloadModelRequest } from '../models/download-model-request';
import { DownloadServiceRequest } from '../models/download-service-request';
import { Stream } from '../models/stream';
import { VersionsRequest } from '../models/versions-request';
import { VersionsResponse } from '../models/versions-response';

@Injectable({
  providedIn: 'root',
})
export class ScreenApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadModelScreen
   */
  static readonly DownloadModelScreenPath = '/screen/DownloadModel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadModel()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadModel$Response(params?: {
    body?: DownloadModelRequest
  }): Observable<StrictHttpResponse<Stream>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.DownloadModelScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stream>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadModel$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadModel(params?: {
    body?: DownloadModelRequest
  }): Observable<Stream> {

    return this.downloadModel$Response(params).pipe(
      map((r: StrictHttpResponse<Stream>) => r.body as Stream)
    );
  }

  /**
   * Path part for operation downloadBizlabAiScreen
   */
  static readonly DownloadBizlabAiScreenPath = '/screen/DownloadBizlabAI';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBizlabAI()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadBizlabAI$Response(params?: {
    body?: DownloadServiceRequest
  }): Observable<StrictHttpResponse<Stream>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.DownloadBizlabAiScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stream>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadBizlabAI$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadBizlabAI(params?: {
    body?: DownloadServiceRequest
  }): Observable<Stream> {

    return this.downloadBizlabAI$Response(params).pipe(
      map((r: StrictHttpResponse<Stream>) => r.body as Stream)
    );
  }

  /**
   * Path part for operation downloadBizlabAdvertiserScreen
   */
  static readonly DownloadBizlabAdvertiserScreenPath = '/screen/DownloadBizlabAdvertiser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBizlabAdvertiser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadBizlabAdvertiser$Response(params?: {
    body?: DownloadServiceRequest
  }): Observable<StrictHttpResponse<Stream>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.DownloadBizlabAdvertiserScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Stream>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadBizlabAdvertiser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  downloadBizlabAdvertiser(params?: {
    body?: DownloadServiceRequest
  }): Observable<Stream> {

    return this.downloadBizlabAdvertiser$Response(params).pipe(
      map((r: StrictHttpResponse<Stream>) => r.body as Stream)
    );
  }

  /**
   * Path part for operation versionsScreen
   */
  static readonly VersionsScreenPath = '/screen/Versions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `versions()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  versions$Response(params?: {
    body?: VersionsRequest
  }): Observable<StrictHttpResponse<VersionsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.VersionsScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VersionsResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `versions$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  versions(params?: {
    body?: VersionsRequest
  }): Observable<VersionsResponse> {

    return this.versions$Response(params).pipe(
      map((r: StrictHttpResponse<VersionsResponse>) => r.body as VersionsResponse)
    );
  }

  /**
   * Path part for operation adToDisplayScreen
   */
  static readonly AdToDisplayScreenPath = '/screen/GetAdToDisplay';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adToDisplay()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adToDisplay$Response(params?: {
    body?: AdToDisplayRequest
  }): Observable<StrictHttpResponse<AdvertisementResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.AdToDisplayScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdvertisementResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adToDisplay$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adToDisplay(params?: {
    body?: AdToDisplayRequest
  }): Observable<AdvertisementResponse> {

    return this.adToDisplay$Response(params).pipe(
      map((r: StrictHttpResponse<AdvertisementResponse>) => r.body as AdvertisementResponse)
    );
  }

  /**
   * Path part for operation adToDisplay2Screen
   */
  static readonly AdToDisplay2ScreenPath = '/screen/GetAdToDisplay2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adToDisplay2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adToDisplay2$Response(params?: {
    body?: AdToDisplayRequest2
  }): Observable<StrictHttpResponse<AdvertisementResponse2>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.AdToDisplay2ScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdvertisementResponse2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `adToDisplay2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adToDisplay2(params?: {
    body?: AdToDisplayRequest2
  }): Observable<AdvertisementResponse2> {

    return this.adToDisplay2$Response(params).pipe(
      map((r: StrictHttpResponse<AdvertisementResponse2>) => r.body as AdvertisementResponse2)
    );
  }

  /**
   * Path part for operation downloadImageScreen
   */
  static readonly DownloadImageScreenPath = '/screen/medias/image/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadImage()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImage$Response(params: {
    mediaId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.DownloadImageScreenPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadImage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadImage(params: {
    mediaId: number;
  }): Observable<void> {

    return this.downloadImage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation downloadVideoScreen
   */
  static readonly DownloadVideoScreenPath = '/screen/medias/video/{mediaId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadVideo()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadVideo$Response(params: {
    mediaId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.DownloadVideoScreenPath, 'get');
    if (params) {
      rb.path('mediaId', params.mediaId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadVideo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadVideo(params: {
    mediaId: number;
  }): Observable<void> {

    return this.downloadVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation logScreenActivityScreen
   */
  static readonly LogScreenActivityScreenPath = '/screen/add-activity';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logScreenActivity()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logScreenActivity$Response(params?: {
    body?: AddScreenActivityRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.LogScreenActivityScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logScreenActivity$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logScreenActivity(params?: {
    body?: AddScreenActivityRequest
  }): Observable<void> {

    return this.logScreenActivity$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation logScreenActivity2Screen
   */
  static readonly LogScreenActivity2ScreenPath = '/screen/add-activity2';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `logScreenActivity2()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logScreenActivity2$Response(params?: {
    body?: AddScreenActivityRequest2
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.LogScreenActivity2ScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `logScreenActivity2$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  logScreenActivity2(params?: {
    body?: AddScreenActivityRequest2
  }): Observable<void> {

    return this.logScreenActivity2$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation alertScreen
   */
  static readonly AlertScreenPath = '/screen/alert';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `alert()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  alert$Response(params?: {
    body?: AlertRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ScreenApi.AlertScreenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `alert$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  alert(params?: {
    body?: AlertRequest
  }): Observable<void> {

    return this.alert$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
