<div class="bc-container"
     [ngStyle]="{
		'height': fullscreen ? '100%' : calendarHeight
	}">
  <div class="bc-row bc-header-row">
    <div class="bc-col-header bc-week-number bc-number">v.
    </div>
    <div class="bc-col-header bc-weekday-label"
         *ngFor="let weekday of calendarWeekdays">
      {{weekday.name}}
    </div>
  </div>

  <ng-container *ngFor="let week of weeks">
    <div class="bc-row">
      <div class="bc-week-number bc-number">
        {{week.number}}
      </div>
      <span class="bc-week-month-label">
        {{week.monthLabel}}
      </span>
    </div>
    <div class="bc-row">
      <div class="bc-absolute-container"
           [style.height]="rowHeight(week)">
        <flow-campaign-calendar-item *ngFor="let item of week.campaignItems"
                                     [item]="item"
                                     [isSelectState]="isSelectState">
        </flow-campaign-calendar-item>
      </div>
      <div class="bc-col bc-week-number bc-number"
           [style.height]="rowHeight(week, 60)">
        &nbsp;
      </div>
      <div class="bc-col bc-day"
           [ngClass]="{ 'bc-odd-month-day': day.isOddMonth }"
           [style.height]="rowHeight(week, 60)"
           *ngFor="let day of week.days">
        <div class="bc-day-number bc-number">
          {{day.number}}
        </div>
      </div>
    </div>
  </ng-container>
</div>
