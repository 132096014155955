import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Settings } from 'luxon';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Settings.defaultZone = 'Europe/Stockholm';
Settings.defaultLocale = 'sv';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
