<div class="page-header">
  <h1 class="page-title">Butiker</h1>
  <mat-divider></mat-divider>
</div>

<div class="table-container">
  <mat-toolbar>
    <a mat-flat-button
       color="accent"
       [routerLink]="['/locations/create']">
      <mat-icon class="mr-2"
                inline="true">add</mat-icon>Skapa
    </a>
  </mat-toolbar>
  <table mat-table
         [dataSource]="dataSource"
         class="table-hover"
         matSort
         matSortActive="id"
         matSortDirection="desc">

    <ng-container matColumnDef="id">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> ID. </th>
      <td mat-cell
          *matCellDef="let location"> {{location.id}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Namn </th>
      <td mat-cell
          *matCellDef="let location"> {{location.name}} </td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell
          *matHeaderCellDef
          mat-sort-header> Kund </th>
      <td mat-cell
          *matCellDef="let poi"> {{poi.customer?.name}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell
          *matHeaderCellDef
          class="actions-col"></th>
      <td mat-cell
          *matCellDef="let location"
          [style.width]="'1px'">
        <button mat-icon-button
                (click)="onDeleteClick($event, location)"
                class="delete-button"
                aria-label="delete item">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let location; columns: displayedColumns;"
        [routerLink]="['/locations', location.id]"></tr>
  </table>
</div>
