import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, forkJoin, of, takeUntil } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { UserRoleEnum } from 'src/app/core/auth/auth.service';
import { EnumService } from 'src/app/core/enum.service';
import { indicateLoading } from 'src/app/core/indicate-loading';
import { SystemMessageService } from 'src/app/core/system-message.service';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Customer, User, UserApi } from 'src/_api';

@Component({
  selector: 'flow-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent extends unsubscribeMixin() implements OnInit {

  userId: number;
  user: User;
  customers: Customer[];
  form: FormGroup<UserFormGroup>;

  loading$ = {
    init: new BehaviorSubject(true),
    save: new BehaviorSubject(false)
  }

  constructor(
    private userApi: UserApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private systemMessageService: SystemMessageService,
    private appService: AppService,
    private adminApi: AdminApi,
    public enumService: EnumService
  ) {
    super();
    this.appService.hasToolbarFormActions = true;
    this.userId = +this.activatedRoute.snapshot.params['id'] || null;
    this.initForm();
  }

  ngOnInit(): void {
    forkJoin([
      this.adminApi.getCustomers(),
      this.userId ? this.userApi.getUsers() : of(null)
    ])
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.init))
      .subscribe(([customers, users]) => {
        this.customers = customers;
        this.user = users?.find(u => u.id === this.userId);
        this.initForm(this.user);
      });
  }

  onSaveClick(): void {
    if (!this.form.valid) {
      return;
    }
    if (!this.userId) {
      this.registerUser();
    } else {
      this.editUser();
    }
  }

  private registerUser(): void {
    this.userApi.register({ body: this.form.value })
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Användare ${this.form.value.name} sparad!`);
        this.router.navigate(['/users']);
      });
  }

  private editUser(): void {
    const values = this.form.value;
    const rolesToRemove = this.user.roleIds?.filter(id => !values.roleIds?.some(newId => id === newId)) || [];
    const rolesToAdd = values.roleIds?.filter(newId => !this.user.roleIds?.some(id => newId === id)) || [];
    const customersToRemove = this.user.customerIds?.filter(id => !values.customerIds?.some(newId => id === newId)) || [];
    const customersToAdd = values.customerIds?.filter(newId => !this.user.customerIds?.some(id => newId === id)) || [];
    const requests = [
      ...rolesToRemove.map(roleId => this.userApi.removeRoleFromUser({ userId: this.userId, roleId })),
      ...rolesToAdd.map(roleId => this.userApi.addRole({ userId: this.userId, roleId })),
      ...customersToRemove.map(customerId => this.userApi.removeUserFromCustomer({ userId: this.userId, customerId })),
      ...customersToAdd.map(customerId => this.userApi.addUserToCustomer({ userId: this.userId, customerId }))
    ];
    if (!requests?.length) {
      this.systemMessageService.info('Det har inte gjorts några ändringar.');
      return;
    }
    forkJoin(requests)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        indicateLoading(this.loading$.save))
      .subscribe(() => {
        this.systemMessageService.success(`Användare ${this.user.name} sparad!`);
        this.router.navigate(['/users']);
      });
  }

  private initForm(user?: User): void {
    this.form = this.formBuilder.group({
      name: [user?.name, Validators.required],
      email: [user?.email, Validators.required],
      password: [user ? '******' : '', Validators.required],
      roleIds: this.formBuilder.control(user?.roleIds),
      customerIds: this.formBuilder.control(user?.customerIds)
    });

    if (this.userId && !user) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    if (user) { // bara roller och customer som går att redigera
      this.form.get('name').disable();
      this.form.get('email').disable();
      this.form.get('password').disable();
    }
  }
}

interface UserFormGroup {
  name: FormControl<string>;
  email: FormControl<string>;
  password: FormControl<string>;
  roleIds: FormControl<UserRoleEnum[]>;
  customerIds: FormControl<number[]>;
}
