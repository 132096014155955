import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { isEqual } from 'lodash-es';
import { DateTime } from 'luxon';
import { BaseChartDirective } from 'ng2-charts';
import { interval, Subscription, takeUntil } from 'rxjs';
import { STATISTICS_EXPOSURES_POLLING_INTERVAL } from 'src/app/constants';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { Advertisement, StatisticsAdvertisementResponse, StatisticsAdvertisementScreenDay, StatisticsApi, TargetGroupEnum, Screen } from 'src/_api';
import { TargetGroupEnumTranslations } from '../../target-group/target-group-enum-translations';
import { TargetGroupHelper } from '../../target-group/target-group.helper';
import { StatisticsFilterValues } from '../statistics-filter.component';
import { ChartWeekday, StatisticsService } from '../statistics.service';

const mapEmptyToNull = value => value || null

@Component({
  template: ''
})
export abstract class AbstractViewsComponent extends unsubscribeMixin() implements OnInit {
  @Input() filter: StatisticsFilterValues;
  @Input() selectedAdvertisement: Advertisement;
  @Input() screens: Screen[]
  @Input() currentMonthStatistics?: { statistics: StatisticsAdvertisementResponse, advertisementId: number };

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  public chartOptions: ChartConfiguration['options'] = {
    scales: {
      y: { grace: '5%' }
    },
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        anchor: 'end',
        align: 'end'
      }
    }
  };
  public chartData: ChartData<'bar'> = null;
  public chartPlugins = [DataLabelsPlugin];

  statsRequest: Subscription;
  statistics: StatisticsAdvertisementResponse;
  hideDefaultAdFC = new FormControl(true);
  GroupByData = GroupByData;
  groupByFC = new FormControl(GroupByData.Total);


  constructor(
    private statisticsApi: StatisticsApi,
    private statisticsService: StatisticsService,
    private targetGroupHelper: TargetGroupHelper
  ) {
    super();

  }


  ngOnInit(): void {
    this.groupByFC.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(this.onGroupByChange);
    interval(STATISTICS_EXPOSURES_POLLING_INTERVAL)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.updateData();
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedAdvertisement?.currentValue) {
      this.updateData();
    }
    if (changes.currentMonthStatistics?.currentValue && this.isTimespanInCurrentMonth()) {
      this.updateData()
    }
  }
  private onGroupByChange = (value: GroupByData): void => {
    this.chartData = this.mapChartData(this.statistics)
    this.chart.update();
  };
  onPeriodChange() {
    this.updateData()
  }

  protected updateData(): void {
    const { startTime, endTime } = this.getStartAndEndTime()
    if (!startTime || !endTime || !this.selectedAdvertisement) return

    if (this.isTimespanInCurrentMonth()) {
      // Statistics is in current month, no need to fetch new data
      console.log('Inside current month - no need to fetch new data')
      if (this.currentMonthStatistics?.advertisementId === this.selectedAdvertisement.id) {
        this.statistics = this.currentMonthStatistics.statistics
        this.chartData = this.mapChartData(this.currentMonthStatistics.statistics)
        this.chart.update()
      }
      return
    }
    console.log('outside current month - need to fetch new data')

    this.statsRequest?.unsubscribe();

    this.statsRequest = this.statisticsApi.getAdvertisementStatistics({
      advertisementId: this.selectedAdvertisement.id,
      body: {
        startTime: startTime.toISO(),
        endTime: endTime.toISO(),
      }
    }).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((stats) => {
        if (this.statistics?.perScreenDay && isEqual(stats.perScreenDay, this.statistics.perScreenDay)) {
          console.log('equal data');
          return;
        }
        this.statistics = stats
        this.chartData = this.mapChartData(stats)
        this.chart.update();

      });

  }

  private isTimespanInCurrentMonth() {
    const { startTime, endTime } = this.getStartAndEndTime()
    if (!startTime || !endTime || !this.selectedAdvertisement) return false

    const now = DateTime.utc().startOf('day');
    const startMonth = now.startOf('month')
    const endMonth = now.endOf('month')

    return startTime.startOf('day') >= startMonth && endTime.startOf('day') <= endMonth
  }

  private mapChartData(stats: StatisticsAdvertisementResponse): ChartData<'bar'> {
    const { perScreenDay, targetGroups } = stats
    const { startTime, endTime } = this.getStartAndEndTime()
    const days = this.statisticsService.buildPeriod(startTime, endTime, this.getColumnType());

    let datasets = []
    switch (this.groupByFC.value) {
      case GroupByData.TargetGroup:
        datasets = this.mapByTargetGroups(days, perScreenDay)

        break;
      case GroupByData.Screen:
        datasets = this.mapByScreen(days, perScreenDay)
        break;
      case GroupByData.Total:
      default:
        datasets = this.mapByTotal(days, perScreenDay, targetGroups)
        break;
    }

    return {
      labels: days.map(day => day.label),
      datasets
    };
  }

  private mapByTotal(days: ChartWeekday[], perDay: StatisticsAdvertisementScreenDay[], targetGroups: TargetGroupEnum[]) {
    const primaryViews = days.map(day => {
      const counts = perDay.find(d => d.date === day.utcString)?.counts ?? [];
      return counts.filter((i) => targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0)
    })

    const secondaryViews = days.map(day => {
      const counts = perDay.find(d => d.date === day.utcString)?.counts ?? [];
      return counts.filter((i) => !targetGroups.includes(i.targetGroup)).reduce((sum, curr) => sum + curr.count ?? 0, 0)
    })
    return [
      {
        label: 'Primära exponeringar',
        data: primaryViews.map(mapEmptyToNull)
      },
      {
        label: 'Sekundära exponeringar',
        data: secondaryViews.map(mapEmptyToNull)
      }
    ]
  }
  private mapByScreen(days: ChartWeekday[], perDay: StatisticsAdvertisementScreenDay[]) {
    const screens = perDay.reduce((acc, curr) => acc.includes(curr.screenId) ? acc : [...acc, curr.screenId], [])

    return screens.map(screenId => ({
      label: this.screens.find((s) => s.id === screenId)?.locationName,
      data: days.map(day => {
        const counts = perDay.filter((i) => i.screenId === screenId).find(d => d.date === day.utcString)?.counts ?? [];
        return counts.reduce((sum, curr) => sum + curr.count ?? 0, 0)
      }).map(mapEmptyToNull)
    }))
  }
  private mapByTargetGroups(days: ChartWeekday[], perDay: StatisticsAdvertisementScreenDay[]) {
    const targetGroups = perDay.reduce((acc, curr) => {
      curr.counts?.forEach(count => {
        if (!acc.includes(count.targetGroup) && count.targetGroup) {
          acc.push(count.targetGroup)
        }
      })
      return acc
    }, [])

    return targetGroups.map(targetGroupId => ({
      label: TargetGroupEnumTranslations[targetGroupId],
      data: days.map(day => {
        const counts = perDay.find(d => d.date === day.utcString)?.counts ?? [];
        const countsForTargetGroup = counts.find((count) => count.targetGroup === targetGroupId)
        return countsForTargetGroup?.count ?? 0
      }).map(mapEmptyToNull),
      ...this.targetGroupHelper.getChartColors(targetGroupId)

    }))
  }

  abstract getStartAndEndTime(): { startTime: DateTime, endTime: DateTime }
  abstract getColumnType(): 'date' | 'day'

}

export enum GroupByData {
  Total,
  Screen,
  TargetGroup
}
