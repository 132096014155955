<div class="page-header">
  <h1 class="page-title"
      *ngIf="!customerId">Skapa kund</h1>
  <h1 class="page-title"
      *ngIf="customerId">Redigera kund</h1>
  <mat-divider></mat-divider>
</div>

<form [formGroup]="form">
  <mat-card class="form-container">
    <div class="grid lg:grid-cols-3 gap-4">
      <mat-form-field>
        <mat-label>Företagsnamn</mat-label>
        <input matInput
               type="text"
               formControlName="name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Organisationsnummer</mat-label>
        <input matInput
               type="text"
               formControlName="organizationId">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Kundtyp</mat-label>
        <mat-select formControlName="type">
          <mat-option *ngFor="let type of enumService.customerTypes"
                      [value]="type.value">
            {{type.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="flex items-center">
      <mat-form-field class="multi-select-field flex-1">
        <mat-label>Kontaktpersoner</mat-label>
        <mat-select formControlName="persons"
                    multiple>
          <mat-select-trigger>
            <flow-multi-select-chips [control]="form.get('persons')"
                                     [displayMethod]="customerPersonLabel"></flow-multi-select-chips>
          </mat-select-trigger>
          <mat-option *ngFor="let person of customerPersons; let i = index"
                      [value]="person">
            <div class="flex">
              <span class="flex-1">{{person.name}} - {{person.phone}}</span>
              <button class="btn-edit-customer-person"
                      mat-icon-button
                      (click)="onCreateCustomerPerson(person, i, $event)"
                      color="accent">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button type="button"
              mat-raised-button
              class="mb-3 ml-4"
              color="accent"
              (click)="onCreateCustomerPerson()">
        <mat-icon class="mr-2"
                  inline="true">add</mat-icon>Ny kontaktperson
      </button>
    </div>
    <flow-form-field-group label="Fakturering">
      <mat-form-field class="w-full">
        <mat-label>Adress</mat-label>
        <input matInput
               type="text"
               formControlName="address">
      </mat-form-field>
      <div class="grid lg:grid-cols-3 gap-4">
        <mat-form-field>
          <mat-label>Postnr</mat-label>
          <input matInput
                 type="text"
                 formControlName="postalCode">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ort</mat-label>
          <input matInput
                 type="text"
                 formControlName="postalCity">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Land</mat-label>
          <mat-select formControlName="countryExternalId">
            <mat-option *ngFor="let country of countries"
                        [value]="country.code">
              {{country.swe}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="grid lg:grid-cols-2 gap-4">
        <mat-form-field>
          <mat-label>Leveranssätt</mat-label>
          <mat-select formControlName="invoiceDeliveryType">
            <mat-option *ngFor="let type of enumService.invoiceDeliveryTypes"
                        [value]="type.value">
              {{type.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="form.value?.invoiceDeliveryType === InvoiceDeliveryTypeEnum.Email">
          <mat-label>E-postadress</mat-label>
          <input matInput
                 type="text"
                 formControlName="invoiceDeliveryEmail">
        </mat-form-field>
      </div>
      <mat-form-field class="w-full">
        <mat-label>Referens</mat-label>
        <input matInput
               type="text"
               formControlName="referenceName">
      </mat-form-field>
    </flow-form-field-group>
  </mat-card>

  <mat-toolbar class="toolbar-form-actions">
    <div class="actions">
      <button type="button"
              mat-stroked-button
              class="btn-cancel"
              [routerLink]="['/locations']">
        Avbryt
      </button>
      <button [disabled]="loading$.save | async"
              mat-flat-button
              class="btn-save"
              color="primary"
              (click)="onSaveClick()">
        <mat-icon class="mr-2"
                  inline="true">save</mat-icon>Spara
      </button>
    </div>
  </mat-toolbar>
</form>
