import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateTime } from 'luxon';

import { MAT_DATE_FORMATS } from '@angular/material/core';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { YearAndMonthPickerHeader } from './year-and-month-picker-header.component';
import { STATISTICS_START_DATE } from 'src/app/constants';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM-DD',
  },
  display: {
    dateInput: 'yyyy-MM',
    monthYearLabel: 'MMMM yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM yyyy'
  },
};

@Component({
  selector: 'year-and-month-picker',
  templateUrl: './year-and-month-picker.component.html',
  styleUrls: [],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class YearAndMonthPickerComponent extends unsubscribeMixin() {
  @Input() formValue: FormControl<DateTime>;
  @Output() monthChanged = new EventEmitter<DateTime>();

  // End of year, otherwise months after current month is not selectable for previous years
  maxDate = DateTime.utc().endOf('month')
  minDate = STATISTICS_START_DATE
  headerComponent = YearAndMonthPickerHeader;


  chosenMonthHandler(normalizedMonth: DateTime, datepicker: MatDatepicker<DateTime>) {
    const ctrlValue = this.formValue.value;
    ctrlValue.set({ month: normalizedMonth.month })
    this.monthChanged.emit(normalizedMonth);
    datepicker.close();
  }
}

