import {
  Component, Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { GroupByData } from '../abstract-views-component';

@Component({
  selector: 'views-chart-header',
  templateUrl: './views-chart-header.component.html',
  styleUrls: ['./views-chart-header.component.scss']
})
export class ViewsChartHeaderComponent extends unsubscribeMixin() {
  @Input() groupByFC: FormControl
  @Input() hideDefaultAdFC: FormControl
  @Input() label = ''

  GroupByData = GroupByData;

}
