<div class="page-header">
  <h1 class="page-title">Räckvidd</h1>
  <mat-divider></mat-divider>
</div>
<!-- <flow-statistics-filter [initData]="initData"
                        (filterChange)="onFilterChange($event)"></flow-statistics-filter> -->
<!-- <mat-divider class="my-5"></mat-divider> -->
<h2>Totalt antal besökare denna vecka</h2>
<div class="grid md:grid-cols-5 gap-10 mb-10">
  <mat-card *ngFor="let stat of peopleTargetGroupsStatistics"
            class="statistics-summary-card target-group-summary">
    <mat-card-header>
      <mat-card-title>{{stat.label}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <span class="number-big">{{stat.currentWeek}}</span>
    </mat-card-content>
  </mat-card>
</div>
<mat-divider class="my-5"></mat-divider>
<div class="charts-container grid md:grid-cols-2 2xl:grid-cols-3 4xl:grid-cols-5 gap-10 mb-10">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Fördelning av kön/ålder</mat-card-title>
    </mat-card-header>
    <flow-people-count-target-group-monthly [filter]="filter">
    </flow-people-count-target-group-monthly>
  </mat-card>
  <mat-card class="2xl:col-span-2">
    <mat-card-header>
      <mat-card-title>Besökare per timme</mat-card-title>
    </mat-card-header>
    <flow-people-count-target-group-daily [filter]="filter">
    </flow-people-count-target-group-daily>
  </mat-card>
  <mat-card class="md:col-span-2 2xl:col-span-3 4xl:col-span-2">
    <mat-card-header>
      <mat-card-title>Besökare fördelat på kön/ålder</mat-card-title>
    </mat-card-header>
    <flow-people-count-target-group-weekly [filter]="filter">
    </flow-people-count-target-group-weekly>
  </mat-card>
</div>
