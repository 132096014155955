import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartConfiguration, ChartData } from 'chart.js';
import { DateTime } from 'luxon';
import { PeopleCounterResponse } from 'src/_api/models/people-counter-response';
import { AbstractPeopleCountTargetGroup } from '../abstract-people-count-target-group';


@Component({
  selector: 'flow-people-count-target-group-monthly',
  templateUrl: './people-count-target-group-monthly.component.html',
  styleUrls: ['./people-count-target-group-monthly.component.scss'],
})
export class PeopleCountTargetGroupMonthlyComponent extends AbstractPeopleCountTargetGroup {
  public chartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        position: 'bottom'
      },
      datalabels: {
        formatter: (value, ctx) => value ? ctx.chart.data.labels?.[ctx.dataIndex] : '',
      },
    }
  };

  public chartData: ChartData<'pie', number[], string | string[]> = null;
  date = new FormControl(DateTime.utc());


  onMonthChange(value: DateTime) {
    this.date.setValue(value);
    this.updateData()
  }

  getRequestBody() {
    const month = this.date.value
    const day = month.startOf('day');
    const startDT = day.startOf('month');
    const endDT = day.endOf('month');

    return {
      startTime: startDT.toISO(),
      endTime: endDT.toISO(),
      screensIds: this.filter?.screens?.map(s => s.id),
    }
  }

  setChartData(statistics?: PeopleCounterResponse): void {
    if (!statistics?.screenHours) return

    const { labels, data, color } = this.peopleTargetGroups.reduce(({ labels, data, color }, targetGroup) => {
      const sum = statistics.screenHours.reduce((acc, stat) => {
        const sumPerHour = stat.peoples
          .filter(p => p.targetGroup === targetGroup.value)
          .reduce((sum, stat) => sum + stat.count, 0)

        return acc + sumPerHour
      }, 0)
      return { labels: [...labels, targetGroup.label], data: [...data, sum], color: [...color, this.targetGroupHelper.getTargetGroupColor(targetGroup.value)] }
    }, { labels: [], data: [], color: [] })

    this.chartData = {
      labels,
      datasets: [{
        data,
        backgroundColor: color,
        hoverBackgroundColor: color,
        borderColor: color,
        hoverBorderColor: color
      }],
    }
    this.chart.update();
  }

}

