import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from 'src/_api';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  previousUrl: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    // private modalService: ModalService,
    // private superAdminService: SuperAdminService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.checkForUser().pipe(
      map(
        (user: User) => {
          if (!route.data || !route.data['authorize']) {
            this.previousUrl = state.url;
            return true;
          }
          if (!user) {
            console.warn('log in to access this route');
            this.router.navigateByUrl('/login');
            return false;
          }

          // if (!this.userHasAccess(user, route.data.accesses)) {
          //   console.warn('current user does not have access to this route', state);
          //   if (!this.previousUrl) {
          //     this.router.navigateByUrl('/');
          //   }
          //   return false;
          // }
          this.previousUrl = state.url;
          return true;
        }
      )
      // ,mergeMap((hasAccess) => {
      //   if (this.userService.user?.accesses?.some((a) => a.accessType === AccessType.SuperAdmin)) {
      //     return this.superAdminService.getRestaurants().pipe(map(() => hasAccess));
      //   }
      //   return of(hasAccess);
      // }),
      // mergeMap((hasAccess) => {
      //   if (hasAccess && route.data?.accesses?.some(a => a.accessType === AccessType.Restaurant) &&
      //     !this.userService.user.restaurantId) {
      //     return from(
      //       this.modalService
      //         .open(SelectRestaurantModal, { emitEvent: false }, null, 'select-restaurant-modal')
      //         .then(() => true)
      //         .catch(() => false))
      //       .pipe(
      //         map((restaurantSelected) => {
      //           console.log('selected?', restaurantSelected);
      //           return restaurantSelected;
      //         })
      //       );
      //   }
      //   return of(hasAccess);
      // })
    );
  }

  // userHasAccess(user: User, accesses: Access[]) {
  //   if (!user) {
  //     return false;
  //   }
  //   if (!accesses || !accesses.length) {
  //     return true;
  //   }
  //   const userAccesses = user.accesses;
  //   return userAccesses.some(ua => {
  //     if (ua.accessType === AccessType.SuperAdmin) {
  //       return true;
  //     }
  //     return accesses.some(ra => {
  //       return ua.accessType === ra.accessType && ua.permission >= ra.permission;
  //     });
  //   });
  // }
}
