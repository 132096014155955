/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AdvertisementDetail } from '../models/advertisement-detail';
import { Campaign2 } from '../models/campaign-2';
import { SaveAdvertisementDetailRequest } from '../models/save-advertisement-detail-request';
import { SaveCampaignRequest } from '../models/save-campaign-request';
import { Screen } from '../models/screen';

@Injectable({
  providedIn: 'root',
})
export class CampaignApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCampaignsCampaign
   */
  static readonly GetCampaignsCampaignPath = '/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Campaign2>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetCampaignsCampaignPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Campaign2>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns(params?: {
  }): Observable<Array<Campaign2>> {

    return this.getCampaigns$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Campaign2>>) => r.body as Array<Campaign2>)
    );
  }

  /**
   * Path part for operation saveCampaignCampaign
   */
  static readonly SaveCampaignCampaignPath = '/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCampaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCampaign$Response(params?: {
    body?: SaveCampaignRequest
  }): Observable<StrictHttpResponse<Campaign2>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.SaveCampaignCampaignPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Campaign2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCampaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveCampaign(params?: {
    body?: SaveCampaignRequest
  }): Observable<Campaign2> {

    return this.saveCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<Campaign2>) => r.body as Campaign2)
    );
  }

  /**
   * Path part for operation getCampaignCampaign
   */
  static readonly GetCampaignCampaignPath = '/{campaignId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaign$Response(params: {
    campaignId: number;
  }): Observable<StrictHttpResponse<Campaign2>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetCampaignCampaignPath, 'get');
    if (params) {
      rb.path('campaignId', params.campaignId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Campaign2>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaign(params: {
    campaignId: number;
  }): Observable<Campaign2> {

    return this.getCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<Campaign2>) => r.body as Campaign2)
    );
  }

  /**
   * Path part for operation removeCampaignCampaign
   */
  static readonly RemoveCampaignCampaignPath = '/{campaignId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCampaign$Response(params: {
    campaignId: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.RemoveCampaignCampaignPath, 'delete');
    if (params) {
      rb.path('campaignId', params.campaignId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `removeCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeCampaign(params: {
    campaignId: number;
  }): Observable<void> {

    return this.removeCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCampaignScreensCampaign
   */
  static readonly GetCampaignScreensCampaignPath = '/{campaignId}/screens';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaignScreens()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignScreens$Response(params: {
    campaignId: number;
  }): Observable<StrictHttpResponse<Array<Screen>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetCampaignScreensCampaignPath, 'get');
    if (params) {
      rb.path('campaignId', params.campaignId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Screen>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCampaignScreens$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaignScreens(params: {
    campaignId: number;
  }): Observable<Array<Screen>> {

    return this.getCampaignScreens$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Screen>>) => r.body as Array<Screen>)
    );
  }

  /**
   * Path part for operation getAdvertisementsCampaign
   */
  static readonly GetAdvertisementsCampaignPath = '/campaigns/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements$Response(params?: {
  }): Observable<StrictHttpResponse<Array<AdvertisementDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetAdvertisementsCampaignPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdvertisementDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisements(params?: {
  }): Observable<Array<AdvertisementDetail>> {

    return this.getAdvertisements$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdvertisementDetail>>) => r.body as Array<AdvertisementDetail>)
    );
  }

  /**
   * Path part for operation saveAdvertisementCampaign
   */
  static readonly SaveAdvertisementCampaignPath = '/campaigns/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveAdvertisement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAdvertisement$Response(params?: {
    body?: SaveAdvertisementDetailRequest
  }): Observable<StrictHttpResponse<AdvertisementDetail>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.SaveAdvertisementCampaignPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdvertisementDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveAdvertisement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  saveAdvertisement(params?: {
    body?: SaveAdvertisementDetailRequest
  }): Observable<AdvertisementDetail> {

    return this.saveAdvertisement$Response(params).pipe(
      map((r: StrictHttpResponse<AdvertisementDetail>) => r.body as AdvertisementDetail)
    );
  }

  /**
   * Path part for operation getAdvertisementsByCampaignCampaign
   */
  static readonly GetAdvertisementsByCampaignCampaignPath = '/campaigns/{campaignId}/advertisements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisementsByCampaign()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisementsByCampaign$Response(params: {
    campaignId: number;
  }): Observable<StrictHttpResponse<Array<AdvertisementDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetAdvertisementsByCampaignCampaignPath, 'get');
    if (params) {
      rb.path('campaignId', params.campaignId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AdvertisementDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisementsByCampaign$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisementsByCampaign(params: {
    campaignId: number;
  }): Observable<Array<AdvertisementDetail>> {

    return this.getAdvertisementsByCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AdvertisementDetail>>) => r.body as Array<AdvertisementDetail>)
    );
  }

  /**
   * Path part for operation getAdvertisementCampaign
   */
  static readonly GetAdvertisementCampaignPath = '/campaigns/advertisements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdvertisement()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisement$Response(params: {
    id: number;
  }): Observable<StrictHttpResponse<AdvertisementDetail>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignApi.GetAdvertisementCampaignPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AdvertisementDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdvertisement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdvertisement(params: {
    id: number;
  }): Observable<AdvertisementDetail> {

    return this.getAdvertisement$Response(params).pipe(
      map((r: StrictHttpResponse<AdvertisementDetail>) => r.body as AdvertisementDetail)
    );
  }

}
