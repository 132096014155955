<div class="c-sidenav h-full flex flex-col justify-between"
     [ngClass]="{ 'pt-0': appService.modeOverQuery.matches }">
  <mat-nav-list>
    <mat-list-item (click)="toggleNav()">
      <mat-icon *ngIf="!navMini">chevron_left</mat-icon>
      <mat-icon matListIcon
                *ngIf="navMini">menu</mat-icon>
      <p class="list-item-text"
         matLine
         *ngIf="!navMini">&nbsp;</p>

    </mat-list-item>
    <a mat-list-item
       routerLink="/"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{ exact: true }">
      <mat-icon matListIcon>home</mat-icon>
      <p class="list-item-text"
         matLine
         *ngIf="!navMini">
        Hem
      </p>
    </a>
    <mat-divider></mat-divider>
    <ng-container *ngIf="isAdmin || selectedCustomer?.id > 0">
      <h3 *ngIf="!navMini"
          matSubheader
          class="nav-group-header">
        <!-- <mat-icon matListIcon>campaign</mat-icon> -->
        <span class="nav-group-header-text">Kampanjer</span>
      </h3>
      <a mat-list-item
         routerLink="/campaigns/book"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }">
        <div matListIcon
             class="stacked-icons">
          <mat-icon>campaign</mat-icon>
          <mat-icon>add_circle</mat-icon>
        </div>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Boka
        </p>
      </a>
      <a mat-list-item
         routerLink="/campaigns"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }">
        <div matListIcon
             class="stacked-icons">
          <mat-icon>campaign</mat-icon>
          <mat-icon>build_circle</mat-icon>
        </div>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Hantera
        </p>
      </a>
      <a *ngIf="isDev"
         mat-list-item
         routerLink="/campaigns/overview"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{ exact: true }">
        <div matListIcon
             class="stacked-icons">
          <mat-icon>campaign</mat-icon>
          <mat-icon>calendar_month</mat-icon>
        </div>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Översikt
        </p>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item
         routerLink="/medias"
         routerLinkActive="active">
        <mat-icon matListIcon>subscriptions</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Media
        </p>
      </a>
      <a mat-list-item
         routerLink="/screens"
         routerLinkActive="active">
        <mat-icon matListIcon>monitor</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Skärmar
        </p>
      </a>
      <mat-divider></mat-divider>
      <h3 *ngIf="!navMini"
          matSubheader
          class="nav-group-header">
        <span class="nav-group-header-text">Statistik</span>
      </h3>
      <a mat-list-item
         routerLink="/statistics/people-count"
         routerLinkActive="active">
        <mat-icon matListIcon>insights</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Räckvidd
        </p>
      </a>
      <a mat-list-item
         routerLink="/statistics/views"
         routerLinkActive="active">
        <mat-icon matListIcon>leaderboard</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
           Exponeringar
        </p>
      </a>
      <mat-divider></mat-divider>
      <a mat-list-item
         routerLink="/person-of-interest"
         routerLinkActive="active">
        <mat-icon matListIcon>person_outline</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Person of interest
        </p>
      </a>
      <mat-divider></mat-divider>
    </ng-container>

    <ng-container *ngIf="isAdmin && selectedCustomer?.id < 0">
      <h3 *ngIf="!navMini"
          matSubheader
          class="nav-group-header">
        <!-- <mat-icon matListIcon>campaign</mat-icon> -->
        <span class="nav-group-header-text">Admin</span>
      </h3>
      <a mat-list-item
         routerLink="/target-groups"
         routerLinkActive="active">
        <mat-icon matListIcon>emoji_people</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Målgrupper
        </p>
      </a>
      <a mat-list-item
         routerLink="/characteristics"
         routerLinkActive="active">
        <mat-icon matListIcon>transgender</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Egenskaper
        </p>
      </a>
      <a mat-list-item
         routerLink="/locations"
         routerLinkActive="active">
        <mat-icon matListIcon>store_mall_directory</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Butiker
        </p>
      </a>
      <a mat-list-item
         routerLink="/customers"
         routerLinkActive="active">
        <mat-icon matListIcon>contact_mail</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Kunder
        </p>
      </a>
      <a mat-list-item
         routerLink="/users"
         routerLinkActive="active">
        <mat-icon matListIcon>admin_panel_settings</mat-icon>
        <p class="list-item-text"
           matLine
           *ngIf="!navMini">
          Användare
        </p>
      </a>
    </ng-container>
  </mat-nav-list>

  <mat-expansion-panel #userPanel="matExpansionPanel"
                       *ngIf="!navMini"
                       (opened)="onUserPanelOpened()">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{selectedCustomer?.name || (isAdmin ? 'Adminläge' : 'Ingen kund vald')}}
      </mat-panel-title>
      <mat-icon class="mr-3">settings</mat-icon>
    </mat-expansion-panel-header>
    <mat-form-field class="w-full">
      <mat-label>Kund</mat-label>
      <mat-select [(ngModel)]="selectedCustomer"
                  (ngModelChange)="onCustomerChange($event)"
                  [disabled]="loading$.customers | async">
        <mat-option *ngFor="let customer of customers"
                    [value]="customer">
          {{customer.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="w-full"
            mat-stroked-button
            color="accent"
            (click)="logout()">
      Logga ut
    </button>
  </mat-expansion-panel>
  <mat-nav-list *ngIf="navMini">
    <button mat-list-item
            (click)="navMini = !navMini">
      <mat-icon matListIcon>settings</mat-icon>
    </button>
  </mat-nav-list>
</div>
