/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BookCampaignRequest } from '../models/book-campaign-request';
import { Campaign } from '../models/campaign';
import { GetBookingRequest } from '../models/get-booking-request';
import { GetBookingResponse } from '../models/get-booking-response';

@Injectable({
  providedIn: 'root',
})
export class CampaignAdminApi extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation bookCampaignCampaignAdmin
   */
  static readonly BookCampaignCampaignAdminPath = '/admin/campaigns/book';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bookCampaign()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookCampaign$Response(params?: {
    body?: BookCampaignRequest
  }): Observable<StrictHttpResponse<Campaign>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignAdminApi.BookCampaignCampaignAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Campaign>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bookCampaign$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  bookCampaign(params?: {
    body?: BookCampaignRequest
  }): Observable<Campaign> {

    return this.bookCampaign$Response(params).pipe(
      map((r: StrictHttpResponse<Campaign>) => r.body as Campaign)
    );
  }

  /**
   * Path part for operation getCampaignsCampaignAdmin
   */
  static readonly GetCampaignsCampaignAdminPath = '/admin/campaigns';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCampaigns()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns$Response(params?: {
  }): Observable<StrictHttpResponse<Array<Campaign>>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignAdminApi.GetCampaignsCampaignAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Campaign>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCampaigns$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCampaigns(params?: {
  }): Observable<Array<Campaign>> {

    return this.getCampaigns$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Campaign>>) => r.body as Array<Campaign>)
    );
  }

  /**
   * Path part for operation getBookingsCampaignAdmin
   */
  static readonly GetBookingsCampaignAdminPath = '/admin/campaigns/bookings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBookings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookings$Response(params?: {
    body?: GetBookingRequest
  }): Observable<StrictHttpResponse<GetBookingResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CampaignAdminApi.GetBookingsCampaignAdminPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GetBookingResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBookings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  getBookings(params?: {
    body?: GetBookingRequest
  }): Observable<GetBookingResponse> {

    return this.getBookings$Response(params).pipe(
      map((r: StrictHttpResponse<GetBookingResponse>) => r.body as GetBookingResponse)
    );
  }

}
