import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of, takeUntil } from 'rxjs';
import { unsubscribeMixin } from 'src/app/core/unsubscribe';
import { AdminApi, Advertisement, Campaign, CampaignApi, TargetGroup } from 'src/_api';
import { CampaignTypeTranslations } from './campaign-type-translations';

@Component({
  selector: 'flow-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent extends unsubscribeMixin() implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['id', 'name', 'type', 'slotsCount', 'actions'];
  dataSource: MatTableDataSource<CampaignListItem>;

  @ViewChild(MatSort) sort: MatSort;

  CampaignTypeTranslations = CampaignTypeTranslations;

  constructor(
    private campaignApi: CampaignApi,
    private adminApi: AdminApi
  ) {
    super();
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.getCampaigns();
  }

  getCampaigns(): void {
    this.campaignApi.getCampaigns()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((campaignsResponse) => {
        const campaigns = campaignsResponse.map(c => {
          const listItem = c as CampaignListItem;
          return listItem;
        });
        this.dataSource = new MatTableDataSource(campaigns);
        this.dataSource.sort = this.sort;
      });
  }

  onDeleteClick(event: Event, campaign: Campaign): void {
    event.preventDefault();
    event.stopPropagation();
    this.campaignApi.removeCampaign({ campaignId: campaign.id })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.getCampaigns();
      });
  }

}

interface CampaignListItem extends Campaign {
}
