<h1 mat-dialog-title>Välj perioder & skärmar</h1>
<div mat-dialog-content
     class="py-2">
  <form [formGroup]="filterForm">
    <div class="grid grid-cols-4 gap-4">
      <mat-form-field>
        <mat-label>Skärmlayout</mat-label>
        <mat-select formControlName="screenOrientation">
          <mat-option *ngFor="let orientation of enumService.screenOrientations"
                      [value]="orientation.value">
            {{orientation.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-span-3">
        <mat-label>Skärmar</mat-label>
        <mat-select formControlName="screenIds"
                    multiple>
          <mat-option *ngFor="let screen of filteredScreens"
                      [value]="screen.id">
            {{screenLabel(screen)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

  </form>

  <div class="mt-3">
    <mat-toolbar>
      <button type="button"
              mat-flat-button
              color="accent"
              (click)="toggleSlots()">
        <mat-icon class="w-6 mr-1"
                  inline="true">checkbox</mat-icon>Markera alla
      </button>
    </mat-toolbar>
    <!-- <div class="flex mb-3">
      <button type="button"
              mat-flat-button
              color="accent">
        Markera alla <mat-icon class="ml-2"
                  inline="true">checkbox</mat-icon>
      </button>
    </div> -->
    <flow-campaign-calendar *ngIf="visibleSlots?.length"
                            [campaignSlots]="visibleSlots"
                            [isSelectState]="true"></flow-campaign-calendar>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button
          type="button"
          (click)="onCancel()">Avbryt</button>
  <button mat-raised-button
          type="button"
          (click)="onDone()"
          color="primary"
          cdkFocusInitial>
    Klar
    <i>({{selectedSlotsCount}} st)</i>
  </button>
</div>
